import React from "react";
import { Helmet } from "react-helmet";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

const CookiesPolicy = () => {
  return (
    <>
      <div style={{ height: "100vh" }}>
        <Helmet>
          <title>VidaVault</title>
        </Helmet>
        <Navbar />
        <div style={{ height: "85%" }} className="page-content">
          <div className="content-wrapper">
            <div className="content-inner">
              <div className="m-5">
                <h3 className="text-center text-capitalize font-weight-bold">Cookies Policy</h3>
                <div>
                  {" "}
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ipsa
                  possimus blanditiis maiores quidem molestias delectus beatae
                  voluptatum aspernatur accusantium, vero deleniti corrupti quos
                  eaque veritatis, alias quasi ut, consectetur magnam eos dicta
                  tempore libero? Expedita dignissimos nostrum ad, inventore
                  facilis quibusdam eveniet amet voluptatum maxime aspernatur
                  perferendis natus dolorum commodi.
                </div><br />
                <div>
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nam
                  molestiae laudantium provident facilis minima nobis ea soluta
                  harum, qui vel nulla sit ad totam magni repudiandae natus,
                  eius temporibus placeat cum ipsam earum! Eaque accusamus
                  accusantium atque distinctio facilis, sapiente adipisci
                  consequuntur. Odio ipsam provident ipsa quisquam ratione nulla
                  fugiat autem, libero itaque sint iusto, quam ad corporis
                  necessitatibus porro dolorum maxime. Magnam consequatur unde
                  eligendi labore, omnis asperiores nesciunt?
                </div><br />
                <div>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint
                  optio quae vel minima. Voluptatem officia facere dolorem eius
                  voluptates consequuntur dolore similique quasi, eligendi
                  dolores, sunt voluptatibus mollitia? Eos, eius.
                </div>
                <br />
                <div>
                  <h3>How to use this document</h3>
                  <div>
                    This Cookies Policy describes, for users, all of the ways in
                    which the website owner/operator uses cookies, as well as
                    what the users can do about setting their permissions for
                    cookies, and how they can ask questions. Before sitting down
                    to fill it out, the operator of the website should ensure
                    that they have all the information needed about how the
                    website works with cookies. This may involve doing technical
                    checks or speaking to others in the company about the
                    security side of things.
                  </div>
                  <br />
                  <p>
                    {" "}
                    In this document, the operator will be able to outline the
                    following information (and more):
                  </p>
                  <li>what types of cookies are used</li>{" "}
                  <li>whether third-party advertising cookies are used</li>{" "}
                  <li>a full list of all of the cookies used</li>{" "}
                  <li>how consent is obtained from users who the user</li>
                  <div>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Velit facere optio eos sapiente totam? Perspiciatis aliquam
                    fuga quaerat exercitationem vitae, officia assumenda
                    voluptates. Nobis excepturi nostrum eius consectetur atque,
                    fugiat mollitia delectus commodi! Quo, explicabo odit!
                    Exercitationem repellat est ullam? Rerum, et rem. Velit
                    eaque provident modi facere! Saepe necessitatibus nihil
                    optio. Perferendis quisquam ipsum quos, eligendi odit
                    officia modi amet nisi esse exercitationem incidunt. Fugiat
                    labore iure possimus quam, voluptatum in, libero quae
                    mollitia unde omnis iste consequatur sit illum voluptate at
                    ratione maiores, deserunt nemo beatae assumenda doloremque
                    quaerat architecto? Inventore expedita aliquid libero alias
                    a incidunt quis ab eum fugit, nam sint, laborum sunt eveniet
                    at minus temporibus beatae cumque corrupti non
                    exercitationem distinctio provident sequi quas! Molestiae
                    aperiam maxime, optio sapiente aut a ab officia et adipisci
                    deleniti rerum ducimus, illo itaque, corporis odio debitis
                    ad autem commodi non veritatis impedit architecto
                    laboriosam! Commodi facilis odit inventore fugit dolores
                    nulla quos dolore ea modi et sed molestias, dolorem
                    aspernatur accusamus consequuntur repellendus, corporis
                    provident error sunt molestiae perspiciatis beatae nobis
                    illum. Enim ut maxime at soluta? Rem hic sapiente, neque
                    ducimus debitis eum, quod id dignissimos assumenda illo
                    commodi quisquam nihil, cupiditate incidunt! Id,
                    voluptatibus voluptas?
                  </div>
                  <br />
                  <div>
                    This Cookies Policy is also compliant with the General Data
                    Protection Regulation, or GDPR, for those companies which
                    may process the data of EU citizens.
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CookiesPolicy;
