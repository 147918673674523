import axios from "axios";
import React from "react";
import { useContext } from "react";
import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Popup from "../../components/Popup";
import { baseUrl } from "../../config";
import { UserContext } from "../../UserProvider";
import Cookies from "js-cookie";
const Recover = () => {
  const [email, setEmail] = useState("");
  const { loggedIn, logUserIn, setMsg, setToast, setIsLoading } =
    useContext(UserContext);
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    if (email != "") {
      setIsLoading(true);
      axios
        .post(`${baseUrl}/forgot_password`, { email: email })
        .then((response) => {
          setIsLoading(false);
          setMsg({ type: "success", text: response.data.resultMsg });
          setToast(true);
        })
        .catch((err) => {
          setIsLoading(false);
          if (err?.response?.data?.resultMsg === "User is inactive") {
            setMsg({
              type: "error",
              text: "The Super Admin has deactivated your account. Contact super admin for details.",
            });
            setToast(true);

            setTimeout(() => {
              Cookies.remove("userData");
              localStorage.removeItem("fha_logo");
              logUserIn(false);

              navigate("/");
            }, 2000);
          } else {
            setMsg({ type: "error", text: err.response.data.message });
            setToast(true);
          }
        });
    } else {
      setMsg({ type: "error", text: "Please enter a valid email ID" });
      setToast(true);
    }
  };

  return (
    <div style={{ height: "100vh" }}>
      <Navbar />
      <div style={{ height: "85%" }} className="page-content">
        <div className="content-wrapper">
          <div className="content-inner">
            <div className="content d-flex justify-content-center align-items-center">
              <form className="login-form" onSubmit={handleSubmit}>
                <div className="card mb-0">
                  <div className="card-body">
                    <div className="text-center mb-3">
                      <i className="fa-solid fa-key large-icon text-indigo mb-3 mt-1"></i>
                      <h5 className="mb-0">Password Recovery</h5>
                      <span className="d-block text-muted">
                        Recovery instructions will be sent via email
                      </span>
                    </div>

                    <div className="form-group form-group-floating row">
                      <div className="col-lg-12">
                        <div className="position-relative">
                          <input
                            type="text"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="form-control form-control-outline"
                            placeholder="Placeholder"
                          />
                          <label className="label-floating">
                            Account Email
                          </label>
                        </div>
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="btn btn-teal btn-labeled btn-block btn-labeled-right"
                    >
                      <b>
                        <i className="fa-solid fa-rotate-right"></i>
                      </b>
                      Reset Password
                    </button>
                  </div>
                </div>
              </form>
            </div>

            <div className="navbar navbar-expand-lg navbar-light">
              <div className="text-center d-lg-none w-100">
                <button
                  type="button"
                  className="navbar-toggler dropdown-toggle"
                  data-toggle="collapse"
                  data-target="#navbar-footer"
                >
                  <i className="icon-unfold mr-2"></i>
                  Footer
                </button>
              </div>

              <div className="navbar-collapse collapse" id="navbar-footer">
                <span className="navbar-text">
                  <span>&copy; 2023 VidaVault</span>
                </span>

                <ul className="navbar-nav ml-lg-auto">
                  <li className="nav-item">
                    <NavLink to="/fha/support" className="navbar-nav-link">
                      <i className="fa-solid fa-life-ring mr-2"></i> Support
                    </NavLink>
                  </li>
                  {/* <li className="nav-item">
                    <a
                      href="FHA-documentation.html"
                      className="navbar-nav-link"
                      target="_blank"
                    >
                      <i className="fa-solid fa-file mr-2"></i> Documentation
                    </a>
                  </li> */}
                  <li className="nav-item">
                    <NavLink
                      to="/fha/register"
                      className="navbar-nav-link font-weight-semibold"
                    >
                      <span className="text-pink mr-2">
                        <i className="fa-solid fa-user-plus"></i> Sign Up
                      </span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Recover;
