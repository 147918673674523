import React from "react";
import  Toast  from "react-toast-component";
const Popup = ({isOpen,setToast,msg,setMsg}) => {
  return (
    <Toast
      isOpen={isOpen}
      closeCallback={() => {setToast(false);setMsg({ text: "", type: "" });}}
      className="toast-container"
      hasAutoDismiss={true}
      duration={6000}
    >
      <div
        className={`alert ${msg.type == "error" && `alert-warning`} ${
          msg.type == "success" && `alert-success`
        } bg-white alert-styled-left alert-arrow-left alert-dismissible `}
      >
        <div onClick={() => {setToast(false);setMsg({ text: "", type: "" });}} className="close" style={{cursor:"pointer"}}>
          <span>&times;</span>
        </div>
        <h6 className="alert-heading font-weight-semibold mb-1">{msg.text}</h6>
      </div>
    </Toast>
  );
};

export default Popup;
