/** @format */

import React, { useEffect, useReducer, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import { useDropzone } from "react-dropzone";
import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import axios from "axios";
import { baseUrl, frontUrl } from "../../config";
import { useContext } from "react";
import { UserContext } from "../../UserProvider";
import Confirmation from "../../components/Confirmation";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
import Subnav from "../../components/Subnav";
import Cookies from "js-cookie";
import PreviewSlideshow from "../user/PreviewSlideshow";
// import InstagramEmbed from "react-instagram-embed";
const Createslideshow = () => {
  const personalDetailsFormRef = useRef(null);
  const familyDetailsFormRef = useRef(null);
  const linkRegex = /^(ftp|http|https):\/\/[^ "]+$/;
  const nameRegex = /^[a-zA-Z]{1,}['-]?[a-zA-Z]+/gm;
  const middleNameRegex = /^[a-zA-Z]{1,}['-]?[a-zA-Z]+/gm;
  const lastNameRegex = /^[a-zA-Z]{1,}['-]?[a-zA-Z]+/gm;
  const motherNameRegex = /^[a-zA-Z]{1,}['-]?[a-zA-Z]+/gm;
  const relativesRegex = /^[a-zA-Z]{1,}['-]?[a-zA-Z]+/gm;
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone();
  const navigate = useNavigate();
  const { loggedIn, logUserIn, setMsg, setToast, setIsLoading } =
    useContext(UserContext);
  let user = Cookies.get("userData");
  const fha_id = user && JSON.parse(user)?.user?.fha_id;

  const [files, setFiles] = useState([]);
  const [videoLink, setVideoLink] = useState();
  const [relativeFields, setRelativeFields] = useState([0]);
  // const [personalDetailsCheck, setPersonalDetailsCheck] = useState(false);
  const [relationList, setRelationList] = useState([]);
  const [existingList, setExistingList] = useState([]);
  const [deletedMedia, setDeletedMedia] = useState([]);
  // const [familyDetailsCheck, setFamilyDetailsCheck] = useState(false);
  // open previewslideshow popup
  const [isPreview, setIsPreview] = useState(false);
  const closeModal = () => {
    const close = document.getElementById("previewModal");
    if (close) {
      if (close.classList.contains("bounceInUp")) {
        close.classList.remove("bounceInUp");
      }
      close.classList.add("bounceOutDown");
    }
    setTimeout(() => {
      setIsPreview(false);
    }, 1000);
  };
  const [contentParams, setContentParams] = useState({
    fields: {
      personalDetails: {
        slideShowId: null,
        firstName: null,
        middleName: null,
        lastName: null,
        dob: null,
        dod: null,
        birthCity: null,
        birthState: null,
        deathCity: null,
        deathState: null,
        obituryText: null,
      },
      familyDetails: {
        slideShowId: null,
        motherName: null,
        fatherName: null,
        relatives: [
          {
            name: "",
            relationId: "",
            existingName: "",
            slideshowURL: "",
          },
          {
            name: "",
            relationId: "",
            existingName: "",
            slideshowURL: "",
          },
        ],
      },
      files: [],
    },
    errors: {},
  });
  const [qrcode, setQrcode] = useState();
  const today = new Date();
  const todayStr = today.toISOString().substring(0, 10);
  const location = useLocation();
  const personalDeatilsRequiredErr = {
    firstName: "First Name",
    middleName: "Middle Name",
    lastName: "Last Name",
    dob: "Date of Birth",
    dod: "Date of Death",
    birthCity: "Place of Birth: City",
    birthState: "Place of Birth: State",
    deathCity: "Place of Death: City",
    deathState: "Place of Death: State",
  };
  const { id } = useParams();
  const fetchData = () => {
    if (id) {
      let temp = {
        personalDetails: {
          slideShowId: null,
          firstName: null,
          middleName: null,
          lastName: null,
          dob: null,
          dod: null,
          birthCity: null,
          birthState: null,
          deathCity: null,
          deathState: null,
          obituryText: null,
        },
        familyDetails: {
          slideShowId: null,
          motherName: null,
          fatherName: null,
          relatives: [
            {
              name: "",
              relationId: "",
              existingName: "",
              slideshowURL: "",
            },
          ],
        },
        files: [],
      };
      setIsLoading(true);
      axios
        .post(`${baseUrl}/slideshow/${id}`, { userId: fha_id })
        .then((res) => {
          let response = res.data.data[0];
          console.log("Response ==> ", response);
          setQrcode(response?.qrCode);
          let i = response?.familyTree?.length - 1;
          let tempRelatives = [];
          while (i >= 0) {
            tempRelatives.push(0);
            i--;
          }
          setRelativeFields(tempRelatives);
          let j = response?.files?.length - 1;
          let tempFiles = [];
          while (j >= 0) {
            let tempObj = response.files[j];
            tempObj.name = response.files[j].dataUri.split("/").reverse()[0];
            tempFiles.push(tempObj);
            j--;
          }
          setFiles(tempFiles);
          temp.personalDetails.firstName = response.firstName;
          temp.personalDetails.middleName = response.middleName;
          temp.personalDetails.lastName = response.lastName;
          temp.personalDetails.dob = new Date(response.dob)
            .toISOString()
            .split("T")[0];
          temp.personalDetails.dod = new Date(response.dod)
            .toISOString()
            .split("T")[0];
          temp.personalDetails.birthCity = response.birthCity;
          temp.personalDetails.birthState = response.birthState;
          temp.personalDetails.deathCity = response.deathCity;
          temp.personalDetails.deathState = response.deathState;
          temp.personalDetails.obituryText = response.obituaryText;
          temp.personalDetails.slideShowId = response.pm_id;
          temp.familyDetails.slideShowId = response.pm_id;
          temp.familyDetails.fatherName = response.fatherName;
          temp.familyDetails.motherName = response.motherName;
          temp.familyDetails.relatives = response.familyTree;
          temp.files = response.files;
          setContentParams({ ...contentParams, fields: { ...temp } });
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          if (err?.response?.data?.resultMsg === "User is inactive") {
            setMsg({
              type: "error",
              text: "The Super Admin has deactivated your account. Contact super admin for details.",
            });
            setToast(true);

            setTimeout(() => {
              Cookies.remove("userData");
              localStorage.removeItem("fha_logo");
              logUserIn(false);

              navigate("/");
            }, 2000);
          }
          setIsLoading(false);
        });
    } else {
    }
  };

  // useEffect(() => {
  //   fetch("http://3.83.45.156:3001/slideshowdata", {
  //     method: "GET",
  //     headers: {
  //       Authorization: `Bearer ${loggedIn.token}`,
  //     },
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       // setData(data);
  //       console.log("get existing relative ==> ", data);
  //     })
  //     .catch((error) => console.error("Error fetching data:", error));
  // }, []);

  // useEffect(() => {
  //   fetch('http://3.83.45.156:3001/slideshowdata', {
  //     method: 'GET',
  //     headers: {
  //       'Authorization': `Bearer ${loggedIn.token}`
  //     }
  //   })
  //     .then(response => response.json())
  //     .then(data => {
  //       // setData(data);
  //       console.log("get existing relative ==> ",data);
  //     })
  //     .catch(error => console.error('Error fetching data:', error));
  // }, []);

  const getExistingList = async () => {
    try {
      axios
        // test URL
        // .get(`${baseUrl}/slideshowdata`, {
        .get(`${baseUrl}/getslideshowdata`, {
          // Prodcuction URL
          // .get(`${baseUrl}/getslideshowdata`, {
          headers: { Authorization: `Bearer ${loggedIn.token}` },
        })
        .then((res) => {
          const sortedData = res.data.data?.sort((a, b) =>
            a?.Firstname?.localeCompare(b?.Firstname)
          );
          setExistingList(sortedData);
        })
        .catch((err) => {
          console.log("err ==> ", err);
        });
    } catch (err) {
      console.log("Error ==> ", Error);
    }
  };
  useEffect(() => {
    getExistingList();
  }, []);

  const fetchRelation = () => {
    axios
      .get(`${baseUrl}/relations`)
      .then((res) => {
        setRelationList(res.data);
        if (id) {
          fetchData();
        } else {
          setQrcode();
          setFiles([]);
          setRelativeFields([0]);
          setDeletedMedia([]);
          setContentParams({
            fields: {
              personalDetails: {
                slideShowId: null,
                firstName: "",
                middleName: "",
                lastName: "",
                dob: "",
                dod: "",
                birthCity: "",
                birthState: "",
                deathCity: "",
                deathState: "",
                obituryText: "",
              },
              familyDetails: {
                slideShowId: "",
                motherName: "",
                fatherName: "",
                relatives: [
                  {
                    name: "",
                    relationId: "",
                    existingName: "",
                    slideshowURL: "",
                  },
                ],
              },
              files: [],
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.data?.resultMsg === "User is inactive") {
          setMsg({
            type: "error",
            text: "The Super Admin has deactivated your account. Contact super admin for details.",
          });
          setToast(true);
          setTimeout(() => {
            Cookies.remove("userData");
            localStorage.removeItem("fha_logo");
            logUserIn(false);
            navigate("/");
          }, 2000);
        }
      });
  };
  useEffect(() => {
    fetchRelation();
  }, [id]);
  const fileToDataUri = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve({
          dataUri: event.target.result,
          type: file.type,
          name: file.name,
          file: file,
        });
      };
      reader.readAsDataURL(file);
    });
  const saveBlob = async () => {
    let urllist = files;
    for (let i = 0; i < acceptedFiles.length; i++) {
      let valid = true;
      console.log(acceptedFiles[i].type);
      if (
        acceptedFiles[i].type == "image/jpeg" ||
        acceptedFiles[i].type == "image/jpg" ||
        acceptedFiles[i].type == "image/png"
      ) {
        if (acceptedFiles[i].size > 1 * 1024 * 1024) {
          valid = false;
          setMsg({
            type: "error",
            text: `Images should be less than 1MB in size.`,
          });
          setToast(true);
        }
      } else if (
        acceptedFiles[i].type == "video/mp4" ||
        acceptedFiles[i].type == "video/quicktime"
      ) {
        if (acceptedFiles[i].size > 2 * 1024 * 1024) {
          valid = false;
          setMsg({
            type: "error",
            text: `Videos should be less than 2MB in size.`,
          });
          setToast(true);
        }
      } else {
        valid = false;
        setMsg({
          type: "error",
          text: `Invalid format of the file`,
        });
        setToast(true);
      }
      if (valid) {
        const response = await fileToDataUri(acceptedFiles[i]);
        urllist.push(response);
      }
    }
    setContentParams({
      ...contentParams,
      fields: { ...contentParams.fields, files: urllist },
    });
    setFiles(urllist);
  };
  const handleSavePersonalDetails = (event) => {
    event.preventDefault();
    if (personalDetailsFormRef.current.checkValidity()) {
      setToast(false);
      setMsg({ text: "", type: "" });
      let data = contentParams.fields.personalDetails;
      let valid = true;
      let keys = Object.keys(personalDeatilsRequiredErr);
      for (let i = 0; i < keys.length; i++) {
        // if (keys[i] === 'dod') continue;
        if (!data[keys[i]]) {
          setMsg({
            type: "error",
            text: `${personalDeatilsRequiredErr[keys[i]]} is a mandatory field`,
          });
          setToast(true);
          valid = false;
          break;
        }
      }

      if (!nameRegex.test(contentParams.fields?.personalDetails?.firstName)) {
        console.log(
          "First name ===> ",
          contentParams.fields?.personalDetails?.firstName
        );
        setMsg({ type: "error", text: "First name is not valid" });
        setToast(true);
        valid = false;
      }
      if (
        !middleNameRegex.test(contentParams.fields?.personalDetails?.middleName)
      ) {
        console.log(
          "Middle name ===> ",
          contentParams.fields?.personalDetails?.middleName
        );
        setMsg({ type: "error", text: "Middle name is not valid" });
        setToast(true);
        valid = false;
      }
      if (
        !lastNameRegex.test(contentParams.fields?.personalDetails?.lastName)
      ) {
        console.log(
          "Last name ===> ",
          contentParams.fields?.personalDetails?.lastName
        );
        setMsg({ type: "error", text: "Last name is not valid" });
        setToast(true);
        valid = false;
      }

      if (
        contentParams.fields.personalDetails.obituryText?.length !== 0 &&
        contentParams.fields.personalDetails.obituryText?.length > 2000
      ) {
        valid = false;
        setMsg({
          type: "error",
          text: `Obituary text should not exceed the limit of 2000 characters.`,
        });
        setToast(true);
      }

      if (valid) {
        setToast(false);
        setMsg({ text: "", type: "" });
        setIsLoading(true);
        axios
          .post(`${baseUrl}/slideshow/create`, data, {
            headers: { Authorization: `Bearer ${loggedIn.token}` },
          })
          .then((res) => {
            let content = contentParams;
            setIsLoading(false);
            content.fields.personalDetails.slideShowId = res.data.id;
            content.fields.familyDetails.slideShowId = res.data.id;
            setContentParams(content);
            setMsg({
              type: "success",
              text: `Basic details ${id ? "Updated" : "saved"} successfully`,
            });
            setToast(true);
          })
          .catch((err) => {
            setIsLoading(false);
            if (err?.response?.data?.resultMsg === "User is inactive") {
              setMsg({
                type: "error",
                text: "The Super Admin has deactivated your account. Contact super admin for details.",
              });
              setToast(true);

              setTimeout(() => {
                Cookies.remove("userData");
                localStorage.removeItem("fha_logo");
                logUserIn(false);

                navigate("/");
              }, 2000);
            }
            console.log(err.response.data.message);
          });
      }
    } else {
      personalDetailsFormRef.current.reportValidity();
    }
  };
  const handleSaveFamilyDetails = (event) => {
    let valid = true;
    event.preventDefault();
    if (personalDetailsFormRef.current.checkValidity()) {
      if (
        contentParams?.fields?.familyDetails?.motherName?.length > 1 &&
        !motherNameRegex.test(contentParams?.fields?.familyDetails?.motherName)
      ) {
        console.log(
          "Mother name ===> ",
          contentParams?.fields?.familyDetails?.motherName
        );
        setMsg({ type: "error", text: "Mother name is not valid name" });
        setToast(true);
        valid = false;
      } else if (contentParams?.fields?.familyDetails?.relatives.length > 0) {
        for (
          let i = 0;
          i < contentParams?.fields?.familyDetails?.relatives.length;
          i++
        ) {
          const relative = contentParams?.fields?.familyDetails?.relatives[i];

          // if (relative.slideshowURL) {
          //   // If slideshowurl is available, remove name and existingName
          //   // delete relative.name;
          //   delete relative.existingName;
          // } else if (relative.name) {
          //   // If name is available, remove existingName and slideshowurl
          //   delete relative.existingName;
          //   // delete relative.slideshowurl;
          // }

          console.log("Relative ===> ", relative);

          // Additional validation logic for the 'name' property
          // if (relative.name && relative.name.length > 0) {
          // You can add your validation logic here for the 'name' property
          // For example, you can uncomment and modify the code block you provided previously
          // if (!relative.name.match(relativesRegex)) {
          //   console.log("Relatives  ===> ", relative.name);
          //   setMsg({
          //     type: "error",
          //     text: `Relatives ${relative.name} name is not valid`,
          //   });
          //   setToast(true);
          //   valid = false;
          // }
          // }
        }

        console.log(
          "handleSaveFamilyDetails => ",
          contentParams?.fields?.familyDetails?.relatives
        );
      }

      if (valid && contentParams.fields.familyDetails.slideShowId) {
        setToast(false);
        setMsg({ text: "", type: "" });
        setIsLoading(true);

        const filteredRelatives = contentParams.fields.familyDetails.relatives;
        // const filteredRelatives =
        //   contentParams.fields.familyDetails.relatives.filter((relative) => {
        //     // Check if relationId, slideshowURL, and name are non-empty strings
        //     return (
        //       typeof relative?.relationId === "string" &&
        //       typeof relative?.slideshowURL === "string" &&
        //       typeof relative?.name === "string" &&
        //       relative.relationId.trim() !== "" &&
        //       relative.slideshowURL.trim() !== "" &&
        //       relative.name.trim() !== ""
        //     );
        //   });
        //   console.log("filtered relatives==>", filteredRelatives);
        // contentParams.fields.familyDetails.relatives.filter(
        //   (relative) =>
        //     relative?.relationId == "" &&
        //     relative?.slideshowURL == "" &&
        //     relative?.name == ""
        // );
        // filteredRelatives = contentParams.fields.familyDetails.relatives.filter(
        // const filteredRelatives = filteredRelatives.filter(
        //   (relative) =>
        //     relative?.relationId !== "" &&
        //     relative?.slideshowURL !== "" &&
        //     relative?.name !== ""
        // );

        const familyDetails = {
          ...contentParams.fields.familyDetails,
          relatives: filteredRelatives,
        };
        const zerosArray = [];
        filteredRelatives.map(() => {
          zerosArray.push(0);
        });
        setRelativeFields(zerosArray);
        const updatedFamilyDetails = {
          ...familyDetails,
          relatives: filteredRelatives,
        };
        const updatedContentParams = {
          ...contentParams,
          fields: {
            ...contentParams.fields,
            familyDetails: updatedFamilyDetails,
          },
        };
        setContentParams(updatedContentParams);
        axios
          .put(`${baseUrl}/slideshow/update`, familyDetails, {
            headers: { Authorization: `Bearer ${loggedIn.token}` },
          })
          .then((res) => {
            setIsLoading(false);
            setMsg({
              type: "success",
              text: `Family details  ${id ? "Updated" : "saved"} successfully`,
            });
            setToast(true);
          })
          .catch((err) => {
            console.log(err);
            console.log("err.data==>", err.response.data.data);
            setMsg({
              type: "error",
              text: `${err.response.data.data}`,
            });
            setToast(true);

            if (err?.response?.data?.resultMsg === "User is inactive") {
              setMsg({
                type: "error",
                text: "The Super Admin has deactivated your account. Contact super admin for details.",
              });
              setToast(true);

              setTimeout(() => {
                Cookies.remove("userData");
                localStorage.removeItem("fha_logo");
                logUserIn(false);

                navigate("/");
              }, 2000);
            }
            setIsLoading(false);
          });
      } else if (!contentParams.fields.familyDetails.slideShowId) {
        setIsLoading(false);
        setMsg({
          type: "error",
          text: `Please submit basic details first`,
        });
        setToast(true);
      }
    } else {
      personalDetailsFormRef.current.reportValidity();
    }
  };
  useEffect(() => {
    if (acceptedFiles.length > 0) {
      saveBlob();
    }
  }, [acceptedFiles]);

  const handleInputChange = async (event) => {
    setToast(false);
    setMsg({ text: "", type: "" });
    let fields = contentParams.fields;
    // console.log("Fields ==> ", fields);
    const category = event.target.name.split("_")[0];
    const name = event.target.name.split("_")[1];
    const val = event.target.value;
    // console.log("category ==> ", category);
    // console.log("name ==> ", name);
    // console.log("Val ==> ", val);
    console.log("Name ==> ", event.target.name.split("_")[2]);
    // console.log("Value ==> ", event.target.value);
    // if (event.target.name.split("_")[2] === "existingname") {
    //   console.log("existingName");
    //   // fields[category][name] = val;
    // }

    if (event.target.name.split("_")[2] === "slideshowURL") {
      // User deselected the existing name, so clear existingUrl
      fields[category][name][event.target.name.split("_")[3]].name = "";
      fields[category][name][event.target.name.split("_")[3]].existingName = "";
      setTimeout(() => {
        fields[category][name][event.target.name.split("_")[3]].existingName =
          null;
      }, 0);
    }

    if (event.target.name.split("_")[2] === "existingName") {
      // Check if an existing name was selected or deselected
      console.log("existing name value ==> ", val);
      if (val === "Select existing relative") {
        console.log("Enter in existingName");
        // User deselected the existing name, so clear existingUrl
        fields[category][name][event.target.name.split("_")[3]].slideshowURL =
          "";
      } else {
        // console.log("val ==> ", val);
        // User selected an existing name, find the corresponding URL and store it
        const selectedExistingName = val;
        console.log("selectedExistingName ==> ", selectedExistingName);
        const selectedExisting = existingList.find(
          (item) =>
            `${item.Firstname} ${item.Lastname}` === selectedExistingName
        );

        if (selectedExisting) {
          fields[category][name][event.target.name.split("_")[3]].slideshowURL =
            selectedExisting?.url;
        }

        console.log("selectedExisting ==> ", selectedExisting);
      }
    }

    if (name == "relatives") {
      fields[category][name][event.target.name.split("_")[3]][
        event.target.name.split("_")[2]
      ] = val;
    } else {
      fields[category][name] = val;
    }
    console.log("Fields => ", fields);
    setContentParams({ ...contentParams, fields: fields });
    // console.log(
    //   "contentParams => ",
    //   contentParams.fields.familyDetails.relatives
    // );
    // contentParams.fields.familyDetails.relatives.map((relative) => {
    //   setExistingList(
    //     existingList.filter((list) => {
    //       return list.url !== relative.existingname;
    //     })
    //   );
    // });
  };
  const handleCreateSlideshow = () => {
    if (files.length === 0) {
      setMsg({
        type: "error",
        text: `Slideshow must contain at least one image or video.`,
      });
      setToast(true);
    } else if (files.length > 20) {
      setMsg({
        type: "error",
        text: `Maximum limit for media upload is 20 units`,
      });
      setToast(true);
    } else if (contentParams.fields.familyDetails.slideShowId) {
      setIsLoading(true);
      let formData = new FormData();
      let linkArr = "";
      files.map((file, index) => {
        if (file.type == "video/link") {
          linkArr += linkArr !== "" ? "," + file.dataUri : file.dataUri;
        } else {
          formData.append(`upload`, file.file);
        }
      });
      deletedMedia.length > 0 &&
        formData.append(
          `deleted_media[0][profile_id]`,
          deletedMedia[0].pma_profile_id
        );
      deletedMedia.map((media) => {
        if(!media.dataUri.startsWith('data')){
          formData.append(`deleted_media[0][dataUri]`, media.dataUri);
        }
      });
      formData.append("link", linkArr);
      formData.append(
        "slideShowId",
        contentParams.fields.familyDetails.slideShowId
      );
      axios
        .post(`${baseUrl}/slideshow/media/update`, formData, {
          headers: {
            Authorization: `Bearer ${loggedIn.token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.data.webUrl) {
            setMsg({
              type: "success",
              text: `Slideshow  ${id ? "Updated" : "Created"} Successfully`,
            });
            setToast(true);
            if (baseUrl.includes("vidavault.net")) {
              axios
                .post(
                  `${baseUrl}/slideshowqr/createQrCode`,
                  {
                    slideShowId: res.data.webUrl,
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${loggedIn.token}`,
                    },
                  }
                )
                .then((res) => {
                  setIsLoading(false);
                  setQrcode(res.data.qrCode);
                })
                .catch((err) => {
                  console.log(err);
                  setIsLoading(false);
                });
            }

            // navigate(`/slideshow/${res.data.webUrl}`);
            localStorage.removeItem("slideshowdata");
            setIsLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          if (err?.response?.data?.resultMsg === "User is inactive") {
            setMsg({
              type: "error",
              text: "The Super Admin has deactivated your account. Contact super admin for details.",
            });
            setToast(true);

            setTimeout(() => {
              Cookies.remove("userData");
              localStorage.removeItem("fha_logo");
              logUserIn(false);

              navigate("/");
            }, 2000);
          }
          setIsLoading(false);
        });
    } else {
      setMsg({
        type: "error",
        text: `Please submit family details first`,
      });
      setToast(true);
    }
  };

  // Preview Slide show
  const handlePreview = () => {
    if (files.length > 20) {
      setMsg({
        type: "error",
        text: `Maximum limit for media upload is 20 units`,
      });
      setToast(true);
    } else {
      setIsPreview(true);
    }
  };

  const handleOrderPlaque = () => {
    setIsLoading(true);
    axios
      .post(
        `${baseUrl}/slideshowor/createOrder`,
        { slideshow_id: contentParams.fields.personalDetails.slideShowId },
        {
          headers: { Authorization: `Bearer ${loggedIn.token}` },
        }
      )
      .then((res) => {
        if (res.data.resultCode == 1) {
          setMsg({
            type: "success",
            text: res.data.resultMsg,
          });
          setToast(true);
          setIsLoading(false);
          navigate("/fha/createslideshow");
        } else if (res.data.resultCode == 0) {
          setMsg({
            type: "error",
            text: res.data.resultMsg,
          });
          setToast(true);
          setIsLoading(false);
          navigate("/fha/subscriptions");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err.response.data.message);
        if (err?.response?.data?.resultMsg === "User is inactive") {
          setMsg({
            type: "error",
            text: "The Super Admin has deactivated your account. Contact super admin for details.",
          });
          setToast(true);
          setTimeout(() => {
            Cookies.remove("userData");
            localStorage.removeItem("fha_logo");
            logUserIn(false);

            navigate("/");
          }, 2000);
        }
      });
  };

  // console.log("Relatives ===>  ", contentParams?.fields?.familyDetails);

  const getRelativeName = async (slideshow_id, id) => {
    console.log("slideshowurl", slideshow_id);
    try {
      let url = slideshow_id?.split("slideshow/");
      if (
        contentParams?.fields?.familyDetails?.relatives[id]?.existingName ===
          null &&
        slideshow_id?.length > 0 &&
        url &&
        url[1] !== undefined
      ) {
        console.log("URL ===> ", url);
        axios(`${baseUrl}/slideshowname/${url[1]}`)
          .then((response) => {
            if (response.status === 200) {
              console.log("response ==> ", response);

              const updatedFields = { ...contentParams.fields };
              updatedFields.familyDetails.relatives[
                id
              ].name = `${response?.data?.data[0]?.Firstname} ${response?.data?.data[0]?.Lastname}`;
              console.log(
                "updatedFields ==> ",
                updatedFields?.familyDetails?.relatives[id]?.name
              );
              setContentParams({ ...contentParams, fields: updatedFields });
              console.log("setContentParams ==> ", contentParams);
            } else {
              console.log("Error ==> ", response);
              // Handle API call error here
              // setMsg({
              //   type: "error",
              //   text: response?.data?.message,
              // });
              // setToast(true);
            }
          })
          .catch((error) => {
            console.log("Error ==> ", error);
            // Handle API call error here
            setMsg({
              type: "error",
              text: error?.response?.data?.message,
            });
            setToast(true);
          });
      } else {
        // setMsg({
        //   type: "error",
        //   text: "URL is not valid",
        // });
        // setToast(true);
      }
    } catch (error) {
      // Handle API call error here
      console.log("Error ==> ", error);
      // setMsg({
      //   type: "error",
      //   text: error?.response?.data?.message,
      // });
      // setToast(true);
    }
    // }
  };

  const handleRemoverelative = (id) => {
    // Clone the content object to avoid mutating the state directly
    let content = { ...contentParams };

    // Clear the fields for the removed entry
    content.fields.familyDetails.relatives[id] = {
      name: "",
      relationId: "",
      existingName: "",
      slideshowURL: "",
    };

    // Remove the entry from the content
    content.fields.familyDetails.relatives.splice(id, 1);

    // Update the relativeFields array and the content
    setRelativeFields(relativeFields.filter((_, index) => index !== id));
    setContentParams(content);
  };

  const handleAddRelative = () => {
    // Create a new relative with entirely empty fields
    const newRelative = {
      name: "",
      relationId: "",
      existingName: "",
      slideshowURL: "",
    };

    // Clone the content object to avoid mutating the state directly
    let content = { ...contentParams };

    // Add the new relative to the content
    content.fields.familyDetails.relatives.push(newRelative);

    // Update the relativeFields array and the content
    setRelativeFields([...relativeFields, 0]);
    setContentParams(content);
  };

  return (
    <div style={{ height: "100vh" }}>
      {isPreview && (
        <div className="modals_preview animated bounceInUp" id="previewModal">
          {/* <div className="modal-contents"> */}
          {/* <span
                className="btn-close-modal text-right close-modal-blog-2"
                // onClick={closeModal}
              >
                &times;
              </span> */}
          <PreviewSlideshow
            contentParams={contentParams}
            closeModal={closeModal}
          />
          {/* </div>{" "} */}
        </div>
      )}
      <Helmet>
        <title>VidaVault</title>
      </Helmet>
      <Navbar />
      <div style={{ height: "85%" }} className="page-content">
        <Sidebar />
        <div className="content-wrapper">
          <div className="content-inner">
            <Subnav />
            {relationList && (
              <div className="content">
                {(!qrcode || id) && (
                  <>
                    <div className="card">
                      <div className="content d-flex justify-content-center align-items-center">
                        <form
                          className="flex-fill"
                          ref={personalDetailsFormRef}
                          onSubmit={handleSavePersonalDetails}
                        >
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="card mb-0">
                                <div className="card-body">
                                  <div className="text-center mb-3">
                                    <h2 className="mb-4 font-weight-bold">
                                      {id
                                        ? "Edit Slideshow"
                                        : "Create New Slideshow"}
                                    </h2>
                                    <i className="fa-solid fa-memo-circle-info text-success large-icon mb-3 mt-1"></i>
                                    <h5 className="mb-1">Add Details</h5>
                                    <span className="d-block text-muted mt-3">
                                      Please enter the profile information
                                      below.
                                    </span>
                                  </div>
                                  <div className="row">
                                    <div className="col-lg-4">
                                      <div className="form-group form-group-floating row">
                                        <div className="col-lg-12">
                                          <div className="position-relative">
                                            <input
                                              type="text"
                                              onChange={handleInputChange}
                                              minLength={2}
                                              maxLength={20}
                                              value={
                                                contentParams.fields
                                                  .personalDetails.firstName
                                              }
                                              pattern="/^[a-zA-Z]{1,}['-]?[a-zA-Z]+/gm;"
                                              name="personalDetails_firstName"
                                              className="form-control form-control-outline"
                                              placeholder="Placeholder"
                                            />
                                            <label className="label-floating">
                                              First Name
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    {/* Middle Name */}
                                    <div className="col-lg-4">
                                      <div className="form-group form-group-floating row">
                                        <div className="col-lg-12">
                                          <div className="position-relative">
                                            <input
                                              type="text"
                                              onChange={handleInputChange}
                                              minLength={2}
                                              maxLength={20}
                                              pattern="/^[a-zA-Z]{1,}['-]?[a-zA-Z]+/gm;"
                                              value={
                                                contentParams.fields
                                                  .personalDetails.middleName
                                              }
                                              name="personalDetails_middleName"
                                              className="form-control form-control-outline"
                                              placeholder="Placeholder"
                                            />
                                            <label className="label-floating">
                                              Middle Name
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-lg-4">
                                      <div className="form-group form-group-floating row">
                                        <div className="col-lg-12">
                                          <div className="position-relative">
                                            <input
                                              type="text"
                                              onChange={handleInputChange}
                                              value={
                                                contentParams.fields
                                                  .personalDetails.lastName
                                              }
                                              minLength={2}
                                              maxLength={20}
                                              name="personalDetails_lastName"
                                              className="form-control form-control-outline"
                                              placeholder="Placeholder"
                                            />
                                            <label className="label-floating">
                                              Last Name
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-lg-6">
                                      <div className="form-group form-group-floating row">
                                        <div className="col-lg-12">
                                          <div className="position-relative">
                                            <input
                                              type="date"
                                              className="form-control form-control-outline"
                                              value={
                                                contentParams.fields
                                                  .personalDetails.dob
                                              }
                                              onChange={(event) => {
                                                contentParams.fields.personalDetails.dod =
                                                  null;
                                                handleInputChange(event);
                                              }}
                                              name="personalDetails_dob"
                                              max={todayStr}
                                            />

                                            <label className="label-floating">
                                              Date of Birth
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-6">
                                      <div className="form-group form-group-floating row">
                                        <div className="col-lg-12">
                                          <div className="position-relative">
                                            <input
                                              type="date"
                                              value={
                                                contentParams.fields
                                                  .personalDetails.dod
                                              }
                                              disabled={
                                                !contentParams.fields
                                                  .personalDetails.dob
                                              }
                                              className="form-control form-control-outline"
                                              onChange={handleInputChange}
                                              name="personalDetails_dod"
                                              min={
                                                contentParams.fields
                                                  .personalDetails.dob
                                              }
                                              max={todayStr}
                                            />
                                            <label className="label-floating">
                                              Date of Death
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-lg-3">
                                      <div className="form-group form-group-floating row">
                                        <div className="col-lg-12">
                                          <div className="position-relative">
                                            <input
                                              type="text"
                                              className="form-control form-control-outline"
                                              onChange={handleInputChange}
                                              value={
                                                contentParams.fields
                                                  .personalDetails.birthCity
                                              }
                                              pattern="^[^0-9]*$"
                                              minLength={2}
                                              maxLength={25}
                                              name="personalDetails_birthCity"
                                              placeholder="Placeholder"
                                            />
                                            <label className="label-floating">
                                              Place of Birth: City
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-3">
                                      <div className="form-group form-group-floating row">
                                        <div className="col-lg-12">
                                          <div className="position-relative">
                                            <select
                                              onChange={handleInputChange}
                                              value={
                                                contentParams.fields
                                                  .personalDetails.birthState
                                              }
                                              name="personalDetails_birthState"
                                              className="custom-select form-control-outline-within"
                                            >
                                              <option
                                                value=""
                                                disabled
                                                hidden
                                                selected
                                              >
                                                Please select state
                                              </option>
                                              <option value="AL">
                                                Alabama
                                              </option>
                                              <option value="AK">Alaska</option>
                                              <option value="AZ">
                                                Arizona
                                              </option>
                                              <option value="AR">
                                                Arkansas
                                              </option>
                                              <option value="CA">
                                                California
                                              </option>
                                              <option value="CO">
                                                Colorado
                                              </option>
                                              <option value="CT">
                                                Connecticut
                                              </option>
                                              <option value="DE">
                                                Delaware
                                              </option>
                                              <option value="DC">
                                                District Of Columbia
                                              </option>
                                              <option value="FL">
                                                Florida
                                              </option>
                                              <option value="GA">
                                                Georgia
                                              </option>
                                              <option value="HI">Hawaii</option>
                                              <option value="ID">Idaho</option>
                                              <option value="IL">
                                                Illinois
                                              </option>
                                              <option value="IN">
                                                Indiana
                                              </option>
                                              <option value="IA">Iowa</option>
                                              <option value="KS">Kansas</option>
                                              <option value="KY">
                                                Kentucky
                                              </option>
                                              <option value="LA">
                                                Louisiana
                                              </option>
                                              <option value="ME">Maine</option>
                                              <option value="MD">
                                                Maryland
                                              </option>
                                              <option value="MA">
                                                Massachusetts
                                              </option>
                                              <option value="MI">
                                                Michigan
                                              </option>
                                              <option value="MN">
                                                Minnesota
                                              </option>
                                              <option value="MS">
                                                Mississippi
                                              </option>
                                              <option value="MO">
                                                Missouri
                                              </option>
                                              <option value="MT">
                                                Montana
                                              </option>
                                              <option value="NE">
                                                Nebraska
                                              </option>
                                              <option value="NV">Nevada</option>
                                              <option value="NH">
                                                New Hampshire
                                              </option>
                                              <option value="NJ">
                                                New Jersey
                                              </option>
                                              <option value="NM">
                                                New Mexico
                                              </option>
                                              <option value="NY">
                                                New York
                                              </option>
                                              <option value="NC">
                                                North Carolina
                                              </option>
                                              <option value="ND">
                                                North Dakota
                                              </option>
                                              <option value="OH">Ohio</option>
                                              <option value="OK">
                                                Oklahoma
                                              </option>
                                              <option value="OR">Oregon</option>
                                              <option value="PA">
                                                Pennsylvania
                                              </option>
                                              <option value="RI">
                                                Rhode Island
                                              </option>
                                              <option value="SC">
                                                South Carolina
                                              </option>
                                              <option value="SD">
                                                South Dakota
                                              </option>
                                              <option value="TN">
                                                Tennessee
                                              </option>
                                              <option value="TX">Texas</option>
                                              <option value="UT">Utah</option>
                                              <option value="VT">
                                                Vermont
                                              </option>
                                              <option value="VA">
                                                Virginia
                                              </option>
                                              <option value="WA">
                                                Washington
                                              </option>
                                              <option value="WV">
                                                West Virginia
                                              </option>
                                              <option value="WI">
                                                Wisconsin
                                              </option>
                                              <option value="WY">
                                                Wyoming
                                              </option>
                                            </select>
                                            <label className="label-floating">
                                              Place of Birth: State
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-lg-3">
                                      <div className="form-group form-group-floating row">
                                        <div className="col-lg-12">
                                          <div className="position-relative">
                                            <input
                                              type="text"
                                              className="form-control form-control-outline"
                                              onChange={handleInputChange}
                                              pattern="^[^0-9]*$"
                                              value={
                                                contentParams.fields
                                                  .personalDetails.deathCity
                                              }
                                              minLength={2}
                                              maxLength={25}
                                              name="personalDetails_deathCity"
                                              placeholder="Placeholder"
                                            />
                                            <label className="label-floating">
                                              Place of Death: City
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-3">
                                      <div className="form-group form-group-floating row">
                                        <div className="col-lg-12">
                                          <div className="position-relative">
                                            <select
                                              onChange={handleInputChange}
                                              value={
                                                contentParams.fields
                                                  .personalDetails.deathState
                                              }
                                              name="personalDetails_deathState"
                                              className="custom-select form-control-outline-within"
                                            >
                                              <option
                                                value=""
                                                disabled
                                                hidden
                                                selected
                                              >
                                                Please select state
                                              </option>
                                              <option value="AL">
                                                Alabama
                                              </option>
                                              <option value="AK">Alaska</option>
                                              <option value="AZ">
                                                Arizona
                                              </option>
                                              <option value="AR">
                                                Arkansas
                                              </option>
                                              <option value="CA">
                                                California
                                              </option>
                                              <option value="CO">
                                                Colorado
                                              </option>
                                              <option value="CT">
                                                Connecticut
                                              </option>
                                              <option value="DE">
                                                Delaware
                                              </option>
                                              <option value="DC">
                                                District Of Columbia
                                              </option>
                                              <option value="FL">
                                                Florida
                                              </option>
                                              <option value="GA">
                                                Georgia
                                              </option>
                                              <option value="HI">Hawaii</option>
                                              <option value="ID">Idaho</option>
                                              <option value="IL">
                                                Illinois
                                              </option>
                                              <option value="IN">
                                                Indiana
                                              </option>
                                              <option value="IA">Iowa</option>
                                              <option value="KS">Kansas</option>
                                              <option value="KY">
                                                Kentucky
                                              </option>
                                              <option value="LA">
                                                Louisiana
                                              </option>
                                              <option value="ME">Maine</option>
                                              <option value="MD">
                                                Maryland
                                              </option>
                                              <option value="MA">
                                                Massachusetts
                                              </option>
                                              <option value="MI">
                                                Michigan
                                              </option>
                                              <option value="MN">
                                                Minnesota
                                              </option>
                                              <option value="MS">
                                                Mississippi
                                              </option>
                                              <option value="MO">
                                                Missouri
                                              </option>
                                              <option value="MT">
                                                Montana
                                              </option>
                                              <option value="NE">
                                                Nebraska
                                              </option>
                                              <option value="NV">Nevada</option>
                                              <option value="NH">
                                                New Hampshire
                                              </option>
                                              <option value="NJ">
                                                New Jersey
                                              </option>
                                              <option value="NM">
                                                New Mexico
                                              </option>
                                              <option value="NY">
                                                New York
                                              </option>
                                              <option value="NC">
                                                North Carolina
                                              </option>
                                              <option value="ND">
                                                North Dakota
                                              </option>
                                              <option value="OH">Ohio</option>
                                              <option value="OK">
                                                Oklahoma
                                              </option>
                                              <option value="OR">Oregon</option>
                                              <option value="PA">
                                                Pennsylvania
                                              </option>
                                              <option value="RI">
                                                Rhode Island
                                              </option>
                                              <option value="SC">
                                                South Carolina
                                              </option>
                                              <option value="SD">
                                                South Dakota
                                              </option>
                                              <option value="TN">
                                                Tennessee
                                              </option>
                                              <option value="TX">Texas</option>
                                              <option value="UT">Utah</option>
                                              <option value="VT">
                                                Vermont
                                              </option>
                                              <option value="VA">
                                                Virginia
                                              </option>
                                              <option value="WA">
                                                Washington
                                              </option>
                                              <option value="WV">
                                                West Virginia
                                              </option>
                                              <option value="WI">
                                                Wisconsin
                                              </option>
                                              <option value="WY">
                                                Wyoming
                                              </option>
                                            </select>
                                            <label className="label-floating">
                                              Place of Death: State
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-lg-12">
                                      <div className="form-group form-group-floating row">
                                        <div className="col-lg-12">
                                          <div className="position-relative">
                                            <textarea
                                              className="form-control form-control-outline"
                                              placeholder="Placeholder"
                                              onChange={handleInputChange}
                                              value={
                                                contentParams.fields
                                                  .personalDetails.obituryText
                                              }
                                              name="personalDetails_obituryText"
                                              rows="5"
                                              cols="5"
                                              maxLength={2000}
                                            ></textarea>
                                            <label className="label-floating">
                                              Obituary Text
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="card-footer bg-transparent text-lg-right text-md-center text-sm-center text-center">
                                    <button
                                      type="submit"
                                      className="btn btn-teal btn-labeled btn-labeled-right"
                                    >
                                      <b>
                                        <i className="fa-solid fa-plus"></i>
                                      </b>
                                      Save Details
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>

                    <div className="card">
                      <div className="content d-flex justify-content-center align-items-center">
                        <form
                          ref={familyDetailsFormRef}
                          onSubmit={handleSaveFamilyDetails}
                          className="flex-fill"
                        >
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="card mb-0">
                                <div className="card-body">
                                  <div className="text-center mb-3">
                                    <i className="fa-solid fa-people-group text-success large-icon mb-3 mt-1"></i>
                                    <h5 className="mb-1">Family Tree</h5>
                                    <span className="d-block text-muted mt-3">
                                      Add relatives to create the family tree.
                                    </span>
                                  </div>
                                  <div className="row">
                                    <div className="col-lg-6">
                                      <div className="form-group form-group-floating row">
                                        <div className="col-lg-12">
                                          <div className="position-relative">
                                            <input
                                              type="text"
                                              onChange={handleInputChange}
                                              value={
                                                contentParams.fields
                                                  .familyDetails.motherName
                                              }
                                              minLength={2}
                                              maxLength={20}
                                              pattern="/^[a-zA-Z]{1,}['- ]?[a-zA-Z]+/gm;"
                                              name="familyDetails_motherName"
                                              className="form-control form-control-outline"
                                              placeholder="Placeholder"
                                            />
                                            <label className="label-floating">
                                              Mother's Name
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-lg-6">
                                      <div className="form-group form-group-floating row">
                                        <div className="col-lg-12">
                                          <div className="position-relative">
                                            <input
                                              type="text"
                                              onChange={handleInputChange}
                                              value={
                                                contentParams.fields
                                                  .familyDetails.fatherName
                                              }
                                              minLength={2}
                                              maxLength={20}
                                              pattern="^[^0-9]*$"
                                              name="familyDetails_fatherName"
                                              className="form-control form-control-outline"
                                              placeholder="Placeholder"
                                            />
                                            <label className="label-floating">
                                              Father's Name
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    {relativeFields.map((value, id) => (
                                      <div className="row" key={id}>
                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                          <div className="form-group form-group-floating">
                                            <div className="position-relative d-flex">
                                              <select
                                                onChange={handleInputChange}
                                                name={`familyDetails_relatives_existingName_${id}`}
                                                value={
                                                  contentParams.fields
                                                    .familyDetails?.relatives[
                                                    id
                                                  ]?.existingName
                                                }
                                                className="custom-select form-control-outline-within"
                                                disabled={
                                                  contentParams.fields
                                                    .familyDetails?.relatives[
                                                    id
                                                  ]?.name?.length > 0 ||
                                                  (contentParams.fields
                                                    .familyDetails?.relatives[
                                                    id
                                                  ]?.relationId !== "" &&
                                                    contentParams.fields
                                                      .familyDetails?.relatives[
                                                      id
                                                    ]?.slideshowURL?.length >
                                                      0 &&
                                                    contentParams.fields
                                                      .familyDetails?.relatives[
                                                      id
                                                    ]?.existingName === "")
                                                }
                                              >
                                                <option value={null} selected>
                                                  {existingList.length == 0
                                                    ? "No existing relative"
                                                    : "Select existing relative"}
                                                </option>
                                                {existingList?.map((rel) => (
                                                  <option
                                                    key={rel.url}
                                                    value={`${rel?.Firstname} ${rel?.Lastname}`}
                                                  >
                                                    {rel?.Firstname}{" "}
                                                    {rel?.Lastname}
                                                  </option>
                                                ))}
                                              </select>
                                              <label className="label-floating">
                                                Select Name From VidaVault
                                              </label>
                                            </div>
                                            <span className="relative-hint">
                                              Have existing relatives? Select
                                              from dropdown
                                            </span>
                                          </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                          <div className="form-group form-group-floating">
                                            <div className="position-relative">
                                              <input
                                                type="text"
                                                onChange={handleInputChange}
                                                name={`familyDetails_relatives_slideshowURL_${id}`}
                                                value={
                                                  contentParams.fields
                                                    .familyDetails?.relatives[
                                                    id
                                                  ]?.slideshowURL
                                                }
                                                onBlur={() =>
                                                  getRelativeName(
                                                    contentParams.fields
                                                      .familyDetails?.relatives[
                                                      id
                                                    ]?.slideshowURL,
                                                    id
                                                  )
                                                }
                                                className="form-control form-control-outline"
                                                placeholder="Placeholder"
                                              />
                                              <label className="label-floating">
                                                Relative's VidaVault Slideshow
                                                URL
                                              </label>
                                            </div>
                                            <span className="relative-hint">
                                              Want to link a relative to this
                                              slideshow?
                                            </span>
                                          </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                          <div className="form-group form-group-floating">
                                            <div className="position-relative">
                                              <input
                                                type="text"
                                                onChange={handleInputChange}
                                                name={`familyDetails_relatives_name_${id}`}
                                                minLength={2}
                                                maxLength={80}
                                                value={
                                                  contentParams.fields
                                                    .familyDetails?.relatives[
                                                    id
                                                  ]?.name
                                                }
                                                className="form-control form-control-outline"
                                                placeholder="Placeholder"
                                                disabled={
                                                  (contentParams.fields
                                                    .familyDetails?.relatives[
                                                    id
                                                  ]?.existingName &&
                                                    contentParams.fields
                                                      .familyDetails?.relatives[
                                                      id
                                                    ]?.existingName !==
                                                      "Select existing relative") ||
                                                  contentParams.fields
                                                    .familyDetails?.relatives[
                                                    id
                                                  ]?.slideshowURL?.length > 0
                                                }
                                              />
                                              <label className="label-floating">
                                                Relative's Name
                                              </label>
                                            </div>
                                            <span className="relative-hint">
                                              Enter the relative's name if not
                                              connecting with a URL
                                            </span>
                                          </div>
                                        </div>
                                        <div className="col-lg-2 col-md-6 col-sm-12">
                                          <div className="form-group form-group-floating">
                                            <div className="position-relative d-flex">
                                              <select
                                                onChange={handleInputChange}
                                                name={`familyDetails_relatives_relationId_${id}`}
                                                value={
                                                  contentParams.fields
                                                    .familyDetails?.relatives[
                                                    id
                                                  ]?.relationId
                                                }
                                                className="custom-select form-control-outline-within"
                                              >
                                                <option
                                                  value=""
                                                  disabled
                                                  hidden
                                                  selected
                                                >
                                                  Please select relation
                                                </option>
                                                {relationList?.map((rel) => (
                                                  <option value={rel.rm_id}>
                                                    {rel.rm_desc}
                                                  </option>
                                                ))}
                                              </select>
                                              <label className="label-floating">
                                                Relation
                                              </label>
                                            </div>
                                            <span className="relative-hint">
                                              * Select type of relation
                                            </span>
                                          </div>
                                        </div>
                                        <div className="col-lg-1 col-md-6 col-sm-12">
                                          <button
                                            type="button"
                                            class="btn "
                                            title="Remove relative"
                                            onClick={() =>
                                              handleRemoverelative(id)
                                            }
                                          >
                                            <i
                                              className="fa fa-times"
                                              style={{
                                                color: "red",
                                                fontSize: "20px",
                                                marginTop: "8px",
                                              }}
                                            ></i>
                                          </button>
                                        </div>
                                      </div>
                                    ))}
                                  </div>

                                  <div className="row col-lg-3">
                                    <button
                                      onClick={
                                        handleAddRelative
                                        // () => {
                                        //   let content = contentParams;
                                        //   let blankData = [
                                        //     {
                                        //       name: "",
                                        //       relationId: "",
                                        //       existingName: "",
                                        //       slideshowURL: "",
                                        //     },
                                        //   ];
                                        //   content.fields.familyDetails.relatives.push(
                                        //     ...blankData
                                        //   );
                                        //   setRelativeFields([
                                        //     ...relativeFields,
                                        //     0,
                                        //   ]);
                                        //   setContentParams(content);
                                        // }
                                      }
                                      className="btn btn-light"
                                      type="button"
                                    >
                                      Add More Relatives
                                    </button>
                                  </div>
                                  <div className="card-footer bg-transparent text-lg-right text-md-center text-sm-center text-center">
                                    <button
                                      type="submit"
                                      className="btn btn-teal btn-labeled btn-labeled-right"
                                    >
                                      <b>
                                        <i className="fa-solid fa-plus"></i>
                                      </b>
                                      Save Family Tree
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="card">
                      <div className="content d-flex justify-content-center align-items-center">
                        <div className="col-lg-12">
                          <div className="card mb-0">
                            <div className="card-body">
                              <div className="text-center mb-3 createSlideShow">
                                <i
                                  {...getRootProps()}
                                  className="fa-solid fa-photo-film text-success large-icon mb-3 mt-1"
                                  style={{
                                    padding: "6px",
                                    border: "2px dotted black",
                                    borderRadius: "7px",
                                    cursor: "pointer",
                                  }}
                                ></i>
                                <input
                                  accept="image/jpeg, image/png, video/mp4"
                                  {...getInputProps()}
                                />
                                <h5 className="mb-1">
                                  <span
                                    {...getRootProps()}
                                    accept="image/jpeg, image/png, video/mp4"
                                    className="hidden-xs btn btn-indigo"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <input
                                      accept="image/jpeg, image/png, video/mp4"
                                      {...getInputProps()}
                                    />
                                    Add Slideshow Media
                                  </span>
                                </h5>
                                <span className="d-block text-muted mt-3">
                                  Slideshow must contain at least one image or
                                  video.
                                </span>
                              </div>
                              <h5 className="mb-1">
                                Add Youtube or Vimeo Videos to the Slideshow
                              </h5>

                              <div className="form-group row">
                                <div className="col-lg-12">
                                  <div className="form-group form-group-floating row">
                                    <div className="col-lg-10">
                                      <div className="position-relative">
                                        <input
                                          type="text"
                                          className="form-control form-control-outline"
                                          placeholder="Placeholder"
                                          value={videoLink}
                                          onChange={(e) =>
                                            setVideoLink(e.target.value)
                                          }
                                        />
                                        <label className="label-floating">
                                          Add Youtube or Vimeo Video URL
                                        </label>
                                        <span className="relative-hint">
                                          Please paste the YouTube or Vimeo
                                          video share URL here
                                        </span>
                                      </div>
                                    </div>

                                    <div className="col-lg-2">
                                      <div className="form-group form-group-floating row">
                                        <div className="col-lg-12">
                                          <div
                                            className="btn btn-indigo btn-file form-control"
                                            tabIndex="500"
                                            onClick={() => {
                                              if (linkRegex.test(videoLink)) {
                                                let urllist = [
                                                  ...files,
                                                  {
                                                    type: "video/link",
                                                    dataUri: videoLink,
                                                    name: videoLink,
                                                  },
                                                ];
                                                setFiles(urllist);
                                                setContentParams({
                                                  ...contentParams,
                                                  fields: {
                                                    ...contentParams.fields,
                                                    files: urllist,
                                                  },
                                                });

                                                setVideoLink("");
                                              } else {
                                                setMsg({
                                                  type: "error",
                                                  text: "Please Enter a Valid Link",
                                                });
                                                setToast(true);
                                              }
                                            }}
                                          >
                                            <i
                                              style={{ marginRight: "0.6rem" }}
                                              className="fa-sharp fa-solid fa-film"
                                            ></i>
                                            <span className="hidden-xs">
                                              Add Video
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="form-group row">
                                <div className="col-lg-12">
                                  {files.map((file, index) => (
                                    <div
                                      style={{
                                        padding: "0.5rem",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div>
                                        {file.name.length > 60
                                          ? file.name.slice(0, 60) + "..."
                                          : file.name}
                                      </div>
                                      <i
                                        onClick={() => {
                                          let urllist = files;
                                          let deletedUrlList = deletedMedia;
                                          let a = urllist.splice(index, 1);
                                          if (id) {
                                            deletedUrlList.push(a[0]);
                                            setDeletedMedia(deletedUrlList);
                                          }
                                          setFiles(urllist);
                                          setContentParams({
                                            ...contentParams,
                                            fields: {
                                              ...contentParams.fields,
                                              files: urllist,
                                            },
                                          });
                                        }}
                                        className="fa fa-times"
                                        title="Remove slideshow media"
                                        style={{
                                          fontSize: "1rem",
                                          cursor: "pointer",
                                          color: "red",
                                          marginRight: "3rem",
                                        }}
                                      ></i>
                                    </div>
                                  ))}
                                </div>
                              </div>
                              <div className="card-footer bg-transparent text-lg-right text-md-center text-sm-center text-center">
                                {/* <Link
                            to={"/slideshow"}
                            state={{ data: contentParams.fields }}
                            
                            className="btn btn-warning btn-labeled btn-labeled-right mr-2"
                          >
                            <b>
                              <i className="fa-solid fa-eye"></i>
                            </b>
                            Preview Slideshow
                          </Link> */}
                                {contentParams.fields.familyDetails
                                  .slideShowId &&
                                  files.length != 0 && (
                                    <button
                                      onClick={handlePreview}
                                      // onClick={() => {
                                      //   try {
                                      //     window.localStorage.setItem(
                                      //       "slideshowdata",
                                      //       JSON.stringify(contentParams.fields)
                                      //     );
                                      //     window.open(
                                      //       `${window.location.origin}/fha/slideshow`,
                                      //       "_blank"
                                      //     );
                                      //   } catch (error) {
                                      //     console.log(
                                      //       "Local storage erro handling ==> ",
                                      //       error
                                      //     );
                                      //     if (
                                      //       error.name === "QuotaExceededError"
                                      //     ) {
                                      //       // Handle the error - notify the user or take appropriate action
                                      //       console.error(
                                      //         "Storage quota exceeded"
                                      //       );

                                      //       // setMsg({
                                      //       //   type: "error",
                                      //       //   text: "Uploaded media exceeded the preview option. Please create the slideshow and view from the slideshow library",
                                      //       // });
                                      //       // setToast(true);

                                      //     } else {
                                      //       // Handle other localStorage related errors
                                      //       console.error(
                                      //         "Error while storing data:",
                                      //         error.message
                                      //       );
                                      //     }
                                      //   }
                                      // }}
                                      type="button"
                                      className="btn btn-warning btn-labeled btn-labeled-right mr-2"
                                    >
                                      <b>
                                        <i className="fa-solid fa-eye"></i>
                                      </b>
                                      Preview Slideshow
                                    </button>
                                  )}
                                <div
                                  onClick={handleCreateSlideshow}
                                  className="btn btn-teal btn-labeled btn-labeled-right"
                                >
                                  <b>
                                    <i className="fa-solid fa-plus"></i>
                                  </b>
                                  {id ? "Edit Slideshow" : "Create Slideshow"}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {qrcode && (
                  <div className="card">
                    <div className="content d-flex justify-content-center align-items-center">
                      <div className="col-lg-12">
                        <div className="card mb-0">
                          <div className="card-body">
                            <div className="text-center mb-3">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <div
                                  style={{
                                    width: "30%",
                                    display: "flex",
                                    justifyContent: "space-around",
                                    marginBottom: "1rem",
                                  }}
                                >
                                  <FacebookShareButton url={qrcode}>
                                    <FacebookIcon size={32} round={true} />
                                  </FacebookShareButton>
                                  <TwitterShareButton url={qrcode}>
                                    <TwitterIcon size={32} round={true} />
                                  </TwitterShareButton>
                                  {/* <InstagramEmbed url={qrcode}>
                                  Share on Instagram
                                </InstagramEmbed> */}
                                  <WhatsappShareButton url={qrcode}>
                                    <WhatsappIcon size={32} round={true} />
                                  </WhatsappShareButton>
                                </div>
                              </div>
                              <img
                                style={{ width: "30%" }}
                                src={qrcode}
                                alt=""
                              />
                              {/* <h5 className="mb-1">Create QR Code</h5> */}
                              <span className="d-block text-muted mt-3">
                                Scan this QR code to view slideshow
                              </span>
                              {/* <p>Beaconstac QR API to be added here...</p> */}
                            </div>

                            <div className="card-footer bg-transparent text-right">
                              <div
                                onClick={handleOrderPlaque}
                                className="btn btn-teal btn-labeled btn-labeled-right"
                              >
                                <b>
                                  <i className="fa-solid fa-plus"></i>
                                </b>{" "}
                                Order QR Plaque
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}

            <div className="navbar navbar-expand-lg navbar-light">
              <div className="text-center d-lg-none w-100">
                <button
                  type="button"
                  className="navbar-toggler dropdown-toggle"
                  data-toggle="collapse"
                  data-target="#navbar-footer"
                >
                  <i className="icon-unfold mr-2"></i>
                  Open Footer
                </button>
              </div>

              <div className="navbar-collapse collapse" id="navbar-footer">
                <span className="navbar-text">
                  <span>&copy; 2023 VidaVault</span>. All rights reserved.
                </span>

                <ul className="navbar-nav ml-lg-auto">
                  <li className="nav-item">
                    <NavLink to="/fha/support" className="navbar-nav-link">
                      <i className="fa-solid fa-life-ring mr-2"></i> Support
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Createslideshow;
