/** @format */

import React, { useContext, useEffect, useState, useRef, useMemo } from "react";
import { Helmet } from "react-helmet";
import Sidebar from "../../components/Sidebar";
import { useDropzone } from "react-dropzone";
import { Link, NavLink, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import { useTable, useFilters, usePagination } from "react-table";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { baseUrl } from "../../config";
import Navbar from "../../components/Navbar";
import { UserContext } from "../../UserProvider";
import Popup from "../../components/Popup";
import Confirmation from "../../components/Confirmation";
import ColumnFilter from "../../components/ColumnFilter";
import Subnav from "../../components/Subnav";
import Cookies from "js-cookie";
const SlideshowLibrary = () => {
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone();
  const { loggedIn, logUserIn, setMsg, setToast, isOpen, msg, setIsLoading } =
    useContext(UserContext);
  const [args, setArgs] = useState([]);
  const [popupTrigger, setPopupTrigger] = useState(false);
  const [popupText, setPopupText] = useState();
  const [popupTitle, setPopupTitle] = useState();
  const [tableData, setTableData] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const toggleDropdown = (req) => {
    if (dropdownOpen) {
      setDropdownOpen(false);
    } else {
      setDropdownOpen(req.fha_id);
    }
  };

  const handleEdit = (slideshow) => {
    navigate(`/fha/editslideshow/${slideshow.pm_webURL}`);
  };
  const confirmed = () => {
    setIsLoading(true);
    setPopupTrigger();
    setPopupTitle();
    setPopupText();
    axios
      .delete(`${baseUrl}/slideshow/${args.pm_id}`, {
        headers: { Authorization: `Bearer ${loggedIn.token}` },
      })
      .then((res) => {
        if (res.data.resultCode == 1) {
          setMsg({
            type: "success",
            text: `Slideshow deleted successfully`,
          });
          setToast(true);
          fetchData();

          setArgs();
        }
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.data?.resultMsg === "User is inactive") {
          setMsg({
            type: "error",
            text: "The Super Admin has deactivated your account. Contact super admin for details.",
          });
          setToast(true);
          setTimeout(() => {
            Cookies.remove("userData");
            localStorage.removeItem("fha_logo");
            logUserIn(false);
            navigate("/");
          }, 2000);
        }
      });
  };
  const canceled = () => {
    setPopupTrigger();
    setPopupTitle();
    setPopupText();
    setArgs();
  };
  const handleView = (slideshow) => {
    axios
      .post(`${baseUrl}/slideshow/${slideshow.pm_webURL}`, {
        headers: { Authorization: `Bearer ${loggedIn.token}` },
      })
      .then((res) => {
        window.localStorage.setItem(
          "userToken",
          JSON.stringify(loggedIn.token)
        );
        window.open(
          `${window.location.origin}/fha/slideshow/${res.data.data[0].pm_webURL}`,
          "_blank"
        );
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.data?.resultMsg === "User is inactive") {
          setMsg({
            type: "error",
            text: "The Super Admin has deactivated your account. Contact super admin for details.",
          });
          setToast(true);

          setTimeout(() => {
            Cookies.remove("userData");
            localStorage.removeItem("fha_logo");
            logUserIn(false);

            navigate("/");
          }, 2000);
        }
      });
  };
  const fetchData = () => {
    setIsLoading(true);
    axios
      .get(`${baseUrl}/slideshows`, {
        headers: { Authorization: `Bearer ${loggedIn.token}` },
      })
      .then((res) => {
        setTableData(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.data?.resultMsg === "User is inactive") {
          setMsg({
            type: "error",
            text: "The Super Admin has deactivated your account. Contact super admin for details.",
          });
          setToast(true);

          setTimeout(() => {
            Cookies.remove("userData");
            localStorage.removeItem("fha_logo");
            logUserIn(false);

            navigate("/");
          }, 2000);
        }
        setIsLoading(false);
      });
  };
  useEffect(() => {
    fetchData();
  }, []);
  const getReadableDate = (x) => {
    let d = new Date(x);
    let options = { month: "2-digit", day: "2-digit", year: "numeric" };
    return d.toLocaleDateString("en-US", options).replace(/\//g, "-");
  };
  const columns = useMemo(
    () => [
      {
        Header: "First Name",
        accessor: "firstName",
        Filter: ColumnFilter,
      },
      {
        Header: "Middle Name",
        accessor: "middleName",
        Filter: ColumnFilter,
      },
      {
        Header: "Last Name",
        accessor: "lastName",
        Filter: ColumnFilter,
      },
      {
        Header: "Date Added",
        accessor: "pm_created_dt",
        Cell: ({ row }) => {
          return getReadableDate(row.original.pm_created_dt);
        },
        filter: "date",
        Filter: ({ column }) => (
          <div className="">
            {/* <DatePicker
              className="form-control form-control-sm"
              selected={column.filterValue || ""}
              onChange={(date) => {
                column.setFilter(date || undefined);
              }}
              dateFormat="MM-dd-yyyy"
              placeholderText="MM-DD-YYYY"
            /> */}
            <input
              className="form-control form-control-sm"
              type="date"
              value={column.filterValue || ""}
              onChange={(event) => {
                column.setFilter(event.target.value || undefined);
              }}
            />
          </div>
        ),
      },
      // {
      //   Header: "QR status",
      //   accessor: "is_active",
      //   Cell: ({ row }) => {
      //     const req = row.original;
      //     return (
      //       <>
      //         {req.is_active == 0 && (
      //           <button
      //             type="button"
      //             className="btn btn-sm link-primary btn-danger"
      //             style={{ textTransform: "none" }}
      //           >
      //             Inactive
      //           </button>
      //         )}
      //         {req.is_active == 1 && (
      //           <button
      //             type="button"
      //             className="btn btn-sm link-primary btn-success"
      //             style={{ textTransform: "none" }}
      //           >
      //             Active
      //           </button>
      //         )}
      //         {req.is_active == 2 && (
      //           <button
      //             type="button"
      //             className="btn btn-sm link-primary btn-warning"
      //             style={{ textTransform: "none" }}
      //           >
      //             Pending
      //           </button>
      //         )}
      //       </>
      //     );
      //   },
      //   Filter: ({ column }) => (
      //     <div className="">
      //       <select
      //         className="form-control form-control-sm"
      //         value={column.filterValue || ""}
      //         onChange={(event) => {
      //           column.setFilter(event.target.value || undefined);
      //         }}
      //       >
      //         <option value="">All</option>
      //         <option value="0">Inactive</option>
      //         <option value="1">Active</option>
      //         <option value="2">Pending</option>
      //       </select>
      //     </div>
      //   ),
      // },
      {
        Header: "Actions",
        Cell: ({ row }) => {
          const ref = useRef(null);
          const [dropdownOpen, setDropdownOpen] = useState(false);
          const req = row.original;
          useEffect(() => {
            function handleClickOutside(event) {
              if (ref.current && !ref.current.contains(event.target)) {
                setDropdownOpen(false);
              }
            }

            document.addEventListener("mousedown", handleClickOutside);

            return () => {
              document.removeEventListener("mousedown", handleClickOutside);
            };
          }, [ref]);
          return (
            <div className="btn-group">
              <button
                type="button"
                className="btn"
                style={{ textTransform: "none" }}
                onClick={() => {
                  if (dropdownOpen == req.pm_id) {
                    setDropdownOpen();
                  } else {
                    setDropdownOpen(req.pm_id);
                  }
                }}
              >
                <i class="fa-solid fa-bars"></i>
              </button>
              {dropdownOpen && dropdownOpen == req.pm_id && (
                <div
                  className="dropdown-menu "
                  style={{
                    display: "block",
                    zIndex: "10",
                  }}
                  ref={ref}
                >
                  <div
                    onClick={() => handleEdit(req)}
                    className="dropdown-item"
                  >
                    Edit
                  </div>
                  {}
                  {req.files.length > 0 && (
                    <div
                      onClick={() => handleView(req)}
                      className="dropdown-item"
                    >
                      View
                    </div>
                  )}
                  <div
                    onClick={() => {
                      setArgs(req);
                      setPopupTitle("Delete");
                      setPopupText("Are you sure do you want to delete ?");
                      setPopupTrigger(true);
                      setDropdownOpen();
                    }}
                    className="dropdown-item"
                  >
                    Delete
                  </div>
                </div>
              )}
            </div>
          );
        },
        disableFilters: true,
      },
    ],
    []
  );
  const data = useMemo(() => tableData, [tableData]);
  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useFilters,
    usePagination
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    nextPage,
    gotoPage,
    previousPage,
    pageOptions,
    pageCount,
    canNextPage,
    canPreviousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = tableInstance;
  return (
    <div style={{ height: "100vh" }}>
      <Helmet>
        <title>VidaVault</title>
      </Helmet>
      <Navbar />
      <Confirmation
        popupText={popupText}
        popupTrigger={popupTrigger}
        setPopupTrigger={setPopupTrigger}
        confirmed={confirmed}
        popupTitle={popupTitle}
        canceled={canceled}
      />
      <div style={{ height: "85%" }} className="page-content">
        <Sidebar />
        <div className="content-wrapper">
          <div className="content-inner">
            {/* <Subnav /> */}
            {/* <div className="navbar navbar-expand-lg navbar-light sticky-top">
              <div className="text-center d-lg-none w-100">
                <button
                  type="button"
                  className="navbar-toggler dropdown-toggle"
                  data-toggle="collapse"
                  data-target="#navbar-second"
                >
                  <i className="icon-unfold mr-2"></i>
                  Open Task Menu
                </button>
              </div>
            </div> */}
            <Subnav />

            <div className="content">
              <div className="card" style={{ height: "100%" }}>
                <div className="mt-4">
                  <h3 className="text-center font-weight-bold">
                    Slideshow Library
                  </h3>
                </div>
                <div
                  className="content d-flex justify-content-center align-items-center overflow-auto "
                  style={{ height: "100%" }}
                >
                  <form className="flex-fill" style={{ height: "100%" }}>
                    <div className="row" style={{ height: "100%" }}>
                      <div className="col-lg-12">
                        <div className="card-body"></div>
                        <div class="table">
                          <table
                            {...getTableProps()}
                            className="table custom-left-padding"
                            style={{
                              height: "100%",
                              padding: "30px",
                              // paddingLeft: "250px",
                            }}
                          >
                            <thead>
                              {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                  {headerGroup.headers.map((column) => (
                                    <th {...column.getHeaderProps()}>
                                      {column.render("Header")}
                                      <div>
                                        {column.canFilter
                                          ? column.render("Filter")
                                          : null}
                                      </div>
                                    </th>
                                  ))}
                                </tr>
                              ))}
                            </thead>
                            {page?.length > 0 ? (
                              <tbody {...getTableBodyProps()}>
                                {page.map((row) => {
                                  prepareRow(row);
                                  return (
                                    <tr {...row.getRowProps()}>
                                      {row.cells.map((cell) => {
                                        return (
                                          <td {...cell.getCellProps()}>
                                            {cell.render("Cell")}
                                          </td>
                                        );
                                      })}
                                    </tr>
                                  );
                                })}
                              </tbody>
                            ) : (
                              <tbody>
                                <tr>
                                  <td
                                    colSpan={
                                      headerGroups[0]?.headers.length || 1
                                    }
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "80px",
                                      }}
                                    >
                                      <h1
                                        style={{
                                          color: "#dddddd",
                                          fontSize: "35px",
                                        }}
                                      >
                                        No record yet!
                                      </h1>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            )}
                          </table>
                          <div
                            className="pagination custom-left-padding"
                            style={{
                              padding: "0.5rem",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <button
                              onClick={() => gotoPage(0)}
                              disabled={!canPreviousPage}
                            >
                              {"<<"}
                            </button>
                            <button
                              style={{ margin: "0.5rem" }}
                              onClick={() => previousPage()}
                              disabled={!canPreviousPage}
                            >
                              {"<"}
                            </button>
                            <button
                              style={{ margin: "0.5rem" }}
                              onClick={() => nextPage()}
                              disabled={!canNextPage}
                            >
                              {">"}
                            </button>
                            <button
                              style={{ margin: "0.5rem" }}
                              onClick={() => gotoPage(pageCount - 1)}
                              disabled={!canNextPage}
                            >
                              {">>"}
                            </button>
                            <span style={{ margin: "0.5rem" }}>
                              Page
                              <strong>
                                {pageIndex + 1} of {pageOptions.length}
                              </strong>
                            </span>
                            |
                            <span style={{ margin: "0.5rem" }}>
                              Go to page:
                              <input
                                type="number"
                                defaultValue={pageIndex + 1}
                                onChange={(e) => {
                                  const page = e.target.value
                                    ? Number(e.target.value) - 1
                                    : 0;
                                  gotoPage(page);
                                }}
                                style={{ width: "100px", margin: "0.5rem" }}
                              />
                            </span>
                            <select
                              style={{ margin: "0.5rem" }}
                              value={pageSize}
                              onChange={(e) => {
                                setPageSize(Number(e.target.value));
                              }}
                            >
                              {[10, 20, 30, 40, 50].map((pageSize) => (
                                <option key={pageSize} value={pageSize}>
                                  Show {pageSize}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="navbar navbar-expand-lg navbar-light">
              <div className="text-center d-lg-none w-100">
                <button
                  type="button"
                  className="navbar-toggler dropdown-toggle"
                  data-toggle="collapse"
                  data-target="#navbar-footer"
                >
                  <i className="icon-unfold mr-2"></i>
                  Open Footer
                </button>
              </div>

              <div className="navbar-collapse collapse" id="navbar-footer">
                <span className="navbar-text">
                  <span>&copy; 2023 VidaVault</span>. All rights reserved.
                </span>

                <ul className="navbar-nav ml-lg-auto">
                  <li className="nav-item">
                    <NavLink to="/fha/support" className="navbar-nav-link">
                      <i className="fa-solid fa-life-ring mr-2"></i> Support
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlideshowLibrary;
