import axios from "axios";
import React, { useContext, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { NavLink, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import "../../assets/css/login.css";
import { baseUrl } from "../../config";
import Toast from "react-toast-component";
import { UserContext } from "../../UserProvider";
import AdminNavbar from "../../components/SaNavbar";
const AdminLogin = () => {
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [remember, setRemember] = useState();

  const [isOpen, setToast] = useState(false);
  const { adminLogin, logAdminIn } = useContext(UserContext);
  const navigate = useNavigate();
  useEffect(() => {
    let user = Cookies.get("adminCredentialsData");
    console.log(user);
    if (user) {
      setUsername(JSON.parse(user).username);
      setPassword(JSON.parse(user).pwd);
    }
  }, []);
  useEffect(() => {
    if (adminLogin) {
      navigate("/sa/pending");
    }
  }, [navigate, adminLogin]);
  const [err, setErr] = useState();
  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordToggle = () => {
    setShowPassword(!showPassword);
  };
  const handleLogin = (e) => {
    e.preventDefault();
    if (username == "admin" && password == "admin@123") {
      setErr();
      if (remember) {
        Cookies.set(
          "adminCredentialsData",
          JSON.stringify({
            username: username,
            pwd: password,
          })
        );
      }
      Cookies.set("adminData", JSON.stringify(true));

      logAdminIn(true);
    } else {
      setErr("Invalid Credentials");
      setToast(true);
    }
    // axios
    //   .post(`${baseUrl}/login`, {
    //     fha_username: username,
    //     fha_pwd: password,
    //   })
    //   .then((res) => {
    //     setErr();
    //     // if ((res.data.resultCode == 1)) {
    //     //   console.log(res.data)

    //     // }
    //     console.log(res)
    //     if(res.data.resultCode == 0){
    //       setErr(res.data.resultMsg)
    //       setToast(true)
    //     }
    //     else{
    //       if (remember) {
    //         console.log(res.data)
    //         Cookies.set("userData", JSON.stringify(res.data));
    //       }
    //       logUserIn(res.data);
    //       navigate("/fha/createslideshow");
    //     }
    //   })
    //   .catch((err) => {
    //     setErr("Invalid Credentials");
    //     setToast(true);
    //   });
  };
  return (
    <div style={{ height: "100vh" }}>
      <Helmet>
        <title>VidaVault - Login</title>
      </Helmet>
      <Toast
        isOpen={isOpen}
        closeCallback={() => setToast(false)}
        className="toast-container"
        hasAutoDismiss={false}
        // duration={8000}
      >
        <div className="alert alert-warning bg-white alert-styled-left alert-arrow-left alert-dismissible">
          <button
            onClick={() => setToast(false)}
            type="button"
            className="close"
            data-dismiss="alert"
          >
            <span>&times;</span>
          </button>
          <h6 className="alert-heading font-weight-semibold mb-1">{err}</h6>
        </div>
      </Toast>
      <AdminNavbar />
      <div style={{ height: "85%" }} className="page-content">
        <div className="content-wrapper">
          <div className="content-inner">
            <div className="content d-flex justify-content-center align-items-center">
              <form className="login-form" onSubmit={handleLogin}>
                <div className="card-body">
                  <div className="text-center mb-3">
                    <i className="fa-solid fa-right-to-bracket text-indigo large-icon mb-3 mt-1"></i>
                    <h5 className="mb-0">Login to Your Account</h5>
                    <span className="d-block text-muted">
                      Please enter your credentials
                    </span>
                  </div>

                  <div className="form-group form-group-floating row">
                    <div className="col-lg-12">
                      <div className="position-relative">
                        <input
                          type="text"
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                          className="form-control form-control-outline"
                          placeholder="Placeholder"
                          required
                        />
                        <label className="label-floating">Username</label>
                      </div>
                    </div>
                  </div>

                  <div className="form-group form-group-floating row">
                    <div className="col-lg-12">
                      <div className="position-relative">
                        <input
                          type={showPassword ? "text" : "password"}
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          className="form-control form-control-outline"
                          placeholder="Placeholder"
                          required
                        />
                        <label className="label-floating">Password</label>
                        {showPassword ? (
                          <button
                            type="button"
                            onClick={handlePasswordToggle}
                            className={`password-toggle-button ${
                              showPassword ? "show" : ""
                            }`}
                          >
                            <i className="fa fa-eye-slash"></i>
                          </button>
                        ) : (
                          <button
                            type="button"
                            onClick={handlePasswordToggle}
                            className={`password-toggle-button ${
                              showPassword ? "show" : ""
                            }`}
                          >
                            <i className="fa fa-eye"></i>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="form-group d-flex align-items-center">
                    <label className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        name="remember"
                        className="custom-control-input"
                        onClick={() => setRemember(!remember)}
                        checked={remember}
                      />
                      <span className="custom-control-label">Remember</span>
                    </label>

                    {/* <NavLink to="/fha/recover" className="ml-auto">
                      Forgot password?
                    </NavLink> */}
                  </div>
                  {/* {err && (
                    <div className="alert alert-danger bg-white alert-styled-left alert-arrow-left alert-dismissible">
                      <button
                        type="button"
                        className="close"
                        data-dismiss="alert"
                      >
                        <span>&times;</span>
                      </button>
                      <h6 className="alert-heading font-weight-semibold mb-1">
                        {err}
                      </h6>
                    </div>
                  )} */}
                  <div className="form-group">
                    <button type="submit" className="btn btn-teal btn-block">
                      Sign in
                    </button>
                  </div>

                  {/* <div className="form-group text-center text-muted content-divider">
                    <span className="px-2">Don't have an account?</span>
                  </div>

                  <NavLink to="/fha/register" className="form-group">
                    <a href="#" className="btn btn-dark btn-block">
                      Sign up
                    </a>
                  </NavLink>

                  <span className="form-text text-center text-muted">
                    By continuing, you're confirming that you've read our{" "}
                    <a href="#">Terms &amp; Conditions</a> and{" "}
                    <a href="#">Cookie Policy</a>
                  </span> */}
                </div>
              </form>
            </div>
            {/* <Footer /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
