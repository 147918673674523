/** @format */

import React, { useContext, useEffect } from "react";
import "../../assets/css/login.css";
import logo from "../../assets/vidavault-logo-seal.png";
import "../../assets/css/landing_page.css";
import "../../assets/css/animate.min.css";
import "../../assets/css/owl.carousel.min.css";
import { useState } from "react";
import { NavLink, Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../UserProvider";
import { baseUrl } from "../../config";
import bg_1 from "../../assets/demo/services-bg-1.jpg";
import bg_2 from "../../assets/demo/services-bg-2.jpg";
import bg_3 from "../../assets/demo/services-bg-3.jpg";
import bg_4 from "../../assets/demo/services-bg-4.jpg";
import section_1 from "../../assets/demo/section-11.jpg";
import section_2 from "../../assets/demo/section-12.jpg";
import section_3 from "../../assets/demo/section-13.jpg";
import section_4 from "../../assets/demo/section-4.jpg";
import Cookies from "js-cookie";
import { useRef } from "react";
import Toast from "react-toast-component";
import axios from "axios";
import TextRotator from "../../components/text-rotator/TextRotator";
import ParallaxScroll from "../../components/parallax/Parallax";
import LeftParallax from "../../components/parallax/LeftParallax";
import BackToTopButton from "../../components/bottom-to-top/BackToTopButton";
import PriceModal from "../../components/PriceModal";
import SplitAnimation from "../../components/spilt-animation/SplitAnimation";
import "../../components/spilt-animation/splitAnimation.css";
const modalData = [
  {
    img: section_1,
    title: "Bringing Memories to Life Beyond Boundaries",
    desc: `<p class="parastyle">
    At VidaVault, we believe that memories should not be confined
    to the limitations of time and space. That's why we've created
    a revolutionary way to extend the reach of cherished life
    stories beyond the digital realm. Our innovative approach
    takes remembrance to physical spaces and memorial objects,
    ensuring that the stories, experiences, and legacies of loved
    ones live on in a tangible and meaningful manner.
  </p>
  <h3 class="custom-h3">A Connection Beyond Time</h3>
  <p class="parastyle">
    Imagine walking through a serene cemetery, where each
    headstone holds more than just a name and dates. With our QR
    code technology, these solemn memorials transform into
    gateways to a treasure trove of memories. Passersby can simply
    scan the QR codes engraved on plaques or memorials, instantly
    unlocking a slideshow that paints a vivid picture of the
    person's life. It's an opportunity to forge a connection
    beyond time, to glimpse into the unique journey that each
    individual embarked upon.
  </p>
  <h3 class="custom-h3">Stories That Transcend Space</h3>
  <p class="parastyle">
    Our QR codes don't just stay within the confines of
    cemeteries; they transcend space, becoming a bridge between
    the physical and the digital. Placed on keepsake jewelry or
    shared through social media, these QR codes allow family
    members and friends to carry the essence of their loved ones
    wherever they go. It's a way to make them a part of everyday
    life, fostering an ongoing connection that transcends
    geographical boundaries.
  </p>
  <h3 class="custom-h3">Honoring Diversity and Legacy</h3>
  <p class="parastyle">
    Every life is a tapestry woven with stories, experiences, and
    moments. Our QR codes honor this diversity by providing a
    platform for a multi-dimensional remembrance. Whether it's a
    person's achievements, passions, travels, or even their
    quirks, the slideshow associated with the QR code captures the
    essence of their legacy. It's a way for generations to come to
    understand and appreciate the lives that came before,
    fostering a sense of continuity and belonging.
  </p>
  <h3 class="custom-h3">Preserving History, Inspiring Future</h3>
  <p class="parastyle">
    As time goes on, history becomes richer with each life that
    contributes to its tapestry. By enabling QR codes to hold and
    share the memories of those who've passed, we're creating an
    ever-growing narrative that continues to inspire and
    enlighten. Whether in a bustling city square, a quiet
    cemetery, or a family home, our QR codes transform ordinary
    spaces into windows to the past, ensuring that the stories of
    our ancestors continue to shape the future.
  </p>
  <p class="parastyle">
    Join us in embracing this remarkable journey of remembrance,
    where technology and sentiment intertwine to create a bridge
    between generations and worlds. With VidaVault, memories truly
    know no bounds – they become an eternal beacon of light,
    guiding us through the ages.
  </p>`,
  },
  {
    img: section_2,
    title: "Unveiling Memories: Sharing Through Every Facet of Life",
    desc: `<p class="parastyle">
    At VidaVault, we understand that memories are meant to be shared,
    cherished, and passed down through generations. That's why we've
    harnessed the power of QR codes to create a seamless and
    heartwarming experience of sharing the rich tapestry of memories
    associated with loved ones. Our innovative approach allows these
    memories to be engraved on physical objects, shared on digital
    platforms, and woven into the fabric of everyday life.
  </p>
  <h3 class="custom-h3">Engraving Memories on Tangible Moments</h3>
  <p class="parastyle">
    Imagine walking through a tranquil garden, where each plaque and
    memorial holds a deeper story. With VidaVault, these physical
    objects become vessels of memories, as QR codes are delicately
    engraved upon them. These unobtrusive codes hold within them a
    slideshow that captures the essence of a person's life journey.
    Engraved on plaques and memorials, they transform ordinary moments
    into gateways to a world of memories, inviting anyone who scans
    them to delve into a life well-lived.
  </p>
  <h3 class="custom-h3">A Personal Keepsake of Connection</h3>
  <p class="parastyle">
    Carrying the memory of a loved one is an intimate experience. With
    VidaVault, memories can take a tangible form through keepsake
    jewelry. Our QR codes can be intricately incorporated into these
    pieces, creating a personal and touching way to stay connected.
    Whether worn close to the heart or displayed with pride, these
    jewelry items become more than adornments – they become a constant
    reminder of the love and moments that define a life.
  </p>
  <h3 class="custom-h3">Sharing Across Digital Horizons</h3>
  <p class="parastyle">
    In a world where distances can separate loved ones, VidaVault
    brings people closer than ever. Our QR codes can be effortlessly
    shared through social media platforms, transcending geographical
    boundaries and time zones. Family members and friends can simply
    share a QR code online, enabling anyone with a smartphone to
    instantly access the memories that matter most. It's a modern way
    to unite friends and relatives, ensuring that the stories of your
    loved ones resonate far and wide.
  </p>
  <h3 class="custom-h3">A Symphony of Connections</h3>
  <p class="parastyle">
    VidaVault isn't just about sharing memories; it's about weaving a
    symphony of connections that span lifetimes. By embedding memories
    within physical objects, jewelry, and digital codes, we're
    creating an intricate network of shared experiences. It's a
    network that bridges generations, cultures, and time itself,
    fostering a sense of unity and belonging that grows stronger with
    each passing day.
  </p>
  <h3 class="custom-h3">How to Get Started</h3>
  <p class="parastyle">
    Embrace the beauty of sharing memories like never before. Whether
    you choose to engrave a memorial, craft personalized keepsake
    jewelry, or share memories through social media, VidaVault makes
    it effortless. Our platform is designed to honor the past while
    embracing the future, ensuring that the legacy of your loved ones
    remains alive and vibrant for years to come. Join us in
    celebrating lives, connecting hearts, and keeping memories alive –
    the VidaVault way.
  </p>`,
  },
  {
    img: section_3,
    title: "Nurturing Roots: Creating Lasting Connections with Family Trees",
    desc: `<p class="parastyle">
    At VidaVault, we understand the profound importance of family,
    connections, and the stories that bind generations together.
    That's why we've taken our mission beyond preserving individual
    memories and extended it to the very fabric of family history.
    With our innovative platform, we not only honor the past but also
    build bridges to the future by facilitating the creation of
    intricate family trees that foster a sense of continuity and
    belonging.
  </p >
  <h3 class="custom-h3">Weaving Threads of Ancestry</h3>
  <p class="parastyle">
    Imagine embarking on a journey through time, where the stories of
    your ancestors come to life in an interconnected tapestry.
    VidaVault empowers you to create family trees that capture the
    essence of your lineage. Our platform allows you to easily add
    each family member, enriching their profile with videos, photos,
    and memories. It's a captivating way to preserve the legacy of
    those who came before and ensure that their stories are passed
    down with reverence.
  </p>
  <h3 class="custom-h3">Connecting Generations, Bridging Gaps</h3>
  <p class="parastyle">
    In a world where rapid change is the norm, VidaVault offers a
    timeless bridge between generations. As you build a family tree,
    you're not just compiling names and dates; you're building a
    bridge. Younger family members can explore their roots and develop
    a deeper understanding of their heritage, while elders can impart
    their stories to ensure they are not forgotten.
  </p>
  <h3 class="custom-h3">Fostering a Sense of Belonging</h3>
  <p class="parastyle">
    Family trees on VidaVault serve as more than just visual
    representations; they are a testament to the interconnectedness of
    families. Each branch represents an individual life, and
    collectively they tell a story of shared experiences, values, and
    aspirations. By fostering a sense of belonging and kinship,
    VidaVault brings families closer, even when miles apart.
  </p>
  <h3 class="custom-h3">Getting Started</h3>
  <p class="parastyle">
    Creating a family tree with VidaVault is as easy as it is
    rewarding. Our user-friendly platform guides you through the
    process of adding family members, connecting relationships, and
    weaving together the story of lineage. Join us in the endeavor of
    bridging generations, preserving legacies, and nurturing
    connections. With VidaVault, a family's story will continue to
    unfold across time, creating an unbroken thread in the grand
    tapestry of life.
  </p>`,
  },
  {
    img: section_4,
    title:
      "Preserving Legacies, Embracing Stories: Your Journey with VidaVault",
    desc: `<p class="parastyle">
    Memories are the threads that weave the fabric of our lives. With
    VidaVault, you have the power to capture these threads and
    transform them into an enduring legacy. The stories of loved ones,
    the triumphs and challenges they faced, the milestones they
    achieved – all of these elements come together to create a legacy
    that resonates through the ages. By joining us, you're becoming a
    guardian of timelessness, ensuring that the essence of those you
    cherish remains vibrant for generations to come.
  </p>
  <h3 class="custom-h3">Embracing the Stories That Define Us</h3>
  <p class="parastyle">
    Behind every person lies a tapestry of experiences that make them
    unique. At VidaVault, we celebrate these stories. We empower you
    to curate and share not just the highlights, but the journey
    itself. From cherished family anecdotes to personal
    accomplishments, from shared adventures to the quiet moments that
    define a life, every story has its place. By embracing these
    stories, you're allowing the depth of human experience to flourish
    and inspire, connecting generations in ways words alone cannot.
  </p>
  <h3 class="custom-h3">Fostering Connections That Span Time</h3>
  <p class="parastyle">
    Our lives are woven together by relationships, and VidaVault
    ensures that these connections are never lost. Through the
    creation of family trees and the sharing of memories, you're
    fostering bonds that transcend generations. It's a remarkable way
    to honor the past while building bridges to the future. By
    fostering these connections, you're not just enriching a family's
    legacy – you're creating a sense of belonging that resonates with
    every member of the lineage.
  </p>
  <h3 class="custom-h3">Join Us on this Meaningful Journey</h3>
  <p class="parastyle">
    Your involvement with VidaVault is more than a commitment; it's an
    opportunity to make a lasting impact. By signing up today, you're
    taking the first step toward building a bridge between the past,
    present, and future. You're stepping into a realm where technology
    and sentiment converge to create something truly timeless. Loved
    ones' stories deserve to be told, shared, and cherished – and with
    VidaVault, you're ensuring they live on.
  </p>
  <h3 class="custom-h3">Become a Part of the VidaVault Legacy</h3>
  <p class="parastyle">
    Preserve, celebrate, and connect with VidaVault. Join us in
    creating a legacy that stands the test of time. Every story
    matters – let's embrace them together.
  </p>
  `,
  },
];

const LandingPage = () => {
  const [selectedFace, setSelectedFace] = useState("front");

  const handleFaceChange = (event) => {
    setSelectedFace(event.target.value);
  };
  const { loggedIn, logUserIn, setIsLoading } = useContext(UserContext);
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [remember, setRemember] = useState();
  const [showModal, setShowModal] = useState(false);
  const [tab, setTab] = useState(1);
  const [isOpen, setToast] = useState(false);
  const [err, setErr] = useState();
  const loginFormRef = useRef(null);
  const [updateModalData, setUpdateModalData] = useState(modalData[0]);
  const [tabDetails, setTabDetails] = useState({
    title: "Introduction",
    desc: `Welcome to VidaVault, the platform that brings
  memories to life in ways you've never imagined.`,
    img: bg_1,
  });
  const [openPriceModal, setOpenPriceModal] = useState(false);

  useEffect(() => {
    document.querySelector("#radio-front").checked = true;
  }, []);

  const [isOpens, setIsOpen] = useState(false);
  const carouselControlsRef = useRef();
  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    const element = document.getElementById("bouncemodel");

    // Check if the element exists
    if (element) {
      // Remove the "old-class" if it exists
      if (element.classList.contains("bounceInUp")) {
        element.classList.remove("bounceInUp");
      }

      // Add the "new-class"
      element.classList.add("bounceOutDown");
    }
    setTimeout(() => setIsOpen(false), 1000);
  };

  useEffect(() => {
    let i = 0;
    if (window.location.pathname === "/" && i == 0) {
      setTimeout(() => {
        // window.location.reload();
      }, 1000);
      i++;
    }
  }, []); // Empty dependency array ensures it runs only once on mount

  const handleOpenModal = () => {
    console.log("handleOpenModal");
    setShowModal(true);
  };
  const handleCloseModal = () => setShowModal(false);

  const navigate = useNavigate();

  const handlePasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    switch (tab) {
      case 2:
        setTabDetails({
          title: "Curate",
          desc: `Curate memories into captivating slideshows. Simply
        upload photos and videos to a profile and arrange them
        to tell a beautiful story.`,
          img: bg_2,
        });
        break;
      case 3:
        setTabDetails({
          title: "QR Codes",
          desc: `Next, experience the magic of our QR codes. Engrave
          them on plaques, jewelry, or share them on social
          media. Each code is a doorway to a world of memories.`,
          img: bg_3,
        });
        break;

      case 4:
        setTabDetails({
          title: "Family",
          desc: `And that's not all – our family tree feature fosters
          connections across generations. Build a comprehensive
          family tree, complete with videos, photos, and
          relationships.`,
          img: bg_4,
        });
        break;
      default:
        setTabDetails({
          title: "Introduction",
          desc: `Welcome to VidaVault, the platform that brings
          memories to life in ways you've never imagined.`,
          img: bg_1,
        });
    }
  }, [tab]);

  useEffect(() => {
    let user = Cookies.get("userCredentialsData");
    if (user) {
      setUsername(JSON.parse(user).username);
      setPassword(JSON.parse(user).pwd);
    }
  }, []);
  useEffect(() => {
    if (loggedIn) {
      navigate("/fha/createslideshow");
    }
  }, [navigate, loggedIn]);

  const handleLogin = (e) => {
    console.log("Event ==> ", e);
    e.preventDefault();
    if (loginFormRef.current.checkValidity()) {
      setIsLoading(true);
      axios
        .post(
          `${baseUrl}/login`,
          {
            fha_username: username,
            fha_pwd: password,
          },
          {
            headers: {
              Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c`,
            },
          }
        )
        .then((res) => {
          console.log("Response ==> ", res);
          setErr();
          setIsLoading(false);
          if (res.data.resultCode == 1) {
            Cookies.set("userData", JSON.stringify(res.data));
            if (remember) {
              Cookies.set(
                "userCredentialsData",
                JSON.stringify({
                  username: username,
                  pwd: password,
                })
              );
            }
            logUserIn(res.data);
            window.location.replace("/fha/createslideshow");
            // navigate("/fha/createslideshow");
          }
          if (
            res.data.resultMsg ===
            "Hi User, Your account is inactive. Please ask SA to activate back."
          ) {
            setErr(
              "The Super Admin has deactivated your account. Contact super admin for details."
            );
            setToast(true);
          } else if (res.data.resultCode == 0) {
            setErr(res.data.resultMsg);
            setToast(true);
          } else {
            setErr("Login Failed");
            setToast(true);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          setErr("Invalid Credentials");
          setToast(true);
        });
    } else {
      loginFormRef.current.reportValidity();
    }
  };
  // first radio button should checked comp mounts
  useEffect(() => {
    document.getElementById("radio-front").checked = true;
  }, []);

  useEffect(() => {
    console.log("window.innerWidth ==> ", window.innerWidth);
    const openAccordion1Link = document.getElementById("open-accordion1");
    if (window.innerWidth <= 899 && openAccordion1Link) {
      openAccordion1Link.click();
    }

    return openAccordion1Link.removeEventListener("click", () => {});
  }, []);

  function refreshPage() {
    window.location.reload(false);
  }

  // Animation start

  function AnimateTop() {
    console.log("AnimateTop==>");
    if (carouselControlsRef) {
      var target = document.querySelector(".carouseling");
      var targetOffsetTop = target.offsetTop;
      console.log("targetOffsetTop==>", targetOffsetTop);
      document.body.scrollTo({
        top: targetOffsetTop,
        behavior: "smooth",
      });
    }
  }

  let activeIndex = 0;
  let limit = 0;
  let disabled = false;
  let stage;
  let controls;
  let canvas = false;

  const SPIN_FORWARD_CLASS = "js-spin-fwd";
  const SPIN_BACKWARD_CLASS = "js-spin-bwd";
  const DISABLE_TRANSITIONS_CLASS = "js-transitions-disabled";
  const SPIN_DUR = 1000;

  const appendControls = () => {
    console.log("LIMIT ==> ", limit);
    for (let i = 0; i < limit; i++) {
      const control = document.createElement("a");
      control.href = "#";
      control.setAttribute("data-index", i);
      controls.appendChild(control);
    }
    const height = controls.lastElementChild.offsetHeight;
    controls.style.height = 30 + limit * height + "px";

    controls.children[0].classList.add("active");
  };

  const setIndexes = () => {
    const spinners = document.querySelectorAll(".spinners > *");
    spinners.forEach((el, i) => {
      el.setAttribute("data-index", i);
      limit++;
    });
  };

  const duplicateSpinner = () => {
    const el = document.querySelector(".spinners").parentNode;
    const html = el.innerHTML;
    el.innerHTML += html;
    const spinners = document.querySelectorAll(".spinners");
    spinners[spinners.length - 1].classList.add("spinner--right");
    spinners[spinners.length - 1].classList.remove("spinner--left");
  };

  const paintFaces = () => {
    const spinnerFaces = document.querySelectorAll(".spinner__face");
    // spinnerFaces.forEach((el) => {
    //   const color = el.getAttribute("data-bg");
    //   el.firstElementChild.style.backgroundImage = `url(${getBase64PixelByColor(
    //     color
    //   )})`;
    // });
  };

  const getBase64PixelByColor = (hex) => {
    if (!canvas) {
      canvas = document.createElement("canvas");
      canvas.height = 1;
      canvas.width = 1;
    }
    if (canvas.getContext) {
      const ctx = canvas.getContext("2d");
      ctx.fillStyle = hex;
      ctx.fillRect(0, 0, 1, 1);
      return canvas.toDataURL();
    }
    return false;
  };

  const prepareDom = () => {
    setIndexes();
    paintFaces();
    duplicateSpinner();
    appendControls();
  };

  const spin = (inc = 1) => {
    if (disabled) return;
    if (!inc) return;
    activeIndex += inc;
    disabled = true;

    if (activeIndex >= limit) {
      activeIndex = 0;
    }

    if (activeIndex < 0) {
      activeIndex = limit - 1;
    }

    const activeEls = document.querySelectorAll(".spinner__face.js-active");
    const nextEls = document.querySelectorAll(
      `.spinner__face[data-index="${activeIndex}"]`
    );
    nextEls.forEach((el) => el.classList.add("js-next"));

    if (inc > 0) {
      AnimateTop();
      stage.classList.add(SPIN_FORWARD_CLASS);
    } else {
      AnimateTop();
      stage.classList.add(SPIN_BACKWARD_CLASS);
    }

    controls.querySelectorAll(".active").forEach((el) => {
      // console.log("Is active",el);
      return el.classList.remove("active");
    });
    controls.children[activeIndex].classList.add("active");

    setTimeout(
      () => {
        spinCallback(inc);
      },
      SPIN_DUR,
      inc
    );
  };

  const spinCallback = (inc) => {
    document
      .querySelectorAll(".js-active")
      .forEach((el) => el.classList.remove("js-active"));
    document
      .querySelectorAll(".js-next")
      .forEach((el) => el.classList.replace("js-next", "js-active"));
    // .forEach((el) => el.classList.remove("js-next")).add('js-active');
    stage.classList.add(DISABLE_TRANSITIONS_CLASS);
    stage.classList.remove(SPIN_FORWARD_CLASS);
    stage.classList.remove(SPIN_BACKWARD_CLASS);

    document.querySelectorAll(".js-active").forEach((el) => {
      el.parentNode.insertBefore(el, el.parentNode.firstChild);
    });

    setTimeout(() => {
      stage.classList.remove(DISABLE_TRANSITIONS_CLASS);
      disabled = false;
    }, 100);
  };

  const attachListeners = () => {
    document.addEventListener("keyup", (e) => {
      // console.log("attachListeners --> ", attachListeners);
      console.log("Key code --> ", e.keyCode);
      switch (e.keyCode) {
        case 38:
          spin(-1);
          break;
        case 40:
          spin(1);
          break;
      }
    });

    controls.addEventListener("click", (e) => {
      console.log("Event", e.target);
      e.preventDefault();
      if (disabled) return;
      const target = e.target;
      console.log("Event", target.tagName);
      if (target.tagName === "A") {
        const toIndex = parseInt(target.getAttribute("data-index"), 10);
        // console.log("ToIndex ==> ",toIndex," activeIndex ==> ",activeIndex);
        spin(toIndex - activeIndex);
      }
    });
  };

  const assignEls = () => {
    stage = document.querySelector(".carousel__stage");
    controls = document.querySelector(".carousel__controls");
  };

  const init = () => {
    assignEls();
    prepareDom();
    attachListeners();
  };

  useEffect(() => {
    init();
  }, []);

  //  Animation end

  // Opemn

  const handleParagraphClick = (e) => {
    const clickedElement = e.target;

    // Check if the clicked element is a paragraph with the class 'blog-contain'
    if (clickedElement.classList.contains("blog-contain")) {
      // Retrieve the data-paragraph attribute to identify the clicked paragraph
      const paragraphId = clickedElement.getAttribute("data-paragraph");

      // Perform actions based on the clicked paragraph
      console.log(`Clicked on paragraph: ${paragraphId}`);

      // Additional logic based on the clicked paragraph
      if (paragraphId === "paragraph1") {
        setUpdateModalData(modalData[0]);
      } else if (paragraphId === "paragraph2") {
        setUpdateModalData(modalData[1]);
      } else if (paragraphId === "paragraph3") {
        setUpdateModalData(modalData[2]);
      } else if (paragraphId === "paragraph4") {
        setUpdateModalData(modalData[3]);
      }
      openModal();
    }
  };

  return (
    <>
      <BackToTopButton />
      {openPriceModal && <PriceModal setOpenPriceModal={setOpenPriceModal} />}
      {isOpen && (
        <Toast
          isOpen={isOpen}
          closeCallback={() => {
            setToast(false);
            setErr(false);
          }}
          className="toast-container"
          hasAutoDismiss={false}
          // duration={8000}
        >
          <div className="alert alert-warning bg-white alert-styled-left alert-arrow-left alert-dismissible">
            <button
              onClick={() => {
                setToast(false);
                setErr();
              }}
              type="button"
              className="close"
              data-dismiss="alert"
            >
              <span>&times;</span>
            </button>
            <h6 className="alert-heading font-weight-semibold mb-1">{err}</h6>
          </div>
        </Toast>
      )}

      {showModal && (
        <div className="modalled">
          <div className="modalled-popup-content">
            <span className="close" onClick={handleCloseModal}>
              &times;
            </span>
            {/* <h2>Login</h2> */}
            {/* Add your login form or content here */}
            <form
              ref={loginFormRef}
              className="login-form m-auto"
              onSubmit={handleLogin}
            >
              <div className="card-body d-flex justify-content-center pb-0">
                <div>
                  <div className="text-center mb-3 login-modal-title">
                    <i className="fa-solid fa-right-to-bracket text-white medium-icon mb-1 mt-1"></i>
                    <h5 className="mb-0 text-white">Login to Your Account</h5>
                    <span className="d-block text-muted">
                      Please enter your credentials
                    </span>
                  </div>

                  <div className="form-group form-group-floating row">
                    <div className="col-lg-12">
                      <div className="position-relative username-div">
                        <input
                          type="text"
                          value={username}
                          minLength={4}
                          maxLength={25}
                          pattern="^(?!.*[_\.]{2})[a-zA-Z0-9]+([_.]?[a-zA-Z0-9]+)*$"
                          onChange={(e) => setUsername(e.target.value)}
                          className="form-control form-control-outline"
                          placeholder="Placeholder"
                          required
                          autoComplete="off"
                        />
                        <label className="label-floating modal-form-label">
                          Username
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="form-group form-group-floating row">
                    <div className="col-lg-12">
                      <div className="position-relative password-div">
                        <input
                          type={showPassword ? "text" : "password"}
                          value={password}
                          minLength={6}
                          maxLength={30}
                          // pattern="^(?=.*\d)(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,}$"
                          // title="Password should be combination of numbers, special character, alphabets"
                          onChange={(e) => setPassword(e.target.value)}
                          className="form-control form-control-outline"
                          placeholder="Placeholder"
                          required
                          autoComplete="off"
                        />
                        <label className="label-floating modal-form-label">
                          Password
                        </label>
                        {showPassword ? (
                          <button
                            type="button"
                            onClick={handlePasswordToggle}
                            className={`password-toggle-button ${
                              showPassword ? "show" : ""
                            }`}
                          >
                            <i className="fa fa-eye-slash"></i>
                          </button>
                        ) : (
                          <button
                            type="button"
                            onClick={handlePasswordToggle}
                            className={`password-toggle-button ${
                              showPassword ? "show" : ""
                            }`}
                          >
                            <i className="fa fa-eye"></i>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="form-group d-flex align-items-center remember-div">
                    <label className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        name="remember"
                        className="custom-control-input"
                        onClick={() => setRemember(!remember)}
                        checked={remember}
                      />
                      <span className="custom-control-label text-white">
                        Remember
                      </span>
                    </label>

                    <NavLink to="/fha/recover" className="ml-auto forgot-text">
                      Forgot password?
                    </NavLink>
                  </div>
                  {/* {err && (
                    <div className="alert alert-danger bg-white alert-styled-left alert-arrow-left alert-dismissible">
                      <button
                        type="button"
                        className="close"
                        data-dismiss="alert"
                      >
                        <span>&times;</span>
                      </button>
                      <h6 className="alert-heading font-weight-semibold mb-1">
                        {err}
                      </h6>
                    </div>
                  )} */}
                  <div className="form-group login-modal-text">
                    <button type="submit" className="btn btn-teal btn-block">
                      Sign in
                    </button>
                  </div>

                  {/* <div className="form-group text-center text-muted content-divider"> */}
                  <div className="form-group text-center text-white login-modal-text">
                    <span className="px-2">Don't have an account?</span>
                  </div>

                  <NavLink to="/fha/register" className="form-group">
                    <a
                      href="#"
                      className="btn btn-dark btn-block w-100 modal-sign-up"
                    >
                      Sign up
                    </a>
                  </NavLink>

                  <span className="form-text text-center text-muted login-modal-footer">
                    By continuing, you're confirming that you've read our
                    <a href="/fha/termsandconditions" target="_blank">
                      {" "}
                      Terms &amp; Conditions
                    </a>{" "}
                    and
                    <a href="/fha/cookiespolicy" target="_blank">
                      {" "}
                      Cookie Policy
                    </a>
                  </span>
                </div>
              </div>
            </form>
            {/* <button onClick={handleCloseModal}>Close</button> */}
            {/* <button onClick={handleCloseModal}>Log In</button> */}
          </div>
        </div>
      )}

      {isOpens && (
        <div className="modals animated bounceInUp" id="bouncemodel">
          <div className="modal-contentss">
            {/* <span className='closes' onClick={closeModal}>
              &times;
            </span> */}
            <div
              class="btn-close-modal close-modal-blog-2"
              onClick={closeModal}
            >
              <i class="fa fa-window-close"></i>
            </div>
            <div>
              <div
                className="section-modal-img  bg-section-modal section-modal-overlays"
                style={{ backgroundImage: `url(${updateModalData.img}` }}
              >
                <div className="section-modal-title" style={{ zIndex: 1 }}>
                  <h2 className="text-white  ">{updateModalData.title}</h2>
                </div>
              </div>
            </div>
            <div className="content-details mb-5">
              <div
                className="mb-0 .custom-h3"
                dangerouslySetInnerHTML={{ __html: updateModalData.desc }}
              ></div>
              {updateModalData.title ===
                "Preserving Legacies, Embracing Stories: Your Journey with VidaVault" && (
                <>
                  <p>
                    <NavLink to="/fha/register" href="#" className="">
                      <a className="btn text-capitalize signup">Sign Up Now</a>
                    </NavLink>
                  </p>

                  <p className="parastyle">
                    Preserve, celebrate, and connect with VidaVault. Join us in
                    creating a legacy that stands the test of time. Every story
                    matters – let's embrace them together.
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      )}

      <div className="landing-page">
        <div
          className="header overlay-dark-header-70 overlay-header scrollable-container"
          id="scrollable-container"
        >
          {/* <!-- Parallax --> */}
          <ParallaxScroll />
          {/* <div
            className="parallax"
            data-speed="0.5"
            data-parallax='{"y":200, "smoothness": 20}}'
          ></div> */}
          <div
            className="headers p-4 pl-5 text-white"
            // style={{ backgroundColor: "#5259a1" }}
          >
            <div>
              <img
                src={logo}
                alt=""
                width="auto"
                height={80}
                id="business-logo"
                onClick={refreshPage}
              />
            </div>
            <ul className="d-flex mx-5">
              {/* <li
                  className="cursor-pointer text-font pr-0 font-weight-bold"
                  onClick={() => navigate("/fha/support")}
                >
                  Support Ticket
                </li> */}
              <li
                className="pl-4 cursor-pointer font-weight-bold text-font login-bttn login-text"
                onClick={handleOpenModal}
                // style={{ fontSize: "0.94rem" }}
              >
                <p>
                  <i class="fa-solid fa-right-to-bracket pr-1"></i> LOGIN
                </p>
              </li>
            </ul>
          </div>

          <TextRotator />

          {/* <!-- Navigation --> */}
          <nav className="navigation main-nav">
            <a id="resp-menu" className="responsive-menu" href="#">
              <i className="fa fa-reorder"></i>
            </a>

            <div className="menu-wrapper">
              <ul className="menu">
                <li>
                  <a href="#overview">Overview</a>
                </li>
                <li>
                  <a href="#features">Features</a>
                </li>
                <li>
                  <a href="#demo">Demo</a>
                </li>
                <li onClick={() => setOpenPriceModal(true)}>
                  <a href="#">Subscription Plans</a>
                </li>
                <li>
                  <NavLink to="fha/register" className="ml-auto forgot-text">
                    Get Started
                  </NavLink>
                  {/* <a href="#">Get Started</a> */}
                </li>
              </ul>
            </div>
          </nav>
          {/* <!-- .navigation -->		 */}
        </div>
        {/* Menu end */}
        {/* Features start */}
        <section className="features d-md-flex justify-content-between wrap">
          <div id="overview"></div>
          {/* <!-- Skills Parallax --> */}
          <div className="skills-image-wrapper overlay-dark-header-70 overlay-header">
            {/* <div className="skills-image" data-parallax='{"y":-200}'></div> */}
          </div>
          <div className="skills-wrapper">
            <div className="skills-heading-wrapper skills-heading-bg-colo overlay-dark-header-70 overlay-header">
              <div
                className="skills-heading d-hidden"
                // style={{ width: "40rem" }}
              >
                {/* <div className="skills-image" data-parallax='{"y":-200}'></div> */}
                <LeftParallax />
              </div>
            </div>
            <div className="skills-content">
              <div className="skills-text">
                <h3 className="font-weight-bold">Welcome to VidaVault</h3>
                <p className="" style={{ fontSize: "1rem" }}>
                  VidaVault is a unique online application that cherishes
                  memories and celebrates lives in a whole new way. We
                  understand the power of memories and their ability to
                  transcend time. Our innovative platform merges technology with
                  sentiment, offering a heartfelt solution for memorializing
                  loved ones. Through our service, funeral homes can now provide
                  a profound and lasting tribute – associating QR codes with
                  images and videos that capture the essence of a person's life.
                </p>

                <ul className="buttons">
                  <li className="mr-3">
                    <a href="#demo" className="bttn m-0">
                      See Demo
                    </a>
                  </li>
                  <li onClick={() => setOpenPriceModal(true)}>
                    <a href="#" className="bttn m-0">
                      Pricing
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <header id="features" className="section-header-wrapper">
          <div className="section-header">
            <h2>Innovative Features of VidaVault</h2>
          </div>
        </header>

        {/* <div className="split" style={{height:"100px",width:"100vw"}}> */}
        {/* <SplitAnimation
          openModal={openModal}
          setUpdateModalData={setUpdateModalData}
        /> */}

        {/* Animation Split */}
        <>
          <div className="carouseling">
            <div className="carousel__controls" ref={carouselControlsRef}></div>
            <div className="carousel__stage" onClick={handleParagraphClick}>
              <div className="spinners spinner--left">
                <div className="spinner__face js-active">
                  <div className="content-animation" data-type="iceland">
                    <div className="content__left"></div>
                    <div className="content__right">
                      <div className="content__main">
                        <p
                          className="text-white m-auto cursor-pointer blog-contain"
                          data-paragraph="paragraph1"
                          // onClick={() => {
                          //   setUpdateModalData(modalData[0]);
                          //   openModal();
                          // }}
                        >
                          Beyond the digital realm, our solution brings a new
                          dimension to remembrance in physical spaces or
                          memorial objects. Passersby can scan the QR codes,
                          allowing the stories and experiences of those who've
                          passed to live on.
                        </p>
                        <a
                          className="read-more btn mt-2 blog-contain"
                          data-paragraph="paragraph1"
                          // onClick={() => {
                          //   setUpdateModalData(modalData[0]);
                          //   openModal();
                          // }}
                        >
                          Read more
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="spinner__face">
                  <div className="content-animation" data-type="china">
                    <div className="content__left"></div>
                    <div className="content__right">
                      <div className="content__main">
                        <p
                          className="text-white cursor-pointer blog-contain"
                          data-paragraph="paragraph2"
                          // onClick={() => {
                          //   setUpdateModalData(modalData[0]);
                          //   openModal();
                          // }}
                        >
                          QR codes can be engraved on plaques, memorials, and
                          keepsake jewelry, or even shared through social media,
                          enabling family members to effortlessly share the rich
                          tapestry of memories with friends and relatives.
                        </p>
                        <a
                          className="read-more btn mt-2 blog-contain"
                          data-paragraph="paragraph2"
                          // onClick={() => {
                          //   setUpdateModalData(modalData[0]);
                          //   openModal();
                          // }}
                        >
                          Read more
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="spinner__face">
                  <div className="content-animation" data-type="usa">
                    <div className="content__left"></div>
                    <div className="content__right">
                      <div className="content__main">
                        <p
                          className="text-white cursor-pointer blog-contain"
                          // onClick={() => {
                          //   setUpdateModalData(modalData[0]);
                          //   openModal();
                          // }}
                          data-paragraph="paragraph3"
                        >
                          Moreover, VidaVault fosters connections by
                          facilitating the creation of family trees, fostering a
                          sense of continuity across generations.
                        </p>
                        <a
                          className="read-more btn mt-2 blog-contain"
                          data-paragraph="paragraph3"

                          // onClick={() => {
                          //   setUpdateModalData(modalData[0]);
                          //   openModal();
                          // }}
                        >
                          Read more
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="spinner__face">
                  <div className="content-animation" data-type="peru">
                    <div className="content__left overlay-dark-50 section-modal-overlays"></div>
                    <div className="content__right">
                      <div className="content__main">
                        <p
                          className="text-white cursor-pointer blog-contain"
                          // onClick={() => {
                          //   setUpdateModalData(modalData[1]);
                          //   openModal();
                          // }}
                          data-paragraph="paragraph4"
                        >
                          Join us in preserving legacies, embracing stories, and
                          fostering connections that span time with VidaVault.
                        </p>
                        <a
                          className="read-more btn mt-2 blog-contain"
                          data-paragraph="paragraph4"

                          // onClick={() => {
                          //   setUpdateModalData(modalData[0]);
                          //   openModal();
                          // }}
                        >
                          Read more
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <!--  Poor man's preloader --> */}
          <div style={{ height: 0, width: 0, overflow: "hidden" }} >
            <img
              src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/215059/peru.jpg"
              alt="sq"
            />
            <img
              src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/215059/canada.jpg"
              alt="sq"
            />
            <img
              src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/215059/china.jpg"
              alt="sq"
            />
            <img
              src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/215059/usa.jpg"
              alt="sq"
            />
            <img
              src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/215059/iceland.jpg"
              alt="sq"
            />
          </div>
        </>
        {/* </div> */}

        <div className="spinner-parent">
          <input
            type="radio"
            // checked
            id="radio-front"
            className="radios cursor-pointer"
            name="select-face"
          />
          <input
            type="radio"
            id="radio-left"
            className="radios cursor-pointer"
            name="select-face"
          />
          <input
            type="radio"
            id="radio-right"
            className="radios cursor-pointer"
            name="select-face"
          />
          <input
            type="radio"
            id="radio-back"
            className="radios cursor-pointer"
            name="select-face"
          />

          {/* <div className="separator"></div> */}

          <div className="space3d">
            <div className="_3dbox">
              <div className="_3dface _3dface--front">
                <div className="_3dface__content">
                  <p
                    className="text-white cursor-pointer"
                    onClick={() => {
                      setUpdateModalData(modalData[0]);
                      openModal();
                    }}
                  >
                    Beyond the digital realm, our solution brings a new
                    dimension to remembrance in physical spaces or memorial
                    objects. Passersby can scan the QR codes, allowing the
                    stories and experiences of those who've passed to live on.
                    <br />
                    <a
                      className="read-more btn mt-3"
                      onClick={() => {
                        setUpdateModalData(modalData[0]);
                        openModal();
                      }}
                    >
                      Read more
                    </a>
                  </p>
                </div>
              </div>
              <div className="_3dface _3dface--top">
                <div className="_3dface__content">
                  <p
                    className="text-white cursor-pointer"
                    onClick={() => {
                      setUpdateModalData(modalData[1]);
                      openModal();
                    }}
                  >
                    QR codes can be engraved on plaques, memorials, and keepsake
                    jewelry, or even shared through social media, enabling
                    family members to effortlessly share the rich tapestry of
                    memories with friends and relatives.
                    <br />
                    <a
                      className="read-more btn mt-3"
                      onClick={() => {
                        setUpdateModalData(modalData[1]);
                        openModal();
                      }}
                    >
                      Read more
                    </a>
                  </p>
                </div>
              </div>
              <div className="_3dface _3dface--bottom">
                <div className="_3dface__content">
                  <p
                    className="text-white cursor-pointer"
                    onClick={() => {
                      setUpdateModalData(modalData[3]);
                      openModal();
                    }}
                  >
                    Join us in preserving legacies, embracing stories, and
                    fostering connections that span time with VidaVault.
                    <br />
                    <a
                      className="read-more btn mt-3"
                      onClick={() => {
                        setUpdateModalData(modalData[3]);
                        openModal();
                      }}
                    >
                      Read more
                    </a>
                  </p>
                </div>
              </div>
              <div className="_3dface _3dface--left"></div>
              <div className="_3dface _3dface--right"></div>
              <div className="_3dface _3dface--back">
                <div className="_3dface__content">
                  <p
                    className="text-white flipped-horizontal cursor-pointer"
                    onClick={() => {
                      setUpdateModalData(modalData[2]);
                      openModal();
                    }}
                  >
                    Moreover, VidaVault fosters connections by facilitating the
                    creation of family trees, fostering a sense of continuity
                    across generations.
                    <br />
                    <a
                      className="read-more btn mt-3"
                      onClick={() => {
                        setUpdateModalData(modalData[2]);
                        openModal();
                      }}
                    >
                      Read more
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="space3d_2_div">
            <div className="_3dbox">
              <div className="_3dface _3dface--front">
                <div></div>
                {/* <img
                  src={section_1}
                  alt="section-img"
                  style={{ width: "100%", height: "100%" }}
                /> */}
              </div>
              <div className="_3dface _3dface--top overlay-dark-50 section-modal-overlays">
                <div></div>
                {/* <img
                  src={section_4}
                  alt="section-img"
                  style={{ width: "100%", height: "100%" }}
                /> */}
              </div>
              <div className="_3dface _3dface--bottom ">
                <div></div>
                {/* <img
                  src={section_2}
                  alt="section-img"
                  style={{ width: "100%", height: "100%" }}
                /> */}
              </div>
              <div className="_3dface _3dface--left"></div>
              <div className="_3dface _3dface--right"></div>
              <div className="_3dface _3dface--back">
                <div></div>
                {/* <img
                  src={section_3}
                  alt="section-img"
                  className="flipped-horizontal"
                  style={{ width: "100%", height: "100%" }}
                /> */}
              </div>
            </div>
          </div>
        </div>

        {/* <div class="scene">
  <div class="cube">
    <div class="face front">Front</div>
    <div class="face back">Back</div>
    <div class="face right">Right</div>
    <div class="face left">Left</div>
    <div class="face top">Top</div>
    <div class="face bottom">Bottom</div>
  </div>
</div> */}

        {/* section memories */}
        <section className="vvdemo" id="demo">
          <header className="section-header-wrapper">
            <div className="section-header">
              <h2>Elevating Memories</h2>
              <p>Unveil the Magic of VidaVault's Features</p>
            </div>
          </header>
          <section className="vvdemo-content-wrapper">
            <div className="vvdemo-tabs-wrapper">
              <ul className="tabs">
                <li
                  // className="tab-logo tab-active"
                  className={tab === 1 ? "tab-logo tab-active" : "tab-logo"}
                  rel="tab1"
                  onClick={() => setTab(1)}
                >
                  <span>Introduction</span>
                </li>
                <li
                  className={tab === 2 ? "tab-logo tab-active" : "tab-logo"}
                  rel="tab2"
                  onClick={() => setTab(2)}
                >
                  <span>Curate</span>
                </li>
                <li
                  className={tab === 3 ? "tab-logo tab-active" : "tab-logo"}
                  rel="tab3"
                  onClick={() => setTab(3)}
                >
                  <span>QR Codes</span>
                </li>
                <li
                  className={tab === 4 ? "tab-logo tab-active" : "tab-logo"}
                  rel="tab4"
                  onClick={() => setTab(4)}
                >
                  <span>Family</span>
                </li>
              </ul>
            </div>

            <div
              className="vvdemo-container-wrapper bg-artical-modal"
              style={{ margin: "0px", marginBottom: "0" }}
            >
              <div className="tab-container">
                <div
                  className={
                    tab === 1 ? "tab tab1 fadeIn active" : "tab tab1 fadeIn"
                  }
                  style={{
                    background: `url(${tabDetails?.img}) 50% 50% no-repeat`,
                    backgroundSize: "cover",
                    maxWidth: "100%",
                    height: "auto",
                  }}
                >
                  <div className="tab-heading" rel="tab1">
                    <div className="tab-logo tab-logo1">
                      <span className="visually-hidden">
                        {tabDetails?.title}
                      </span>
                    </div>
                  </div>
                  <div
                    id="tab1"
                    className="tab-content-wrapper overlay overlay-dark-70"
                    style={{
                      background:
                        "-webkit-linear-gradient(-45deg, rgba(4,24,136,.7) 0%, rgba(24,2,73,.7) 100%)",
                    }}
                  >
                    <div className="tab-content-main">
                      <div className="tab-content">
                        <blockquote className="vvdemo-text">
                          {tabDetails?.desc}
                        </blockquote>
                        {tab === 4 && (
                          <>
                            <p>
                              Ready to embrace the future of remembrance? Join
                              us on VidaVault and embark on a journey that spans
                              time, connections, and cherished memories.
                            </p>

                            <p>
                            <NavLink to="fha/register" className="btn orange">
                                Get Started Today
                              </NavLink>
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* tab-container for small devices */}
              <div className="tab-container1">
                <div class="accordion">
                  <span class="target-fix" id="accordion"></span>
                  <div>
                    <span class="target-fix" id="accordion1"></span>
                    <a
                      href="#accordion1"
                      id="open-accordion1"
                      title="open"
                      className="tab-logo"
                    >
                      Introduction
                    </a>
                    <a
                      href="#accordion"
                      id="close-accordion1"
                      title="close"
                      className="tab-logo  "
                    >
                      Introduction
                    </a>
                    <div
                      class="accordion-content accordion-overlay accordion-overlay-70"
                      style={{
                        background: `url(${bg_1}) 50% 50% no-repeat`,
                        backgroundSize: "cover",
                        maxWidth: "100%",
                        // height: "auto",
                        overflow: "hidden",
                      }}
                    >
                      <p className="paragraph-1">
                        Welcome to VidaVault, the platform that brings memories
                        to life in ways you've never imagined.
                      </p>
                    </div>
                  </div>
                  <div>
                    <span class="target-fix" id="accordion2"></span>
                    <a
                      href="#accordion2"
                      id="open-accordion2"
                      title="open"
                      className="tab-logo "
                    >
                      Curate
                    </a>
                    <a
                      href="#accordion"
                      id="close-accordion2"
                      title="close"
                      className="tab-logo"
                    >
                      Curate
                    </a>
                    <div
                      class="accordion-content accordion-overlay accordion-overlay-70"
                      style={{
                        background: `url(${bg_2}) 50% 50% no-repeat`,
                        backgroundSize: "cover",
                        maxWidth: "100%",
                        // height: "auto",
                      }}
                    >
                      <p className="paragraph-1">
                        Curate memories into captivating slideshows. Simply
                        upload photos and videos to a profile and arrange them
                        to tell a beautiful story.
                      </p>
                    </div>
                  </div>
                  <div>
                    <span class="target-fix" id="accordion3"></span>
                    <a
                      href="#accordion3"
                      id="open-accordion3"
                      title="open"
                      className="tab-logo"
                    >
                      QR Codes
                    </a>
                    <a
                      href="#accordion"
                      id="close-accordion3"
                      title="close"
                      className="tab-logo"
                    >
                      QR Codes
                    </a>
                    <div
                      class="accordion-content accordion-overlay accordion-overlay-70"
                      style={{
                        background: `url(${bg_3}) 50% 50% no-repeat`,
                        backgroundSize: "cover",
                        maxWidth: "100%",
                        // height: "auto",
                      }}
                    >
                      <p className="paragraph-1">
                        Next, experience the magic of our QR codes. Engrave them
                        on plaques, jewelry, or share them on social media. Each
                        code is a doorway to a world of memories.
                      </p>
                    </div>
                  </div>
                  <div>
                    <span class="target-fix" id="accordion4"></span>
                    <a
                      href="#accordion4"
                      id="open-accordion4"
                      title="open"
                      className="tab-logo"
                    >
                      Family
                    </a>
                    <a
                      href="#accordion"
                      id="close-accordion4"
                      title="close"
                      className="tab-logo"
                    >
                      Family
                    </a>
                    <div
                      class="accordion-content accordion-overlay accordion-overlay-70"
                      style={{
                        background: `url(${bg_4}) 50% 50% no-repeat`,
                        backgroundSize: "cover",
                        maxWidth: "100%",
                        display: "flex",
                        flexDirection: "column",
                        // background:
                        //   "linear-gradient(135deg, rgba(4, 24, 136, 0.7) 0%, rgba(24, 2, 73, 0.7) 100%)",
                        // height: "auto",
                      }}
                    >
                      <p className="paragraph-1">
                        And that's not all – our family tree feature fosters
                        connections across generations. Build a comprehensive
                        family tree, complete with videos, photos, and
                        relationships.
                      </p>
                      <p className="paragraph-2">
                        Ready to embrace the future of remembrance? Join us on
                        VidaVault and embark on a journey that spans time,
                        connections, and cherished memories.
                      </p>
                      <div className="mb-5">
                      <NavLink to="fha/register"  className="btn-1 orange">
                          Get Started Today
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
        {/* <!-- ======================================
		                    Footer
	      ======================================= --> */}
        <div>
          <footer className="footer">
            <div className="footer-wrapper">
              <div className="footer-content">
                <p>© VidaVault 2023. All rights reserved.</p>
                <p>
                  For a personalized demo tailored to your needs, please don't
                  hesitate to{" "}
                  <a
                    href="mailto:accounts@vidavault.net"
                    className="font-weight-bold"
                  >
                    contact us
                  </a>
                  .
                </p>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
};

export default LandingPage;
