import React, { createContext, useState } from "react";
const UserContext = createContext();

function UserProvider({ children }) {
  const [loggedIn, setLoggedIn] = useState(false);
  const [adminLogin, setAdminLogin] = useState(false);
  const [isOpen, setToast] = useState(false);
  const [msg, setMsg] = useState({ text: "", type: "" });
  const [isLoading, setIsLoading] = useState(false);
  const logUserIn = (newValue) => {
    setLoggedIn(newValue);
  };
  const logAdminIn = (newValue) => {
    setAdminLogin(newValue);
  };
  const setTicketCount = (val) =>{
    let temp = loggedIn
    temp.ticketCount = val
    setLoggedIn({...temp})
  }
  const setNotificationCount = (val) =>{
    let temp = loggedIn
    temp.notificationCount = val
    setLoggedIn({...temp})
  }
  return (
    <UserContext.Provider
      value={{
        loggedIn,
        logUserIn,
        adminLogin,
        logAdminIn,
        isOpen,
        setToast,
        msg,
        setMsg,
        isLoading,
        setIsLoading,
        setTicketCount,
        setNotificationCount,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
export { UserContext, UserProvider };
