/** @format */

import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import axios from "axios";
import { baseUrl } from "../../config";
import { useContext } from "react";
import { UserContext } from "../../UserProvider";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import MessageComponent from "../../components/MessageComponent";
import Cookies from "js-cookie";
const Messagedetails = () => {
  const [ticketData, setTicketData] = useState([]);
  const { loggedIn, setIsLoading, setMsg, setToast, logUserIn } =
    useContext(UserContext);
  const { messageId } = useParams();
  const navigate = useNavigate();
  const fetchData = () => {
    setIsLoading(true);
    axios
      .get(`${baseUrl}/support_ticket/fha_user/${messageId}`, {
        headers: { Authorization: `Bearer ${loggedIn.token}` },
      })
      .then((res) => {
        setTicketData(res.data.data[0]);
        setIsLoading(false);
      })
      .catch((err) => {
        if (err?.response?.data?.resultMsg === "User is inactive") {
          setMsg({
            type: "error",
            text: "The Super Admin has deactivated your account. Contact super admin for details.",
          });
          setToast(true);

          setTimeout(() => {
            Cookies.remove("userData");
            localStorage.removeItem("fha_logo");
            logUserIn(false);

            navigate("/");
          }, 2000);
        }
        setIsLoading(false);
      });
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div style={{ height: "100vh" }}>
      <Helmet>
        <title>VidaVault</title>
      </Helmet>
      <Navbar />
      <div style={{ height: "85%" }} className="page-content">
        <Sidebar />
        <div className="content-wrapper ">
          <div className="content-inner">
            <div className="navbar navbar-expand-lg navbar-light sticky-top">
              <div className="text-center d-lg-none w-100 ">
                <button
                  type="button"
                  className="navbar-toggler dropdown-toggle"
                  data-toggle="collapse"
                  data-target="#navbar-second"
                >
                  <i className="icon-unfold mr-2"></i>
                  Open Task Menu
                </button>
              </div>
            </div>
            <div className="content ">
              <div className="card overflow-auto" style={{ height: "100%" }}>
                <div
                  className="d-lg-flex flex-wrap p-lg-5"
                  // style={{ display: "flex", flexWrap: "wrap", padding: "4rem" }}
                >
                  {ticketData.st_from && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexBasis: "50%",
                        padding: "0.7rem 1.5rem",
                      }}
                    >
                      <div style={{ fontSize: "1rem", fontWeight: "bold" }}>
                        FHA :
                      </div>
                      <div style={{ marginLeft: "0.5rem" }}>
                        {ticketData.st_from}
                      </div>
                    </div>
                  )}
                  {ticketData.st_email && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexBasis: "50%",
                        padding: "0.7rem 1.5rem",
                      }}
                    >
                      <div style={{ fontSize: "1rem", fontWeight: "bold" }}>
                        FHA Email :
                      </div>
                      <div style={{ marginLeft: "0.5rem" }}>
                        {ticketData.st_email}
                      </div>
                    </div>
                  )}
                  {ticketData.st_business_name && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexBasis: "50%",
                        padding: "0.7rem 1.5rem",
                      }}
                    >
                      <div style={{ fontSize: "1rem", fontWeight: "bold" }}>
                        Bussiness Name :
                      </div>
                      <div style={{ marginLeft: "0.5rem" }}>
                        {ticketData.st_business_name}
                      </div>
                    </div>
                  )}
                  {ticketData.st_subject && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexBasis: "50%",
                        padding: "0.7rem 1.5rem",
                      }}
                    >
                      <div style={{ fontSize: "1rem", fontWeight: "bold" }}>
                        Subject :
                      </div>
                      <div style={{ marginLeft: "0.5rem" }}>
                        {ticketData.st_subject}
                      </div>
                    </div>
                  )}
                  {ticketData.st_message && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexBasis: "50%",
                        padding: "0.7rem 1.5rem",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "1rem",
                          fontWeight: "bold",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Message :
                      </div>
                      <div style={{ marginLeft: "0.5rem" }}>
                        {ticketData.st_message}
                      </div>
                    </div>
                  )}
                  {ticketData.st_attachments && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexBasis: "50%",
                        padding: "0.7rem 1.5rem",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "1rem",
                          fontWeight: "bold",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Attachments :
                      </div>
                      <a
                        href={ticketData.st_attachments}
                        target="_blank"
                        rel="noreferrer"
                        style={{ marginLeft: "0.5rem" }}
                      >
                        {ticketData.st_attachments}
                      </a>
                    </div>
                  )}
                  {ticketData.created_dt && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexBasis: "50%",
                        padding: "0.7rem 1.5rem",
                      }}
                    >
                      <div style={{ fontSize: "1rem", fontWeight: "bold" }}>
                        Created At :
                      </div>
                      <div style={{ marginLeft: "0.5rem" }}>
                      {new Date(ticketData.created_dt).toLocaleString(
                          "en-US",
                          {
                            timeZone: "UTC",
                            weekday: "long",
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            hour12: true,
                          }
                        )}
                        {/* {new Date(ticketData.created_dt).toUTCString()} */}
                      </div>
                    </div>
                  )}
                  {ticketData.st_email && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexBasis: "100%",
                        padding: "0.7rem 1.5rem",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "1rem",
                          fontWeight: "bold",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Responses :
                      </div>
                      <div
                        style={{
                          marginLeft: "0.5rem",
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        <ul className="list-group-flush">
                          {ticketData.response.length > 0 ? (
                            ticketData?.response?.map((res) => (
                              <MessageComponent message={res} />
                            ))
                          ) : (
                            <>
                              <span style={{color:"rgb(181,181,181)"}}>No Response Yet</span>
                            </>
                          )}
                        </ul>
                      </div>
                    </div>
                  )}

                  {/* {
                    <div
                      style={{
                        display: "flex",
                        flexBasis: "50%",
                        alignItems: "center",
                        padding: "0.7rem 1.5rem",
                      }}
                    >
                      <div className="form-group form-group-floating">
                        <div className="position-relative">
                          <textarea
                            className="form-control form-control-outline"
                            placeholder="Placeholder"
                            onChange={(e) => setResponse(e.target.value)}
                            value={response}
                            name="personalDetails_obituryText"
                            rows="5"
                            cols="50"
                          ></textarea>
                          <label className="label-floating">Response</label>
                        </div>
                      </div>
                      <div style={{ marginLeft: "0.5rem" }}>
                        <div
                          onClick={handleSendResponse}
                          className="btn btn-teal"
                        >
                          Respond
                        </div>
                      </div>
                    </div>
                  } */}
                </div>
              </div>
            </div>
            <div className="navbar navbar-expand-lg navbar-light">
              <div className="text-center d-lg-none w-100">
                <button
                  type="button"
                  className="navbar-toggler dropdown-toggle"
                  data-toggle="collapse"
                  data-target="#navbar-footer"
                >
                  <i className="icon-unfold mr-2"></i>
                  Open Footer
                </button>
              </div>
              <div className="navbar-collapse collapse" id="navbar-footer">
                <span className="navbar-text">
                  <span>&copy; 2023 VidaVault</span>. All rights reserved.
                </span>
                <ul className="navbar-nav ml-lg-auto">
                  <li className="nav-item">
                    <NavLink to="/fha/support" className="navbar-nav-link">
                      <i className="fa-solid fa-life-ring mr-2"></i> Support
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Messagedetails;
