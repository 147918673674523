import axios from "axios";
import React from "react";
import { useState } from "react";
import Navbar from "../../components/Navbar";
import Popup from "../../components/Popup";
import { baseUrl } from "../../config";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { UserContext } from "../../UserProvider";
import { useContext } from "react";
import Cookies from "js-cookie";
const Resetpassword = () => {
  const { token } = useParams();
  const [password, setPassword] = useState();
  const [password2, setPassword2] = useState();
  const [success, setSuccess] = useState(false);
  const { loggedIn, logUserIn, setMsg, setToast, setIsLoading } =
    useContext(UserContext);
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    if (password == "" || password2 == "") {
      setMsg({ type: "error", text: "Please fill both fields" });
      setToast(true);
    } else if (password != password2) {
      setMsg({ type: "error", text: "Passwords doesn't match" });
      setToast(true);
    } else {
      setIsLoading(true);
      axios
        .post(`${baseUrl}/reset_password`, {
          password: password,
          confirm_password: password2,
          activation_token: token,
        })
        .then((response) => {
          setIsLoading(false);
          setMsg({ type: "success", text: response.data.resultMsg });
          setToast(true);
          setSuccess(true);
        })
        .catch((err) => {
          console.log("error => ", err);
          if (err?.response?.data?.resultMsg === "User is inactive") {
            setMsg({
              type: "error",
              text: "The Super Admin has deactivated your account. Contact super admin for details.",
            });
            setToast(true);

            setTimeout(() => {
              Cookies.remove("userData");
              localStorage.removeItem("fha_logo");
              logUserIn(false);

              navigate("/");
            }, 2000);
          }
          setTimeout(() => {
            navigate("/fha/recover");
          }, 1000);
          setIsLoading(false);
          setMsg({ type: "error", text: "Link expired, try with new link" });
          setToast(true);
        });
    }
  };
  const handleRedirect = () => {
    window.location.replace("/");
  };
  return (
    <div style={{ height: "100vh" }}>
      <Navbar />
      <div style={{ height: "85%" }} className="page-content">
        <div className="content-wrapper">
          <div className="content-inner">
            <div className="content d-flex justify-content-center align-items-center">
              {success ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "white",
                      float: "left",
                      marginTop: "40px",
                      borderRadius: "5px",
                      boxSizing: "border-box",
                      padding: "80px 30px 25px 30px",
                      textAlign: "center",
                      position: "relative",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      boxShadow:
                        "0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)",
                    }}
                  >
                    <span
                      style={{
                        position: "absolute",
                        top: "-50px",
                        display: "flex",
                        justifyContent: "center",
                        width: "100px",
                        height: "100px",
                        borderRadius: "100%",
                        backgroundColor: "#60c878",
                        border: "5px solid white",
                      }}
                    >
                      <i
                        className="fa fa-check"
                        style={{
                          color: "white",
                          lineHeight: "100px",
                          fontSize: "45px",
                        }}
                      ></i>
                    </span>
                    <h1
                      style={{
                        textTransform: "uppercase",
                        color: "#55585b",
                        fontSize: "18px",
                        fontWeight: "500",
                        marginBottom: "5px",
                      }}
                    >
                      Password Reset Successful
                    </h1>
                    <h2
                      style={{
                        color: "#959a9e",
                        fontSize: "16px",
                        fontWeight: "400",
                        marginTop: "0px",
                      }}
                    >
                      Congratulations! Your password has been changed. Please
                      <div
                        style={{
                          color: "blue",
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                        onClick={handleRedirect}
                      >
                        click here
                      </div>
                      to log in to the system
                    </h2>
                  </div>
                </div>
              ) : (
                <form className="login-form" onSubmit={handleSubmit}>
                  <div className="card mb-0">
                    <div className="card-body">
                      <div className="text-center mb-3">
                        <i className="fa-solid fa-key large-icon text-indigo mb-3 mt-1"></i>
                        <h5 className="mb-0">Reset Password</h5>
                        <span className="d-block text-muted">
                          Reset your password to access the VidaVault system
                        </span>
                      </div>
                      <div className="form-group form-group-floating row">
                        <div className="col-lg-12">
                          <div className="position-relative">
                            <input
                              type="password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              className="form-control form-control-outline"
                              placeholder="Placeholder"
                            />
                            <label className="label-floating">
                              New Password
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="form-group form-group-floating row">
                        <div className="col-lg-12">
                          <div className="position-relative">
                            <input
                              type="password"
                              value={password2}
                              onChange={(e) => setPassword2(e.target.value)}
                              className="form-control form-control-outline"
                              placeholder="Placeholder"
                            />
                            <label className="label-floating">
                              Confirm Password
                            </label>
                          </div>
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="btn btn-teal btn-labeled btn-block btn-labeled-right"
                      >
                        <b>
                          <i className="fa-solid fa-rotate-right"></i>
                        </b>
                        Reset Password
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </div>

            <div className="navbar navbar-expand-lg navbar-light">
              <div className="text-center d-lg-none w-100">
                <button
                  type="button"
                  className="navbar-toggler dropdown-toggle"
                  data-toggle="collapse"
                  data-target="#navbar-footer"
                >
                  <i className="icon-unfold mr-2"></i>
                  Footer
                </button>
              </div>

              <div className="navbar-collapse collapse" id="navbar-footer">
                <span className="navbar-text">
                  &copy; 2022 <a href="#">VidaVault</a>
                </span>

                <ul className="navbar-nav ml-lg-auto">
                  <li className="nav-item">
                    <NavLink to="/fha/support" className="navbar-nav-link">
                      <i className="fa-solid fa-life-ring mr-2"></i> Support
                    </NavLink>
                  </li>
                  {/* <li className="nav-item">
                    <a
                      href="FHA-documentation.html"
                      className="navbar-nav-link"
                      target="_blank"
                    >
                      <i className="fa-solid fa-file mr-2"></i> Documentation
                    </a>
                  </li> */}
                  <li className="nav-item">
                    <NavLink
                      to="/fha/register"
                      className="navbar-nav-link font-weight-semibold"
                    >
                      <span className="text-pink mr-2">
                        <i className="fa-solid fa-user-plus"></i> Sign Up
                      </span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resetpassword;
