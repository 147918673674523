import React from "react";
import "../assets/css/price_pop_modal.css";
const PriceModal = ({ setOpenPriceModal }) => {
  return (
    <>
      <div className="price-modalled">
        <div className="price-modalled-popup-content">
          <span
            className="closed"
            onClick={() => {
              setOpenPriceModal(false);
            }}
          >
            &times;
          </span>
          <div className="price-list">
            <div className="price-card">
              <p>Free</p>
              <div className="sub-header">$0</div>
              <hr className="horizontal-line" />
              <div className="desc-lists">
                <ul className="desc-list">
                  <li>Enjoy a free trial of our services today.</li>
                </ul>
              </div>
            </div>

            <div className="price-card">
              <p>Monthly</p>
              <div className="sub-header">$100</div>
              <hr className="horizontal-line" />
              <div className="desc-lists">
                <ul className="desc-list">
                  <li>Get access for just $100 per month.</li>
                </ul>
              </div>
            </div>
            <div className="price-card">
              <p>Yearly</p>
              <div className="sub-header">$200</div>
              <hr className="horizontal-line" />
              <div className="desc-lists">
                <ul className="desc-list">
                  <li>
                    Opt for an annual subscription and get it for only $200.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PriceModal;
