import { Elements } from "@stripe/react-stripe-js";
import React, { useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Checkout from "./CheckoutForm";
import { createPaymentIntent, stripePromise } from "./Stripe";
import PaymentFailure from "./PaymentFailure";
const Payment = () => {
  const [err, setErr] = useState(false);
  // State to track if the page has been reloaded
  const [isPageReloaded, setIsPageReloaded] = useState(false);
  const location = useLocation();
  const navigate = useNavigate(); // Get the navigate function for navigation
  console.log(location)

  // useEffect(() => {
  //   const isReloaded = performance.navigation.type === 1;
  //   setIsPageReloaded(isReloaded);

  //   if (isReloaded) {
  //     console.log(isPageReloaded);
  //     // Redirect to "fha/subscriptions" if the page is reloaded
  //     navigate("/fha/subscriptions");
  //   }
  // }, [navigate]);
  // console.log(performance.navigation);

  useEffect(() => {
    if (!location.state) {
      // If location.state is null or falsy, redirect to "fha/subscriptions"
      navigate("/fha/subscriptions");
      return; // Stop further processing
    }

    // Continue with your payment logic here
    // For example, you might want to fetch data or perform payment-related operations
    // If an error occurs, you can handle it in the component
  }, [navigate, location.state]);

  console.log(location);
  return (
    <div>
      {err ? (
        <div>
          <PaymentFailure err={err}/>
        </div>
      ) : (
        <div>
          { location.state !== null && location.state.data && (
            <Elements stripe={stripePromise}>
              <Checkout setErr={setErr} data={location.state.data} />
            </Elements>
          )}
        </div>
      )}
    </div>
  );
};

export default Payment;
