import React, { useState, useEffect } from "react";
import "./BackToTopButton.css"; // Import your CSS file

const BackToTopButton = () => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // console.log(document.body.scrollTop);
      if (document.body.scrollTop > 200) {
        console.log(showButton);
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll, true);

    return () => {
      window.removeEventListener("scroll", handleScroll, true);
    };
  }, []);

  useEffect(() => {
    console.log("Show => ", showButton);
  }, [showButton]);

  const scrollToTop = () => {
    document.body.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div>
      <a id="button"></a>
      <div
        id="button"
        className={showButton ? "show" : ""}
        onClick={scrollToTop}
      >
        <span className="icon">
          <i class="fa-solid fa-chevron-up" style={{ color: "#fff" }}></i>
        </span>
      </div>
    </div>
  );
};

export default BackToTopButton;
