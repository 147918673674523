import { useEffect } from "react";
import "./splitAnimation.css";
function SplitAnimation({ openModal, setUpdateModalData }) {
  let activeIndex = 0;
  let limit = 0;
  let disabled = false;
  let stage;
  let controls;
  let canvas = false;

  const SPIN_FORWARD_CLASS = "js-spin-fwd";
  const SPIN_BACKWARD_CLASS = "js-spin-bwd";
  const DISABLE_TRANSITIONS_CLASS = "js-transitions-disabled";
  const SPIN_DUR = 1000;

  const appendControls = () => {
    console.log("LIMIT ==> ", limit);
    for (let i = 0; i < limit; i++) {
      const control = document.createElement("a");
      control.href = "#";
      control.setAttribute("data-index", i);
      controls.appendChild(control);
    }
    const height = controls.lastElementChild.offsetHeight;
    controls.style.height = 30 + limit * height + "px";

    controls.children[0].classList.add("active");
  };

  const setIndexes = () => {
    const spinners = document.querySelectorAll(".spinners > *");
    spinners.forEach((el, i) => {
      el.setAttribute("data-index", i);
      limit++;
    });
  };

  const duplicateSpinner = () => {
    const el = document.querySelector(".spinners").parentNode;
    const html = el.innerHTML;
    el.innerHTML += html;
    const spinners = document.querySelectorAll(".spinners");
    spinners[spinners.length - 1].classList.add("spinner--right");
    spinners[spinners.length - 1].classList.remove("spinner--left");
  };

  const paintFaces = () => {
    const spinnerFaces = document.querySelectorAll(".spinner__face");
    // spinnerFaces.forEach((el) => {
    //   const color = el.getAttribute("data-bg");
    //   el.firstElementChild.style.backgroundImage = `url(${getBase64PixelByColor(
    //     color
    //   )})`;
    // });
  };

  const getBase64PixelByColor = (hex) => {
    if (!canvas) {
      canvas = document.createElement("canvas");
      canvas.height = 1;
      canvas.width = 1;
    }
    if (canvas.getContext) {
      const ctx = canvas.getContext("2d");
      ctx.fillStyle = hex;
      ctx.fillRect(0, 0, 1, 1);
      return canvas.toDataURL();
    }
    return false;
  };

  const prepareDom = () => {
    setIndexes();
    paintFaces();
    duplicateSpinner();
    appendControls();
  };

  const spin = (inc = 1) => {
    if (disabled) return;
    if (!inc) return;
    activeIndex += inc;
    disabled = true;

    if (activeIndex >= limit) {
      activeIndex = 0;
    }

    if (activeIndex < 0) {
      activeIndex = limit - 1;
    }

    const activeEls = document.querySelectorAll(".spinner__face.js-active");
    const nextEls = document.querySelectorAll(
      `.spinner__face[data-index="${activeIndex}"]`
    );
    nextEls.forEach((el) => el.classList.add("js-next"));

    if (inc > 0) {
      stage.classList.add(SPIN_FORWARD_CLASS);
    } else {
      stage.classList.add(SPIN_BACKWARD_CLASS);
    }

    controls.querySelectorAll(".active").forEach((el) => {
      // console.log("Is active",el);
      return el.classList.remove("active");
    });
    controls.children[activeIndex].classList.add("active");

    setTimeout(
      () => {
        spinCallback(inc);
      },
      SPIN_DUR,
      inc
    );
  };

  const spinCallback = (inc) => {
    document
      .querySelectorAll(".js-active")
      .forEach((el) => el.classList.remove("js-active"));
    document
      .querySelectorAll(".js-next")
      .forEach((el) => el.classList.replace("js-next", "js-active"));
    // .forEach((el) => el.classList.remove("js-next")).add('js-active');
    stage.classList.add(DISABLE_TRANSITIONS_CLASS);
    stage.classList.remove(SPIN_FORWARD_CLASS);
    stage.classList.remove(SPIN_BACKWARD_CLASS);

    document.querySelectorAll(".js-active").forEach((el) => {
      el.parentNode.insertBefore(el, el.parentNode.firstChild);
    });

    setTimeout(() => {
      stage.classList.remove(DISABLE_TRANSITIONS_CLASS);
      disabled = false;
    }, 100);
  };

  const attachListeners = () => {
    document.addEventListener("keyup", (e) => {
      // console.log("attachListeners --> ", attachListeners);
      console.log("Key code --> ", e.keyCode);
      switch (e.keyCode) {
        case 38:
          spin(-1);
          break;
        case 40:
          spin(1);
          break;
      }
    });

    controls.addEventListener("click", (e) => {
      console.log("Event", e.target);
      e.preventDefault();
      if (disabled) return;
      const target = e.target;
      console.log("Event", target.tagName);
      if (target.tagName === "A") {
        const toIndex = parseInt(target.getAttribute("data-index"), 10);
        // console.log("ToIndex ==> ",toIndex," activeIndex ==> ",activeIndex);
        spin(toIndex - activeIndex);
      }
    });
  };

  const assignEls = () => {
    stage = document.querySelector(".carousel__stage");
    controls = document.querySelector(".carousel__controls");
  };

  const init = () => {
    assignEls();
    prepareDom();
    attachListeners();
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <div className="carouseling">
        <div className="carousel__controls"></div>
        <div className="carousel__stage">
          <div className="spinners spinner--left">
            <div className="spinner__face js-active">
              <div className="content-animation" data-type="iceland">
                <div className="content__left">
                  {/* <h1>
                    ICELAND
                    <br />
                    <span>EUROPE</span>
                  </h1> */}
                </div>
                <div className="content__right">
                  <div className="content__main">
                    <p
                      className="text-white cursor-pointer"
                      onClick={() => {
                          // setUpdateModalData(modalData[0]);
                          openModal();
                      }}
                    >
                      Beyond the digital realm, our solution brings a new
                      dimension to remembrance in physical spaces or memorial
                      objects. Passersby can scan the QR codes, allowing the
                      stories and experiences of those who've passed to live on.
                    </p>
                    <a
                      className="read-more btn mt-2"
                      onClick={() => {
                        // setUpdateModalData(modalData[0]);
                        openModal();
                      }}
                    >
                      Read more
                    </a>
                    {/* <p>– Carolyn Bain</p> */}
                  </div>
                  {/* <h3 className="content__index">01</h3> */}
                </div>
              </div>
            </div>
            <div className="spinner__face">
              <div className="content-animation" data-type="china">
                <div className="content__left">
                  {/* <h1>
                    CHINA
                    <br />
                    <span>ASIA</span>
                  </h1> */}
                </div>
                <div className="content__right">
                  <div className="content__main">
                    <p>
                      QR codes can be engraved on plaques, memorials, and
                      keepsake jewelry, or even shared through social media,
                      enabling family members to effortlessly share the rich
                      tapestry of memories with friends and relatives.
                    </p>
                    <a
                      className="read-more btn mt-2"
                      onClick={() => {
                        // setUpdateModalData(modalData[0]);
                        // openModal();
                      }}
                    >
                      Read more
                    </a>
                    {/* <p>– Damian Harper</p> */}
                  </div>
                  {/* <h3 className="content__index">02</h3> */}
                </div>
              </div>
            </div>
            <div className="spinner__face">
              <div className="content-animation" data-type="usa">
                <div className="content__left">
                  {/* <h1>
                    USA
                    <br />
                    <span>NORTH AMERICA</span>
                  </h1> */}
                </div>
                <div className="content__right">
                  <div className="content__main">
                    <p>
                      Moreover, VidaVault fosters connections by facilitating
                      the creation of family trees, fostering a sense of
                      continuity across generations.
                    </p>
                    <a
                      className="read-more btn mt-2"
                      onClick={() => {
                        // setUpdateModalData(modalData[0]);
                        // openModal();
                      }}
                    >
                      Read more
                    </a>
                    {/* <p>– Regis St Louis</p> */}
                  </div>
                  {/* <h3 className="content__index">03</h3> */}
                </div>
              </div>
            </div>
            <div className="spinner__face">
              <div className="content-animation" data-type="peru">
                <div className="content__left">
                  {/* <h1>
                    PERU
                    <br />
                    <span>SOUTH AMERICA</span>
                  </h1> */}
                </div>
                <div className="content__right">
                  <div className="content__main">
                    <p
                      className="text-white cursor-pointer"
                      onClick={() => {
                        //   setUpdateModalData(modalData[1]);
                        //   openModal();
                      }}
                    >
                      Join us in preserving legacies, embracing stories, and
                      fostering connections that span time with VidaVault.
                    </p>
                    <a
                      className="read-more btn mt-2"
                      onClick={() => {
                        // setUpdateModalData(modalData[0]);
                        // openModal();
                      }}
                    >
                      Read more
                    </a>
                    {/* <p>– Carolyn McCarthy</p> */}
                  </div>
                  {/* <h3 className="content__index">04</h3> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!--  Poor man's preloader --> */}
      <div style={{ height: 0, width: 0, overflow: "hidden" }}>
        <img
          src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/215059/peru.jpg"
          alt="sq"
        />
        <img
          src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/215059/canada.jpg"
          alt="sq"
        />
        <img
          src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/215059/china.jpg"
          alt="sq"
        />
        <img
          src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/215059/usa.jpg"
          alt="sq"
        />
        <img
          src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/215059/iceland.jpg"
          alt="sq"
        />
      </div>
    </>
  );
}

export default SplitAnimation;
