import React, { useRef, useState } from "react";
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
  convertToPixelCrop,
} from "react-image-crop";
import { useDebounceEffect } from "./useDebounceEffect";
import { canvasPreview } from "./canvasPreview";
import "react-image-crop/dist/ReactCrop.css";
import "./avatar.css";
import default_logo from "../../assets/add_business_logo.png";
import { UserContext } from "../../UserProvider";
import { useContext } from "react";
function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

const Avatar = ({ backgroundImage, setBackgroundImage, setLogoFile }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [crop, setCrop] = useState({
    unit: "%",
    width: 320,
    height: 124,
    aspect: 16 / 9,
  });
  const [imgSrc, setImgSrc] = useState("");
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, setScale] = useState(1);
  const [aspect, setAspect] = useState(16 / 9);
  const [rotate, setRotate] = useState(0);
  // const [backgroundImage, setBackgroundImage] = useState(default_logo);

  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const hiddenAnchorRef = useRef(null);
  const blobUrlRef = useRef("");

  const { loggedIn, logUserIn, setMsg, setToast, setIsLoading } =
    useContext(UserContext);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setImgSrc("");
    setCompletedCrop();
    setIsModalOpen(false);
  };

  function onSelectFile(e) {
    console.log("onSelectFile");
    const selectedFile = e.target.files[0];
    // Check if a file was selected
    if (selectedFile) {
      // Check file type
      if (
        selectedFile.type === "image/jpeg" ||
        selectedFile.type === "image/png"
      ) {
        // Check file size (1MB limit)
        if (selectedFile.size <= 1 * 1024 * 1024) {
          if (e.target.files && e.target.files.length > 0) {
            setCrop(undefined); // Makes crop preview update between images.
            const reader = new FileReader();
            reader.addEventListener("load", () =>
              setImgSrc(reader.result?.toString() || "")
            );
            reader.readAsDataURL(e.target.files[0]);
          }
        } else {
          // alert("File size must be 1MB or less.");
          console.log("Error");
          setMsg({
            type: "error",
            text: "Error: File size exceeds the limit. Uploaded image must be under 1MB.",
          });
          setToast(true);
          e.target.value = null;
        }
      } else {
        // alert("Only JPG and PNG files are allowed.");
        setMsg({ type: "error", text: "Only JPG and PNG files are allowed." });
        setToast(true);
        e.target.value = null;
      }
    }
  }

  function onImageLoad(e) {
    console.log("onImageLoad ====> ", e);
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  function handleToggleAspectClick() {
    if (aspect) {
      setAspect(undefined);
    } else if (imgRef.current) {
      const { width, height } = imgRef.current;
      setAspect(16 / 9);
      const newCrop = centerAspectCrop(width, height, 16 / 9);
      setCrop(newCrop);
      // Updates the preview
      setCompletedCrop(convertToPixelCrop(newCrop, width, height));
    }
  }

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  // function onDownloadCropClick() {
  //   console.log("Set image ==> ", completedCrop);
  //   setBackgroundImage(completedCrop);

  //   if (!previewCanvasRef.current) {
  //     throw new Error("Crop canvas does not exist");
  //   }

  //   previewCanvasRef.current.toBlob((blob) => {
  //     if (!blob) {
  //       throw new Error("Failed to create blob");
  //     }
  //     if (blobUrlRef.current) {
  //       URL.revokeObjectURL(blobUrlRef.current);
  //     }
  //     blobUrlRef.current = URL?.createObjectURL(blob);
  //     hiddenAnchorRef.current.href = blobUrlRef.current;
  //     hiddenAnchorRef.current.click();
  //   });
  //   closeModal();
  // }

  function onDownloadCropClick() {
    // console.log("Cropped image data => ", completedCrop);

    if (completedCrop && previewCanvasRef.current) {
      console.log("previewCanvasRef.current ==> ", previewCanvasRef.current);
      previewCanvasRef.current.toBlob(async (blob) => {
        console.log("previewCanvasRef blob ==> ", blob);
        if (!blob) {
          console.error("Failed to create blob");
          return;
        }

        // Set the cropped image as the background of the div
        setBackgroundImage(URL.createObjectURL(blob));

        // const formData = new FormData();
        // formData.append("logo", blob, "cropped-image.png");

        setLogoFile(blob);
      }, "image/jpg"); // You can adjust the MIME type based on your needs
    }
    closeModal();
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  // console.log("Crop image ", completedCrop);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    console.log(file);
    if (file) {
      setBackgroundImage(URL.createObjectURL(file));
    }
  };

  // console.log("completedCrop => ", completedCrop);

  return (
    <>
      <div
        className="profile-icon"
        style={{
          width: "320px",
          height: "124px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          border: " 1px solid rgb(128 128 128 / 42%)",
        }}
        onClick={openModal}
      >
        <img
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
            width: "100%",
            height: "100%",
            objectFit: "fit-content",
          }}
          src={backgroundImage}
          alt="logo"
          title="Click to Change Logo"
        />
      </div>
      <input
        type="file"
        id="fileInput"
        style={{ display: "none" }}
        onChange={handleImageChange}
        accept="image/jpeg, image/png"
        // Add any additional attributes you need for the file input
      />
      {/* <button onClick={openModal}>Open Modal</button> */}
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <div>
              <span className="close text-right" onClick={closeModal}>
                &times;
              </span>

              <h2>Business Logo</h2>
            </div>
            <div className="Crop-Controls">
              {/* <div>
                <label htmlFor="scale-input">Scale: </label>
                <input
                  id="scale-input"
                  type="range"
                  //   step="0.0001"
                  value={scale}
                  disabled={!imgSrc}
                  onChange={(e) => setScale(Number(e.target.value))}
                />
              </div> */}
              {/* <div>
                <label htmlFor="rotate-input">Rotate: </label>
                <input
                  id="rotate-input"
                  type="range"
                  value={rotate}
                  disabled={!imgSrc}
                  onChange={(e) =>
                    setRotate(
                      Math.min(180, Math.max(-360, Number(e.target.value)))
                    )
                  }
                />
              </div> */}
            </div>
            {!!imgSrc && (
              <>
                <h4>Crop Business Logo</h4>
                <ReactCrop
                  crop={crop}
                  onChange={(_, percentCrop) => {
                    // console.log("Crop image => ", percentCrop);
                    setCrop(percentCrop);
                  }}
                  onComplete={(c) => {
                    // console.log("Crop image---------- => ", c);

                    setCompletedCrop(c);
                  }}
                  aspect={aspect}
                >
                  <img
                    ref={imgRef}
                    alt="Crop me"
                    src={imgSrc}
                    width={400}
                    height={300}
                    style={{
                      transform: `scale(${scale}) rotate(${rotate}deg)`,
                    }}
                    onLoad={onImageLoad}
                  />
                </ReactCrop>
              </>
            )}
            {!!completedCrop && (
              <>
                <div>
                  <h4 className="mt-3">Preview</h4>
                  <div className="w-100">
                    <div
                      style={{
                        width: "320px",
                        height: "124px",
                        // display: "flex",
                        // justifyContent: "center",
                        // alignItems: "center",
                        margin: "auto",
                      }}
                    >
                      <canvas
                        ref={previewCanvasRef}
                        style={{
                          border: "1px solid black",
                          objectFit: "center",
                          width: "100%",
                          height: "100%",
                          margin: "auto",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}

            <input
              id="select-logo"
              type="file"
              accept="image/*"
              onChange={(e) => onSelectFile(e)}
            />
            {!!completedCrop && (
              <>
                <div className="m-auto">
                  <button
                    className="btn btn-indigo btn-file  upload-image-btn mt-4"
                    onClick={onDownloadCropClick}
                  >
                    Upload
                  </button>
                  <a
                    ref={hiddenAnchorRef}
                    download
                    style={{
                      position: "absolute",
                      top: "-200vh",
                      visibility: "hidden",
                    }}
                  >
                    Hidden download
                  </a>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Avatar;
