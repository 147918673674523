import React from "react";
import { NavLink } from "react-router-dom";
const Footer = () => {
  return (
    <div className="navbar navbar-expand-lg navbar-light">
      <div className="text-center d-lg-none w-100">
        <button
          type="button"
          className="navbar-toggler dropdown-toggle"
          data-toggle="collapse"
          data-target="#navbar-footer"
        >
          <i className="icon-unfold mr-2"></i>
          Footer
        </button>
      </div>

      <div className="navbar-collapse collapse" id="navbar-footer">
        <span className="navbar-text">
        <span>&copy; 2023 VidaVault</span>
        </span>

        <ul className="navbar-nav ml-lg-auto">
          <li className="nav-item">
            <NavLink
              style={{ textDecoration: "none", color: "white" }}
              to="/fha/support"
            >
              <a
                // href="FHA-support.html"
                className="navbar-nav-link"
                target="_blank"
              >
                <i className="fa-solid fa-life-ring mr-2"></i> Support
              </a>
            </NavLink>
          </li>
          {/* <li className="nav-item">
            <a
              href="FHA-documentation.html"
              className="navbar-nav-link"
              target="_blank"
            >
              <i className="fa-solid fa-file mr-2"></i> Documentation
            </a>
          </li> */}
          {window.location.pathname !== "/fha/register" && (
            <li className="nav-item">
              <NavLink
                style={{ textDecoration: "none", color: "white" }}
                to="/fha/register"
              >
                <a
                  // href="FHA-registration.html"
                  className="navbar-nav-link font-weight-semibold"
                >
                  <span className="text-pink mr-2">
                    <i className="fa-solid fa-user-plus"></i> Sign Up
                  </span>
                </a>
                t
              </NavLink>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default Footer;
