/** @format */

import React, { useEffect, useRef, useState } from "react";
import { NavLink, useLocation, useParams } from "react-router-dom";
// import '../../assets/css/reset-min.css'
import "../../assets/css/slick-min.css";
import "../../assets/css/styles.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import axios from "axios";
import { baseUrl } from "../../config";
import { useContext } from "react";
import { UserContext } from "../../UserProvider";
import default_logo from "../../assets/preview-logo.png";
import Cookies from "js-cookie";
import { BeatLoader } from "react-spinners";
import { useCallback } from "react";
const PreviewSlideshow = ({ contentParams, closeModal }) => {
  // console.log("contentParams Preview ==> ", contentParams);
  const { loggedIn, logUserIn, isLoading, setIsLoading, setMsg, setToast } =
    useContext(UserContext);
  const [data, setData] = useState(contentParams.fields);
  const [isSlideExisted, setIsSlideExisted] = useState([]);
  const [relationList, setRelationList] = useState([]);
  const location = useLocation();
  const params = useParams();
  const [isdata, setIsData] = useState(false);
  const [logo, setLogo] = useState(default_logo);
  const [Previewloader, setPreviewLoader] = useState(true);

  let user = Cookies.get("userData");
  const fha_id = user && JSON.parse(user).user?.fha_id;
  // const fha_id = JSON.parse(user)?.user?.fha_id;
  const [showerr, setshowErr] = useState(false);
  useEffect(() => {
    axios
      .get(`${baseUrl}/relations`)
      .then((res) => {
        setRelationList(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    const logo = localStorage.getItem("fha_logo");
    logo && setLogo(logo);
  }, []);
  //   useEffect(() => {
  //     document.querySelector("html").style.fontSize = "62.5%";
  //   }, []);
  const nav = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      setPreviewLoader(false);
    }, 2000);
  }, []);

  //   useEffect(() => {
  //     const slideshow_base = document.querySelector(".slideshow_base");
  //     // Ensure that the DOM has loaded before applying the parallax effect
  //     document.addEventListener(
  //       "scroll",
  //       function () {
  //         if (document.body.scrollTop > 0) {
  //           nav?.current?.classList?.add("_header__sticky");
  //         } else {
  //           nav?.current?.classList?.remove("_header__sticky");
  //         }
  //       },
  //       true
  //     );
  //     return () => {
  //       document.removeEventListener("scroll", function () {});
  //     };
  //   }, []);
  const [scrollm, setScrollM] = useState(true);
  useEffect(() => {
    const modalContent = document.querySelector(".slideshow_base");
    const handleScroll = () => {
      const scrollTop = modalContent.scrollTop;
      // console.log("scrollTop ==> ", scrollTop);
      if (scrollTop > 0) {
        nav?.current?.classList?.add("_header__sticky");
      } else {
        nav?.current?.classList?.remove("_header__sticky");
      }
    };
    window.addEventListener(
      "scroll",
      () => {
        setScrollM(!scrollm);
        // console.log("scroll triggers...");
      },
      true
    );
    modalContent?.addEventListener("scroll", handleScroll, true);
    return () => {
      modalContent?.removeEventListener("scroll", handleScroll, true);
    };
  }, [scrollm]);
  // useEffect(() => {
  //   const modalContent = slideshowBaseRef.current;

  //   if (modalContent) {
  //     // Your logic here to handle the scroll event
  //     // For example:
  //     const handleScroll = () => {
  //       const scrollTop = modalContent.scrollTop;
  //       if (scrollTop > 0) {
  //         // Apply your sticky header logic here
  //       }
  //     };

  //     modalContent.addEventListener("scroll", handleScroll);

  //     return () => {
  //       modalContent.removeEventListener("scroll", handleScroll);
  //     };
  //   }
  // }, [slideshowBaseRef, nav]);
  // calculate age
  function calculateAge(dob, dod) {
    const birthDate = new Date(dob);
    const deathDate = new Date(dod);
    const ageInMilliseconds = deathDate - birthDate;

    const days = ageInMilliseconds / (1000 * 60 * 60 * 24);
    const months = Math.floor(days / 30.44); // Approximate average number of days in a month
    const years = Math.floor(months / 12);
    const remainingMonths = months % 12;
    const remainingDays = Math.floor(days % 30.44);

    if (years === 0 && months === 0 && remainingDays === 1) {
      return ` They were 1 day old.`;
    } else if (years === 0 && months === 1 && remainingDays === 0) {
      return ` They were 1 month old.`;
    } else if (years === 1 && months === 0 && remainingDays === 0) {
      return ` They were 1 year old.`;
    } else if (years === 0 && months === 0) {
      return ` They were ${Math.floor(days)} days old.`;
    } else if (years === 0 && months > 0) {
      return ` They were ${years} years and ${remainingMonths} months old.`;
    } else {
      return ` They were ${years} years and ${remainingMonths} months old.`;
    }
  }

  const handleDebounceImage = useCallback((file) => {
    return (
      <>
        try{" "}
        {
          <video
            // width="100%"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
            height="95%"
            controls
            name="Video Name"
          >
            <source
              // width="100%"
              // height="95%"
              src={file?.dataUri}
              type="video/mp4"
            />
          </video>
        }{" "}
        catch (error) {console.log("Error ==> ")}
      </>
    );
  }, []);

  return (
    <>
      {/* {data?.files !== undefined && ( */}

      {Previewloader ? (
        <>
          <div className="Preview_loader">
            <h2
              className="text-center"
              style={{
                fontSize: "1.5rem",
                color: "#555",
                marginTop: "10px",
              }}
            >
              <BeatLoader color="#3f51b5" size={18} />
              Generating your preview slideshow...
            </h2>
          </div>
        </>
      ) : (
        <>
          <div className="Preview_slider">
            <div
              className="div"
              style={{
                fontWeight: "bold",
                fontSize: "19px",
                marginLeft: "95px",
              }}
            >
              Preview Mode
            </div>
            <div className="div">
              <span>
                Caution: Using the browser's back button may not save your
                slides. Use 'Close Preview' button
              </span>
            </div>

            <div className="div" title="Close Preview">
              <span
                className="btn-close-modal_preview text-right "
                onClick={closeModal}
              >
                &times;
              </span>
            </div>
          </div>
          {/* <hr /> */}
          <div
            className="slideshow_base slideshow_base_preview"
            style={{ height: "100%", fontSize: "20px !important" }}
          >
            <a name="memories"></a>
            <header className="_header ">
              <nav ref={nav} className="_header__nav_preview" id="navbar">
                <div className="_fh-logo"></div>
                <ul className="_header__list">
                  <li className="_header__item">
                    <a href="#memories" className="_header__link_preview">
                      Memories
                    </a>
                  </li>
                  <li className="_header__item">
                    <a href="#obituary" className="_header__link_preview">
                      Obituary
                    </a>
                  </li>
                  <li className="_header__item">
                    <a href="#tree" className="_header__link_preview">
                      Family Tree
                    </a>
                  </li>
                  <li className="_header__item">
                    <a
                      // href="#vv"
                      href="https://vidavault.net/"
                      target="_blank"
                      className="_header__link_preview"
                    >
                      VidaVault
                    </a>
                  </li>
                  {/* <li>
                <span
                  className="btn-close-modal text-right close-modal-blog-2"
                  onClick={closeModal}
                >
                  &times;
                </span>
              </li> */}
                </ul>

                <div className="_navigation">
                  <input
                    type="checkbox"
                    className="_navigation__checkbox"
                    id="navi-toggle"
                  />
                  <label for="navi-toggle" className="_navigation__button">
                    <span className="_navigation__icon"></span>
                  </label>
                  <div className="_navigation__background"></div>
                  <nav className="_navigation__nav">
                    <ul className="_navigation__list">
                      <li className="_navigation__item">
                        <a href="#memories" className="_navigation__link">
                          Memories
                        </a>
                      </li>
                      <li className="_navigation__item">
                        <a href="#obituary" className="_navigation__link">
                          Obituary
                        </a>
                      </li>
                      <li className="_navigation__item">
                        <a href="#tree" className="_navigation__link">
                          Family Tree
                        </a>
                      </li>
                      <li className="_navigation__item">
                        <a
                          href="#vv"
                          target="_blank"
                          className="_navigation__link"
                        >
                          VidaVault
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </nav>
            </header>

            <section className="_main-slider-preview">
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Swiper
                  allowTouchMove={false}
                  navigation={true}
                  modules={[Autoplay, Pagination, Navigation]}
                  slidesPerView={1}
                  loop={true}
                  pagination={{
                    clickable: true,
                  }}
                  style={{ height: "100%", width: "100%" }}
                  onSlideChange={() => {}}
                  lazy={true}
                >
                  {data?.files?.length > 0 ? (
                    data?.files.map((file) => {
                      // console.log("file?.dataUri ==> ", file);
                      try {
                        return (
                          <SwiperSlide>
                            {file.type.split("/")[0] == "application" && (
                              <>
                                {/* <iframe
                                  width="100%"
                                  height="95%"
                                  src={file?.dataUri}
                                  title="YouTube video player"
                                  frameborder="0"
                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                  allowfullscreen
                                ></iframe> */}
                                {/*  */}
                                {handleDebounceImage(file)}
                              </>
                            )}

                            {file.type.split("/")[0] == "video" &&
                              file.type.split("/")[1] != "link" && (
                                <>
                                  {/* <iframe
                                    width="100%"
                                    height="95%"
                                    src={file?.dataUri}
                                    title="YouTube video player"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowfullscreen
                                  ></iframe> */}

                                  {/* <video
                                    // width="100%"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowFullScreen
                                    height="95%"
                                    controls
                                    name="Video Name"
                                  >
                                    <source
                                      // width="100%"
                                      // height="95%"
                                      src={file?.dataUri}
                                      type="video/mp4"
                                    />
                                  </video> */}

                                  {handleDebounceImage(file)}
                                </>
                              )}

                            {file?.type.split("/")[0] == "video" &&
                              file?.type.split("/")[1] == "link" && (
                                <iframe
                                  height="95%"
                                  width="100%"
                                  src={
                                    file?.dataUri.match(".be/")
                                      ? "https://www.youtube.com/embed/" +
                                        file?.dataUri.split(".be/")[1]
                                      : "https://player.vimeo.com/video/" +
                                        file?.dataUri.split("m/")[1]
                                  }
                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                  title={file?.dataUri}
                                  allowFullScreen
                                ></iframe>
                              )}
                            {file?.type.split("/")[0] == "image" && (
                              <>
                                <img
                                  style={{ height: "100%", width: "100%" }}
                                  src={file?.dataUri}
                                  alt="slide-image"
                                />
                              </>
                            )}
                            {}
                          </SwiperSlide>
                        );
                      } catch (error) {
                        console.log(
                          "esizeObserver loop completed with undelivered notifications ----> ",
                          error
                        );
                      }
                    })
                  ) : (
                    <SwiperSlide>
                      <div className="h-100 d-flex justify-content-center">
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{ fontSize: "5em" }}
                        >
                          <span style={{ color: "#c2c2c2" }}>
                            Slide not available
                          </span>
                        </div>
                      </div>
                    </SwiperSlide>
                  )}
                </Swiper>
              </div>
            </section>
            <a name="obituary"></a>
            <section className="_container">
              <div className="_content_preview">
                <h2>
                  {data?.personalDetails?.firstName + " "}
                  {data?.personalDetails?.lastName}
                </h2>
                <h3>
                  {data?.personalDetails?.dob?.split("-")[0]} -
                  {data?.personalDetails?.dod?.split("-")[0]}
                </h3>
                <p style={{ color: "#333" }}>
                  {data?.personalDetails?.firstName + " "}
                  {data.personalDetails?.lastName} of
                  {" " + data?.personalDetails?.deathCity},
                  {" " + data?.personalDetails?.deathState + " "}
                  died on
                  {" " +
                    new Date(data.personalDetails?.dod).toLocaleDateString(
                      "en-US",
                      {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      }
                    )}
                  .
                  {data?.personalDetails?.dod &&
                    calculateAge(
                      data?.personalDetails?.dob,
                      data?.personalDetails?.dod
                    )}
                </p>
                <p style={{ color: "#333" }}>
                  {data?.personalDetails?.obituryText}
                </p>
              </div>
            </section>

            <a name="tree"></a>
            <section className="_container _dark">
              <div className="_content_preview">
                <h2>Family Tree</h2>

                <ul className="_tree">
                  <li>
                    <p>
                      <span>Mother :</span>
                      {data?.familyDetails &&
                      data?.familyDetails?.motherName ? (
                        <span id="spancustom">
                          {data?.familyDetails?.motherName}{" "}
                        </span>
                      ) : (
                        <span id="spancustom"> Not Available</span>
                      )}
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>Father :</span>
                      {data?.familyDetails &&
                      data?.familyDetails?.fatherName ? (
                        <span id="spancustom">
                          {data?.familyDetails?.fatherName}
                        </span>
                      ) : (
                        <span id="spancustom">{"Not Available"}</span>
                      )}
                    </p>
                  </li>

                  {/* {data?.familyDetails?.relatives?.map((relative) => {
                console.log("Relative ==> ", relative);
                return (
                  <li>
                    <p>
                      <span>
                        {relationList.find(
                          (obj) => obj.rm_id === +relative?.relationId
                        )?.rm_desc + " :"}
                      </span>
                      {relative.slideshowURL == null ||
                      relative.slideshowURL === "" ? (
                        <span id="spancustom"> {relative?.name} </span>
                      ) : (
                        <a href={relative?.slideshowURL} target="_blank">
                          {relative?.existingName
                            ? relative?.existingName
                            : relative?.name}
                        </a>
                      )}
                    </p>
                  </li>
                );
              })} */}
                  {data?.familyDetails?.relatives
                    ?.filter((relative) => relative?.relationId !== undefined)
                    .map((relative) => {
                      const relation = relationList.find(
                        (obj) => obj.rm_id === +relative?.relationId
                      );
                      // Check if relation is found before accessing its properties
                      const relationDesc = relation
                        ? relation.rm_desc + " : "
                        : "";
                      return (
                        <li key={relative.id}>
                          <p>
                            <span>{relationDesc}</span>
                            {relative?.relationId && // Check if relationId is defined
                              (relative.slideshowURL == null ||
                              relative.slideshowURL === "" ? (
                                <span id="spancustom">{relative?.name}</span>
                              ) : (
                                <a
                                  href={relative?.slideshowURL}
                                  target="_blank"
                                >
                                  {relative?.existingName
                                    ? relative?.existingName
                                    : relative?.name}
                                </a>
                              ))}
                          </p>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </section>

            <div className="_container1">
              <div className="_content1">
                <img
                  src={logo}
                  title="Business logo"
                  style={{ height: "120px", width: "auto" }}
                  alt="footer logo"
                />
              </div>
            </div>
          </div>
          {/* )} */}
          {data?.length === 0 ||
            (data?.files == undefined && showerr && (
              <div className="page-not-found">
                <h1>The slideshow you're requesting no longer exists.</h1>
              </div>
            ))}
        </>
      )}
    </>
  );
};

export default PreviewSlideshow;
