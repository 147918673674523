import React from "react";

const Confirmation = ({
  popupText,
  popupTrigger,
  canceled,
  confirmed,
  popupTitle,
}) => {
  return (
    <>
      {popupTrigger && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            width: "100vw",
            zIndex: "4000",
            backdropFilter: "brightness(90%) blur(4px)",
          }}
          className="modal"
        >
          <div
            style={{
              display: "flex",
              width: "40%",
            }}
            className="modal-content"
          >
            <div
              class="modal-header"
              style={{
                paddingBottom: "1rem",
              }}
            >
              <h5
                class="modal-title"
                style={{ fontWeight: "semiBold", fontSize: "1.2rem" }}
                id="exampleModalLongTitle"
              >
                {popupTitle}
              </h5>
            </div>

            <div className="modal-body">{popupText}</div>
            <div className="modal-footer" style={{ paddingTop: "1rem" }}>
              <button
                type="button"
                className="btn btn-link"
                data-bs-dismiss="modal"
                onClick={canceled}
              >
                Cancel
              </button>
              <button
                onClick={confirmed}
                type="button"
                className="btn btn-primary"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Confirmation;
