import React, { useState } from "react";
import axios from "axios";
import { adminToken, baseUrl } from "../config";
import { useContext } from "react";
import { UserContext } from "../UserProvider";
const Sendmsg = ({ msgTrigger, setMsgTrigger, reciever, setReciever }) => {
  const { setIsLoading, setMsg, setToast } = useContext(UserContext);
  const [text, setText] = useState("");
  const handleSendResponse = () => {
    if (text.trim() == "") {
      setMsg({
        type: "error",
        text: `Cannot send empty message`,
      });
      setToast(true);
      setIsLoading(false);
    } else {
      setToast(false);
      setMsg({ text: "", type: "" });
      setIsLoading(true);
      setText("");
      setMsgTrigger(false);
      setReciever();
      axios
        .post(
          `${baseUrl}/msg/send`,
          {
            tm_fha_id: reciever.fha_id,
            tm_msg: text.trim(),
          },
          {
            headers: {
              Authorization: `Bearer ${adminToken}`,
            },
          }
        )
        .then((res) => {
          setMsg({
            type: "success",
            text: `Message sent successfully`,
          });
          setToast(true);
          setIsLoading(false);
        })
        .catch((err) => {
          setMsg({
            type: "error",
            text: `Failed to send the message`,
          });
          setToast(true);
          setIsLoading(false);
        });
    }
  };
  return (
    <>
      {msgTrigger && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            width: "100vw",
            zIndex: "4000",
            backdropFilter: "brightness(90%) blur(4px)",
          }}
          className="modal"
        >
          <div
            style={{
              display: "flex",
              width: "40%",
              border: "1px solid #BCC2C8",
              padding: "1.5rem",
            }}
            className="modal-content"
          >
            <div
              style={{
                fontSize: "1.3rem",
                fontWeight: "semibold",
                marginBottom: "0.5rem",
              }}
            >
              {reciever?.fha_business_name}
            </div>
            <div
              style={{
                display: "flex",
                flexBasis: "100%",
                alignItems: "center",
              }}
            >
              <div
                className="form-group form-group-floating"
                style={{ width: "100%" }}
              >
                <div className="position-relative" style={{ width: "100%" }}>
                  <textarea
                    className="form-control form-control-outline"
                    placeholder="Placeholder"
                    onChange={(e) => setText(e.target.value)}
                    value={text}
                    name="personalDetails_obituryText"
                    rows="7"
                    style={{ width: "100%" }}
                  ></textarea>
                  <label className="label-floating">Message</label>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexBasis: "100%",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <div
                onClick={() => {
                  setMsgTrigger(false);
                  setText();
                }}
                style={{ marginRight: "1rem" }}
                className="btn btn-border btn-danger"
              >
                Cancel
              </div>
              <div onClick={handleSendResponse} className="btn btn-teal">
                Send Message
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Sendmsg;
