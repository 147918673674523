import React, { useState } from "react";

const MessageComponent = ({ message }) => {
  const [expanded, setExpanded] = useState(false);
  const toggleExpand = () => {
    setExpanded(!expanded);
  };
  return (
    <li
      className="list-group-item list-group-item-action"
      onClick={toggleExpand}
      style={{ border: "1px solid #dee2e6" }}
    >
      <div
        style={{ flexDirection: "column" }}
        className="d-flex justify-content-between align-items-start"
      >
        <p className="mb-0">
          {expanded || message.sts_responded_msg?.length < 120 ? (
            <div>{message.sts_responded_msg}</div>
          ) : (
            <div className="d-flex">
              {message.sts_responded_msg?.slice(0, 120)}
              <div
                className="text-primary"
                style={{ cursor: "pointer", fontWeight: "900" }}
              >
                ...
              </div>
            </div>
          )}
        </p>
        <p className="text-muted mb-0">
          {/* {message.sts_created_at} */}
          {new Date(message.sts_created_at).toLocaleString("en-US", {
            timeZone: "UTC",
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          })}
        </p>
      </div>
    </li>
  );
};

export default MessageComponent;
