import React, { useState, useEffect } from "react";
import "./text-rotator.css";

const TextRotator = () => {
  const words = ["Digital Memory Vault", "For a Life Remembered"];
  const [position, setPosition] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setPosition((prevPosition) =>
        prevPosition === words.length - 1 ? 0 : prevPosition + 1
      );
    }, 4500);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="text-rotator">
      {words.map((word, index) => (
        <span key={index} className={index === position ? "active" : ""}>
          {word}
        </span>
      ))}
    </div>
  );
};

export default TextRotator;
