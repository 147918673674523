/** @format */

import React from "react";
import { Helmet } from "react-helmet";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

const SignupTerms = () => {
  return (
    <>
      <div style={{ height: "100vh" }}>
        <Helmet>
          <title>VidaVault</title>
        </Helmet>
        <Navbar />
        <div style={{ height: "85%" }} className="page-content">
          <div className="content-wrapper">
            <div className="content-inner">
              <div className="content d-flex justify-content-center align-items-center"></div>
              <div className="mx-5">
                <h3 className="text-center text-capitalize">
                  Terms Of Service
                </h3>
                <br />
                <span className="font-weight-bold mt-3"> Last Revised:</span>
                &nbsp; February 2,2023
                <br />
                <div>
                  Welcome to VidaVault. This Site is provided as a service to
                  our visitors and may be used for informational purposes only.
                  Because the Terms and Conditions contain legal obligations,
                  please read them carefully.
                </div>
                <br />
                <div>
                  <span className="font-weight-bold mt-3">
                    1. Your Agreement
                  </span>
                  <br />
                  By using this Site, you agree to be bound by, and to comply
                  with, these Terms of Service. If you do not agree to these
                  Terms of Service, please do not use this site.
                  <br />
                </div>
                <div className="mt-3">
                  <span className="font-weight-bold">2. Privacy</span>
                  <br />
                  Please review our Privacy Policy, which also governs your
                  visit to this Site, to understand our practices.
                </div>
                <div className="mt-2">
                  <span className="font-weight-bold">
                    3. Forward-Looking Statements
                  </span>
                  <br />
                  All materials reproduced on this site speak as of the original
                  date of publication or filing. The fact that a document is
                  available on this site does not mean that the information
                  contained in such document has not been modified or superseded
                  by events or by a subsequent document or filing. We have no
                  duty or policy to update any information or statements
                  contained on this site, and therefore, such information or
                  statements should not be relied upon as being current as of
                  the date you access this site.
                </div>
                <div className="mt-3">
                  <span className="font-weight-bold">
                    4. Disclaimer of Warranties and Limitation of Liability
                  </span>
                  <br />
                  <span className="pl-2">
                    A. This Site may contain inaccuracies and typographical
                    errors. We do not warrant the accuracy or completeness of
                    the materials or the reliability of any advice, opinion,
                    statement, or other information displayed or distributed
                    through the Site.
                  </span>{" "}
                  <br />
                  <span className="pl-2">
                    B. You understand and agree that under no circumstances,
                    including, but not limited to, negligence, shall we be
                    liable for any direct, indirect, incidental, special,
                    punitive, or consequential damages that result from the use
                    of, or the inability to use, any of our Sites or materials
                    or functions on any such Site, even if we have been advised
                    of the possibility of such damages.
                  </span>
                </div>
                <div className="mt-2">
                  <span className="font-weight-bold">
                    5. Exclusions and Limitations
                  </span>
                  <br />
                  Some jurisdictions do not allow the exclusion of certain
                  warranties or the limitation or exclusion of liability for
                  incidental or consequential damages. Accordingly, our
                  liability in such jurisdiction shall be limited to the maximum
                  extent permitted by law.
                  <br />
                </div>
                <div className="mt-2">
                  <span className="font-weight-bold">
                    6. Our Proprietary Rights
                  </span>
                  <br />
                  This Site and all its Contents are intended solely for
                  personal, non-commercial use. Except as expressly provided,
                  nothing within the Site shall be construed as conferring any
                  license under our or any third party's intellectual property
                  rights.
                </div>
                <div className="mt-3">
                  <span className="font-weight-bold">7. Indemnity</span>
                  <br />
                  By using the Site websites, you agree to indemnify us and
                  affiliated entities ("Indemnities") and hold them harmless
                  from any and all claims and expenses, including attorney's
                  fees, arising from your use of the Site websites, your use of
                  the Products and Services, or your submission of ideas and/or
                  related materials to us.
                  <br />
                  The framing, mirroring, scraping or data mining of the Site or
                  any of its content in any form and by any method is expressly
                  prohibited.
                </div>
                <div className="mt-3">
                  <span className="font-weight-bold">
                    {" "}
                    8. Copyright and Trademark Notice
                  </span>
                  <br />
                  Except our generated dummy copy, which is free to use for
                  private and commercial use, all other text is copyrighted.
                  VidaVault © 2023, all rights reserved.
                </div>
                <div className="mt-3">
                  <span className="font-weight-bold">
                    9. Intellectual Property Infringement Claims
                  </span>
                  <br />
                  It is our policy to respond expeditiously to claims of
                  intellectual property infringement. Notices of claimed
                  infringement should be directed to:
                  <br />
                  <a href="mailto:accounts@vidavault.net">
                    accounts@vidavault.net
                  </a>
                </div>
                <div className="mt-3">
                  <span className="font-weight-bold">
                    10. Place of Performance
                  </span>
                  <br />
                  This Site is controlled, operated, and administered by us from
                  our office in [Location]. If you access this Site from a
                  location outside of [Location], you are responsible for
                  compliance with all local laws.
                </div>
                <div className="mt-3">
                  <span className="font-weight-bold">11. General</span>
                  <br />
                  <span className="pl-2">
                    A. If any provision of these Terms of Service is held to be
                    invalid or unenforceable, the provision shall be removed (or
                    interpreted, if possible, in a manner as to be enforceable),
                    and the remaining provisions shall be enforced.
                  </span>
                  <br />
                  <span className="pl-2">
                    B. No Joint Venture, No Derogation of Rights. You agree that
                    no joint venture, partnership, employment, or agency
                    relationship exists between you and us as a result of these
                    Terms of Service or your use of the Site. Please note that
                    you should replace VidaVault, 11-02-2023, [Location], and
                    <a
                      href="mailto:accounts@vidavault.net"
                      className="mr-1 ml-1"
                    >
                      accounts@vidavault.net
                    </a>
                    with your specific website information. Additionally,
                    consult with a legal professional to ensure that your Terms
                    of Service agreement complies with all relevant laws and
                    regulations.
                  </span>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignupTerms;
