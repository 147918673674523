/** @format */

import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import Subnav from "../../components/Subnav";
import Footer from "../../components/Footer";
import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import { baseUrl } from "../../config";
import { UserContext } from "../../UserProvider";
import logo from "../../assets/add_business_logo.png";
import Avatar from "../../components/image-upload/Avatar";
import default_logo from "../../assets/add_business_logo.png";
const MyAccount = () => {
  const [valid, setValid] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState(default_logo);
  const [logoFile, setLogoFile] = useState(null);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  let data = Cookies.get("userData");
  const nameRegex = /^[a-zA-Z]{1,}['-]?[a-zA-Z]+$/gm;
  const LastnameRegex = /^[a-zA-Z]{1,}['-]?[a-zA-Z]+$/gm;
  var emailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  const { loggedIn, logUserIn, setMsg, setToast, setIsLoading } =
    useContext(UserContext);
  data = data && JSON.parse(data);
  // console.log("User Data context ==> ", data.user);
  const [registerSuccess, setRegisterSuccess] = useState(false);
  const [userData, setUserData] = useState({
    businessName: "",
    userName: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    zipCode: "",
    comment: "",
  });
  const [editable, setEditable] = useState({
    firstName: true,
    lastName: true,
    email: true,
    phone: true,
    address: true,
    address2: true,
    city: true,
    state: true,
    zipCode: true,
    comment: true,
  });

  // console.log("User Data ===> ", loggedIn?.token);

  const getUserDetail = async () => {
    try {
      setIsLoading(true);
      await axios
        .get(`${baseUrl}/users/${data?.user?.fha_id}`, {
          headers: {
            Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c`,
          },
        })
        .then((response) => {
          // console.log(response?.data?.data);
          let details = response?.data?.data;
          // console.log("Details ==> ", details);
          // Cookies.set("userData", JSON.stringify(details));
          setUserData({
            businessName: details?.fha_business_name,
            userName: details?.fha_username,
            firstName: details?.fha_firstname,
            lastName: details?.fha_lastname,
            email: details?.fha_email,
            phone: details?.fha_contact,
            address: details?.fha_address1,
            address2: details?.fha_address2,
            city: details?.fha_city,
            state: details?.fha_state,
            zipCode: details?.fha_zipcode,
            comment: details?.fha_comments,
          });
          let logo = details?.fha_logo ?? default_logo;
          setBackgroundImage(logo);
          // console.log("FHA LOGO ==> ", details?.fha_logo);
          if (details?.fha_logo?.startsWith("http")) {
            localStorage.setItem("fha_logo", details?.fha_logo);
          }
          // console.log("user data ==> ", userData);
        });
      setIsLoading(false);
    } catch (error) {
      console.log("Error ==> ", error);
      if (error?.response?.data?.resultMsg === "User is inactive") {
        setMsg({
          type: "error",
          text: "The Super Admin has deactivated your account. Contact super admin for details.",
        });
        setToast(true);

        setTimeout(() => {
          Cookies.remove("userData");
          localStorage.removeItem("fha_logo");
          logUserIn(false);

          navigate("/");
        }, 2000);
      } else {
        setMsg({
          type: "error",
          text: "Error occurred while get account details",
        });
        setToast(true);
      }
      setValid(false);
      setIsLoading(false);
    }
  };

  // console.log(typeof backgroundImage, backgroundImage);
  const submitData = async () => {
    setValid(false);
    if (!userData?.firstName?.match(nameRegex)) {
      setMsg({ type: "error", text: "First name is not valid" });
      setToast(true);
      setValid(false);
    } else if (!userData?.lastName?.match(LastnameRegex)) {
      setMsg({ type: "error", text: "Last name is not valid" });
      setToast(true);
      setValid(false);
    } else if (!userData?.email?.match(emailRegex)) {
      setMsg({ type: "error", text: "Email is not valid" });
      setToast(true);
      setValid(false);
    } else if (userData?.phone?.length !== 10) {
      setMsg({ type: "error", text: "Phone length must be 10" });
      setToast(true);
      setValid(false);
    } else if (
      userData?.address?.trim()?.length < 2 ||
      userData?.address?.trim()?.length > 50
    ) {
      setMsg({
        type: "error",
        text: "Address text should be atleast 2 and atmost 50 characters long",
      });
      setToast(true);
      setValid(false);
    } else if (
      // userData?.address2?.trim()?.length  ||
      userData?.address2?.trim()?.length > 50
    ) {
      setMsg({
        type: "error",
        text: "Suite, Unit text should be less than 25 characters long",
      });
      setToast(true);
      setValid(false);
    } else if (
      userData?.city?.trim()?.length < 2 ||
      userData?.city?.trim()?.length > 25
    ) {
      setMsg({
        type: "error",
        text: "City text should be atleast 2 and atmost 25 characters long",
      });
      setToast(true);
      setValid(false);
    } else if (userData?.zipCode?.trim()?.length !== 5) {
      setMsg({
        type: "error",
        text: "Zip code length must be 5",
      });
      setToast(true);
      setValid(false);
    } else if (
      // userData?.comment?.trim()?.length < 4 ||
      userData?.comment?.trim()?.length > 200
    ) {
      setMsg({
        type: "error",
        text: "Comment or questions text should be atleast 4 and atmost 200 characters long",
      });
      setToast(true);
      setValid(false);
    } else {
      setValid(true);
    }

    if (valid) {
      console.log("Valid ", valid);
      // Create a new FormData object
      const formData = new FormData();
      // Append the fields from your payload to the FormData object
      formData.append("fha_business_name", userData?.businessName);
      formData.append("fha_username", userData?.userName);
      formData.append("fha_firstname", userData?.firstName);
      formData.append("fha_lastname", userData?.lastName);
      formData.append("fha_email", userData?.email);
      formData.append("fha_contact", userData?.phone);
      formData.append("fha_address1", userData?.address);
      formData.append("fha_address2", userData?.address2);
      formData.append("fha_city", userData?.city);
      formData.append("fha_state", userData?.state);
      formData.append("fha_zipcode", userData?.zipCode);
      formData.append("fha_comments", userData?.comment);
      logoFile instanceof Blob &&
        formData.append("logo", logoFile, "cropped-image.jpg");
      backgroundImage?.startsWith("/static/media") !== true &&
        formData.append("url", backgroundImage);
      try {
        setIsLoading(true);
        await axios.patch(
          `${baseUrl}/users/updateaccount/${data?.user?.fha_id}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${loggedIn.token}`,
            },
          }
        );
        setMsg({
          type: "success",
          text: `Account details updated successfully`,
        });
        getUserDetail();
        setToast(true);
        setValid(false);
        setIsLoading(false);
      } catch (error) {
        console.log("Error ==> ", error);
        if (error?.response?.data?.resultMsg === "User is inactive") {
          setMsg({
            type: "error",
            text: "The Super Admin has deactivated your account. Contact super admin for details.",
          });
          setToast(true);
          setTimeout(() => {
            Cookies.remove("userData");
            localStorage.removeItem("fha_logo");
            logUserIn(false);
            navigate("/");
          }, 2000);
        } else if (error?.response?.data?.message === "Email already exist") {
          setMsg({
            type: "error",
            text: error?.response?.data?.message,
          });
        } else if (
          error.response?.data?.message === "Phone number already exist"
        ) {
          setMsg({
            type: "error",
            text: error?.response?.data?.message,
          });
        } else {
          setMsg({
            type: "error",
            text: "Error occurred while updating account details, Please try again",
          });
        }

        setToast(true);
        setValid(false);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    getUserDetail();
  }, []);
  // console.log("User data => ", userData);

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;

        img.onload = () => {
          console.log(
            "Img width ==> ",
            img.width,
            " Img height ==> ",
            img.height
          );
          if (img.width === 320 && img.height === 124) {
            // Convert the image data to a Blob
            const canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, img.width, img.height);
            canvas.toBlob((blob) => {
              console.log("typeof blob ==> ", typeof blob);
              setBackgroundImage(e.target.result);
              setLogoFile(blob); // Set the Blob as the logo file
              setError("");
            }, file.type);
          } else {
            console.log("Image must be 320x124 pixels.");
            setError("File size should be 320px width and 124px height.");
          }
        };
      };

      reader.readAsDataURL(file);
    }
    // console.log("Image ==> ", typeof logoFile);
  };

  return (
    <div style={{ height: "100vh" }}>
      <Helmet>
        <title>VidaVault</title>
      </Helmet>
      <Navbar />
      <div style={{ height: "85%" }} className="page-content">
        <Sidebar />
        <div className="content-wrapper">
          <div className="content-inner">
            {/* <Subnav /> */}
            {/* <div className="navbar navbar-expand-lg navbar-light sticky-top"> */}
            {/* <div className="text-center d-lg-none w-100">
                <button
                  type="button"
                  className="navbar-toggler dropdown-toggle"
                  data-toggle="collapse"
                  data-target="#navbar-second"
                >
                  <i className="icon-unfold mr-2"></i>
                  Open Task Menu
                </button>
              </div> */}
            <Subnav />
            {/* </div> */}
            <div style={{ height: "85%" }} className="page-content">
              <div className="content-wrapper">
                <div className="content-inner">
                  <div className="content d-flex justify-content-center align-items-center">
                    <form
                      // ref={registerFormRef}
                      // onSubmit={submitData}
                      className="flex-fill"
                    >
                      <div className="row">
                        <div className="col-lg-6 offset-lg-3">
                          <div className="card mb-0">
                            <div className="card-body">
                              <div className="text-center mb-3">
                                {/* <i className="fa-solid fa-circle-plus text-success large-icon mb-3 mt-1"></i> */}
                                <h3 className="mb-1 font-weight-bold">
                                  My Account
                                </h3>
                                {/* <span className="d-block text-muted mt-3">
                                    A VidaVault team member will be in touch
                                    within 1-2 business days to confirm your
                                    account details.
                                  </span> */}
                              </div>
                              <div className="d-flex justify-content-center">
                                <div
                                  style={{
                                    border: " 1px solid rgb(128 128 128 / 42%)",
                                  }}
                                >
                                  <div
                                    className="profile-icon"
                                    style={{
                                      backgroundImage: `url(${backgroundImage})`,
                                      width: "320px",
                                      height: "124px",
                                      backgroundSize: "cover",
                                      backgroundPosition: "center",
                                      // borderRadius: "50%",
                                    }}
                                    onClick={() =>
                                      document
                                        .getElementById("fileInput")
                                        .click()
                                    }
                                  >
                                    <input
                                      type="file"
                                      id="fileInput"
                                      style={{ display: "none" }}
                                      onChange={handleImageChange}
                                    />
                                  </div>
                                </div>

                                {/* <Avatar
                                  backgroundImage={backgroundImage}
                                  setBackgroundImage={setBackgroundImage}
                                  setLogoFile={setLogoFile}
                                /> */}
                              </div>
                              {error && (
                                <p
                                  style={{ color: "red", textAlign: "center" }}
                                >
                                  {error}
                                </p>
                              )}
                              <p
                                className="text-muted text-center mt-1"
                                style={{ fontSize: "13px" }}
                              >
                                Business logo dimensions must be within 320px
                                width and 124px height.
                              </p>
                              <div className="row mt-4">
                                <div className="col-lg-6">
                                  <div className="form-group form-group-floating row">
                                    <div className="col-lg-12">
                                      <div className="position-relative">
                                        <input
                                          type="text"
                                          className="form-control form-control-outline"
                                          placeholder="Bussiness Name"
                                          value={userData.businessName}
                                          minLength={4}
                                          maxLength={50}
                                          //   onChange={(e) =>
                                          //     setBussinessname(e.target.value)
                                          //   }
                                          required
                                          disabled
                                        />

                                        <label className="label-floating">
                                          Business Name
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="form-group form-group-floating row">
                                    <div className="col-lg-12">
                                      <div className="position-relative">
                                        <input
                                          type="text"
                                          className="form-control form-control-outline"
                                          placeholder="Placeholder"
                                          value={userData.userName}
                                          minLength={4}
                                          maxLength={25}
                                          pattern="^(?!.*[_\.]{2})[a-zA-Z0-9]+([_.]?[a-zA-Z0-9]+)*$"
                                          //   onChange={(e) =>
                                          //     setUsername(e.target.value)
                                          //   }
                                          required
                                          disabled
                                        />
                                        <label className="label-floating">
                                          Username
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="form-group form-group-floating row">
                                    <div className="col-lg-12">
                                      <div className="position-relative">
                                        <input
                                          type="text"
                                          className="form-control form-control-outline my-acc-input-field"
                                          placeholder="Placeholder"
                                          value={userData.firstName}
                                          minLength={2}
                                          maxLength={20}
                                          onChange={(e) =>
                                            setUserData({
                                              ...userData,
                                              firstName: e.target.value,
                                            })
                                          }
                                          required
                                          // autoFocus={editable.firstName===true}
                                          disabled={
                                            editable.firstName ? true : false
                                          }
                                        />
                                        <label className="label-floating">
                                          First Name
                                        </label>
                                        {editable.firstName === true && (
                                          <i
                                            className="fa-light fa-pen-to-square fa-xl edit-field"
                                            onClick={() =>
                                              setEditable({
                                                ...editable,
                                                firstName: !editable,
                                              })
                                            }
                                          ></i>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-lg-6">
                                  <div className="form-group form-group-floating row">
                                    <div className="col-lg-12">
                                      <div className="position-relative">
                                        <input
                                          type="text"
                                          className="form-control form-control-outline my-acc-input-field"
                                          placeholder="Placeholder"
                                          value={userData?.lastName}
                                          // value={userData.user.fha_lastname}
                                          minLength={2}
                                          maxLength={20}
                                          onChange={(e) =>
                                            setUserData({
                                              ...userData,
                                              lastName: e.target.value,
                                            })
                                          }
                                          required
                                          disabled={
                                            editable.lastName ? true : false
                                          }
                                        />
                                        <label className="label-floating">
                                          Last Name
                                        </label>
                                        {editable.lastName === true && (
                                          <i
                                            className="fa-light fa-pen-to-square fa-xl edit-field"
                                            onClick={() =>
                                              setEditable({
                                                ...editable,
                                                lastName: !editable,
                                              })
                                            }
                                          ></i>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                {/* <div className="col-lg-6">
                                    <div className="form-group form-group-floating row">
                                      <div className="col-lg-12">
                                        <div className="position-relative">
                                          <input
                                            type="password"
                                            className="form-control form-control-outline"
                                            placeholder="Placeholder"
                                            //   value={password}
                                            //   onChange={(e) =>
                                            //     setPassword(e.target.value)
                                            //   }
                                            minLength={6}
                                            maxLength={30}
                                            required
                                          />
                                          <label className="label-floating">
                                            Create Password
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div> */}

                                {/* <div className="col-lg-6">
                                    <div className="form-group form-group-floating row">
                                      <div className="col-lg-12">
                                        <div className="position-relative">
                                          <input
                                            type="password"
                                            className="form-control form-control-outline"
                                            placeholder="Placeholder"
                                            //   value={repeatPassword}
                                            //   onChange={(e) =>
                                            //     setRepeatPassword(e.target.value)
                                            //   }
                                            minLength={6}
                                            maxLength={30}
                                            required
                                          />
                                          <label className="label-floating">
                                            Repeat Password
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div> */}
                              </div>

                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="form-group form-group-floating row">
                                    <div className="col-lg-12">
                                      <div className="position-relative">
                                        <input
                                          type="email"
                                          className="form-control form-control-outline my-acc-input-field"
                                          placeholder="Placeholder"
                                          value={userData?.email}
                                          onChange={(e) =>
                                            setUserData({
                                              ...userData,
                                              email: e.target.value,
                                            })
                                          }
                                          minLength={6}
                                          maxLength={50}
                                          required
                                          disabled={
                                            editable.email ? true : false
                                          }
                                        />
                                        <label className="label-floating">
                                          Email
                                        </label>
                                        {editable.email === true && (
                                          <i
                                            className="fa-light fa-pen-to-square fa-xl edit-field"
                                            onClick={() =>
                                              setEditable({
                                                ...editable,
                                                email: !editable,
                                              })
                                            }
                                          ></i>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-lg-6">
                                  <div className="form-group form-group-floating row">
                                    <div className="col-lg-12">
                                      <div className="position-relative">
                                        <input
                                          type="number"
                                          className="form-control form-control-outline my-acc-input-field"
                                          placeholder="Placeholder"
                                          value={userData?.phone}
                                          pattern="[0-9]{10}"
                                          minLength={10}
                                          maxLength={10}
                                          onChange={(e) =>
                                            setUserData({
                                              ...userData,
                                              phone: e.target.value,
                                            })
                                          }
                                          required
                                          disabled={
                                            editable.phone ? true : false
                                          }
                                        />
                                        <label className="label-floating">
                                          Phone
                                        </label>
                                        {editable.phone === true && (
                                          <i
                                            className="fa-light fa-pen-to-square fa-xl edit-field"
                                            onClick={() =>
                                              setEditable({
                                                ...editable,
                                                phone: !editable,
                                              })
                                            }
                                          ></i>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="form-group form-group-floating row">
                                    <div className="col-lg-12">
                                      <div className="position-relative">
                                        <input
                                          type="text"
                                          className="form-control form-control-outline my-acc-input-field"
                                          placeholder="Placeholder"
                                          value={userData?.address}
                                          onChange={(e) =>
                                            setUserData({
                                              ...userData,
                                              address: e.target.value,
                                            })
                                          }
                                          minLength={2}
                                          maxLength={50}
                                          required
                                          disabled={
                                            editable.address ? true : false
                                          }
                                        />
                                        <label className="label-floating">
                                          Address
                                        </label>
                                        {editable.address === true && (
                                          <i
                                            className="fa-light fa-pen-to-square fa-xl edit-field"
                                            onClick={() =>
                                              setEditable({
                                                ...editable,
                                                address: !editable,
                                              })
                                            }
                                          ></i>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-lg-6">
                                  <div className="form-group form-group-floating row">
                                    <div className="col-lg-12">
                                      <div className="position-relative">
                                        <input
                                          type="text"
                                          className="form-control form-control-outline my-acc-input-field"
                                          placeholder="Placeholder"
                                          value={userData?.address2}
                                          minLength={1}
                                          maxLength={25}
                                          onChange={(e) =>
                                            setUserData({
                                              ...userData,
                                              address2: e.target.value,
                                            })
                                          }
                                          // required
                                          disabled={
                                            editable.address2 ? true : false
                                          }
                                        />
                                        <label className="label-floating">
                                          Suite, Unit, Building, or Floor
                                        </label>
                                        {editable.address2 === true && (
                                          <i
                                            className="fa-light fa-pen-to-square fa-xl edit-field"
                                            onClick={() =>
                                              setEditable({
                                                ...editable,
                                                address2: !editable,
                                              })
                                            }
                                          ></i>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="form-group form-group-floating row">
                                    <div className="col-lg-12">
                                      <div className="position-relative">
                                        <input
                                          type="text"
                                          className="form-control form-control-outline my-acc-input-field"
                                          placeholder="Placeholder"
                                          value={userData?.city}
                                          minLength={2}
                                          maxLength={25}
                                          onChange={(e) =>
                                            setUserData({
                                              ...userData,
                                              city: e.target.value,
                                            })
                                          }
                                          required
                                          disabled={
                                            editable.city ? true : false
                                          }
                                        />
                                        <label className="label-floating">
                                          City
                                        </label>
                                        {editable.city === true && (
                                          <i
                                            className="fa-light fa-pen-to-square fa-xl edit-field"
                                            onClick={() =>
                                              setEditable({
                                                ...editable,
                                                city: !editable,
                                              })
                                            }
                                          ></i>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-3">
                                  <div className="form-group form-group-floating row">
                                    <div className="col-lg-12">
                                      <div className="position-relative">
                                        <select
                                          onChange={(e) =>
                                            setUserData({
                                              ...userData,
                                              state: e.target.value,
                                            })
                                          }
                                          className="custom-select form-control-outline-within my-acc-input-field"
                                          value={userData?.state}
                                          required
                                          disabled={
                                            editable.state ? true : false
                                          }
                                        >
                                          <option
                                            value=""
                                            disabled
                                            hidden
                                            selected
                                          >
                                            Please select state
                                          </option>
                                          <option value="AL">Alabama</option>
                                          <option value="AK">Alaska</option>
                                          <option value="AZ">Arizona</option>
                                          <option value="AR">Arkansas</option>
                                          <option value="CA">California</option>
                                          <option value="CO">Colorado</option>
                                          <option value="CT">
                                            Connecticut
                                          </option>
                                          <option value="DE">Delaware</option>
                                          <option value="DC">
                                            District Of Columbia
                                          </option>
                                          <option value="FL">Florida</option>
                                          <option value="GA">Georgia</option>
                                          <option value="HI">Hawaii</option>
                                          <option value="ID">Idaho</option>
                                          <option value="IL">Illinois</option>
                                          <option value="IN">Indiana</option>
                                          <option value="IA">Iowa</option>
                                          <option value="KS">Kansas</option>
                                          <option value="KY">Kentucky</option>
                                          <option value="LA">Louisiana</option>
                                          <option value="ME">Maine</option>
                                          <option value="MD">Maryland</option>
                                          <option value="MA">
                                            Massachusetts
                                          </option>
                                          <option value="MI">Michigan</option>
                                          <option value="MN">Minnesota</option>
                                          <option value="MS">
                                            Mississippi
                                          </option>
                                          <option value="MO">Missouri</option>
                                          <option value="MT">Montana</option>
                                          <option value="NE">Nebraska</option>
                                          <option value="NV">Nevada</option>
                                          <option value="NH">
                                            New Hampshire
                                          </option>
                                          <option value="NJ">New Jersey</option>
                                          <option value="NM">New Mexico</option>
                                          <option value="NY">New York</option>
                                          <option value="NC">
                                            North Carolina
                                          </option>
                                          <option value="ND">
                                            North Dakota
                                          </option>
                                          <option value="OH">Ohio</option>
                                          <option value="OK">Oklahoma</option>
                                          <option value="OR">Oregon</option>
                                          <option value="PA">
                                            Pennsylvania
                                          </option>
                                          <option value="RI">
                                            Rhode Island
                                          </option>
                                          <option value="SC">
                                            South Carolina
                                          </option>
                                          <option value="SD">
                                            South Dakota
                                          </option>
                                          <option value="TN">Tennessee</option>
                                          <option value="TX">Texas</option>
                                          <option value="UT">Utah</option>
                                          <option value="VT">Vermont</option>
                                          <option value="VA">Virginia</option>
                                          <option value="WA">Washington</option>
                                          <option value="WV">
                                            West Virginia
                                          </option>
                                          <option value="WI">Wisconsin</option>
                                          <option value="WY">Wyoming</option>
                                        </select>
                                        <label className="label-floating">
                                          State
                                        </label>
                                        {editable.state === true && (
                                          <i
                                            className="fa-light fa-pen-to-square fa-xl edit-field"
                                            onClick={() =>
                                              setEditable({
                                                ...editable,
                                                state: !editable,
                                              })
                                            }
                                          ></i>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-3">
                                  <div className="form-group form-group-floating row">
                                    <div className="col-lg-12">
                                      <div className="position-relative">
                                        <input
                                          type="number"
                                          inputMode="numeric"
                                          pattern="^\d{5}(-\d{4})?$"
                                          className="form-control form-control-outline my-acc-input-field"
                                          placeholder="Placeholder"
                                          value={userData?.zipCode}
                                          onChange={(e) =>
                                            setUserData({
                                              ...userData,
                                              zipCode: e.target.value,
                                            })
                                          }
                                          required
                                          disabled={
                                            editable.zipCode ? true : false
                                          }
                                        />
                                        <label className="label-floating">
                                          Zip Code
                                        </label>
                                        {editable.zipCode === true && (
                                          <i
                                            className="fa-light fa-pen-to-square fa-xl edit-field"
                                            onClick={() =>
                                              setEditable({
                                                ...editable,
                                                zipCode: !editable,
                                              })
                                            }
                                          ></i>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="form-group">
                                {/* <label>
                                    Request{" "}
                                    <a href="#" target="_blank">
                                      Subscription
                                    </a>{" "}
                                    Type:
                                  </label>
                                  <select
                                    data-placeholder="Select your state"
                                    className="form-control form-control-select2"
                                    aria-hidden="false"
                                      onChange={(e) =>
                                        setSubscription(e.target.value)
                                      }
                                    required
                                  > */}
                                {/* <optgroup> */}
                                {/* <option value="" disabled hidden selected>
                                      Please select subscription
                                    </option> */}

                                {/* {allSubscriptions?.map((sub) => (
                                    <option value={sub.subs_id}>
                                      {sub.subs_name}
                                    </option>
                                  ))} */}
                                {/* </optgroup> */}
                                {/* </select> */}
                              </div>

                              <div className="form-group position-relative">
                                <label>Comments or Questions:</label>
                                {editable.comment === true && (
                                  <i
                                    className="fa-light fa-pen-to-square fa-xl edit-text-field"
                                    onClick={() =>
                                      setEditable({
                                        ...editable,
                                        comment: !editable,
                                      })
                                    }
                                  ></i>
                                )}
                                <textarea
                                  rows="5"
                                  cols="5"
                                  className="form-control my-acc-input-field"
                                  placeholder="Please let us know if you have any comments or questions."
                                  value={userData?.comment}
                                  onChange={(e) =>
                                    setUserData({
                                      ...userData,
                                      comment: e.target.value,
                                    })
                                  }
                                  minLength="4"
                                  maxLength="200"
                                  disabled={editable.comment ? true : false}
                                ></textarea>
                              </div>

                              {/* <div className="form-group mb-0">
                                  <label className="custom-control custom-checkbox">
                                    <input
                                      type="checkbox"
                                      name="remember"
                                      className="custom-control-input"
                                      title="Please accepts terms and conditions to proceed"
                                      required
                                    />
                                    <span className="custom-control-label">
                                      Accept
                                      <a href="#" target="_blank">
                                        &nbsp;terms of service
                                      </a>
                                    </span>
                                  </label>
                                </div> */}
                            </div>
                            {/* <div
                                      style={{
                                                   color: "#E53030",
                                       display: "flex",
                                        justifyContent: "end",
                                        marginRight: "20px",
                                      }}
                                   >
                                      {err}
                                    </div> */}
                            <div className="card-footer bg-transparent text-center text-lg-right">
                              <button
                                // type="submit"
                                onClick={(e) => {
                                  e.preventDefault();
                                  submitData();
                                }}
                                className="btn btn-teal btn-labeled btn-labeled-right mr-3 pr-3"
                              >
                                {/* <b>
                                    <i className="fa-solid fa-plus"></i>
                                  </b> */}{" "}
                                Update account
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="navbar navbar-expand-lg navbar-light">
              <div className="text-center d-lg-none w-100">
                <button
                  type="button"
                  className="navbar-toggler dropdown-toggle"
                  data-toggle="collapse"
                  data-target="#navbar-footer"
                >
                  <i className="icon-unfold mr-2"></i>
                  Open Footer
                </button>
              </div>

              <div className="navbar-collapse collapse" id="navbar-footer">
                <span className="navbar-text">
                  <span>&copy; 2023 VidaVault</span>. All rights reserved.
                </span>

                <ul className="navbar-nav ml-lg-auto">
                  <li className="nav-item">
                    <NavLink to="/fha/support" className="navbar-nav-link">
                      <i className="fa-solid fa-life-ring mr-2"></i> Support
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyAccount;
