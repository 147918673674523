import React, { useEffect } from "react";
import SimpleParallax from "simple-parallax-js";
import img from "../../assets/demo/header.jpg";
// import simpleParallax from "simple-parallax-js";
function ParallaxScroll() {
  useEffect(() => {
    const background = document.querySelector(".parallax-background");
    // Ensure that the DOM has loaded before applying the parallax effect
    document.addEventListener(
      "scroll",
      function () {
        new SimpleParallax(background, {
          delay: 2,
          transition: "cubic-bezier(0.25, 0.1, 0.25, 1)",
          overflow: true,
          orientation: "down",
        });
        // console.log("background", background);
      },
      true
    );
    console.log("useeffect call");
    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("scroll", function () {});
    };
  }, []);

  return (
    <div
      className="SimpleParallax"
      style={{ position: "absolute", height: "100%" }}
    >
      <div
        className="parallax-container"
        style={{ position: "absolute", height: "100%" }}
      >
        <div
          className="parallax-background"
          style={{
            backgroundImage: `url(${img})`,
            height: "120vh",
            width: "100vw",
            backgroundRepeat: "no-repeat",
            position: "absolute",
            top: "-20vh",
            backgroundSize: "cover",
            backgroundPosition: "50% 50%",
          }}
          // onClick={() => {
          //   console.log("click event triggers");
          // }}
        >
          {/* Content */}
        </div>
      </div>
      {/* <div style={{ height: "100vh", width: "100vw" }}></div> */}
    </div>
  );
}

export default ParallaxScroll;
