import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { NavLink, useLocation } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import { UserContext } from "../../UserProvider";
const PaymentFailure = () => {
  const location = useLocation();
  const { loggedIn } = useContext(UserContext);
  return (
    <div style={{ height: "100vh" }}>
      {loggedIn?.token && (
        <Helmet>
          <title>VidaVault</title>
        </Helmet>
      )}
      <Navbar />
      <div style={{ height: "85%" }} className="page-content">
        {loggedIn?.token && <Sidebar />}
        <div style={{ position: "relative" }}>
          <div style={{ position: "absolute", left: "2rem", top: "1rem" }}>
            {loggedIn.token ? (
              <NavLink to="/fha/subscriptions">
                <i
                  className="fa fa-arrow-left"
                  style={{
                    backgroundColor: "white",
                    fontSize: "2rem",
                    padding: "1rem",
                    borderRadius: "0.4rem",
                  }}
                ></i>
              </NavLink>
            ) : (
              <NavLink
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "white",

                  padding: "1rem",
                  borderRadius: "0.4rem",
                }}
                to="/"
              >
                <i
                  className="fa fa-arrow-left"
                  style={{ fontSize: "2rem" }}
                ></i>
                <div style={{margin:"0rem 1rem", fontSize:"1rem"}}>Login</div>
              </NavLink>
            )}
          </div>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              float: "left",
              marginTop: "40px",
              borderRadius: "5px",
              boxSizing: "border-box",
              padding: "80px 30px 25px 30px",
              textAlign: "center",
              position: "relative",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              boxShadow:
                "0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)",
            }}
          >
            <span
              style={{
                position: "absolute",
                top: "-50px",
                display: "flex",
                justifyContent: "center",
                width: "100px",
                height: "100px",
                borderRadius: "100%",
                backgroundColor: "#60c878",
                border: "5px solid white",
              }}
            >
              <i
                className="fa fa-check"
                style={{
                  color: "white",
                  lineHeight: "100px",
                  fontSize: "45px",
                }}
              ></i>
            </span>
            <h1
              style={{
                textTransform: "uppercase",
                color: "#55585b",
                fontSize: "18px",
                fontWeight: "500",
                marginBottom: "5px",
              }}
            >
              Payment Successful
            </h1>
            <h2
              style={{
                color: "#959a9e",
                fontSize: "16px",
                fontWeight: "400",
                marginTop: "0px",
              }}
            >
              Congratulations you have successfully subscribed to the{" "}
              {location?.state?.description} service
            </h2>
            {!loggedIn.token && (
              <h2
                style={{
                  color: "#959a9e",
                  fontSize: "16px",
                  fontWeight: "400",
                  marginTop: "0px",
                }}
              >
                Please{" "}
                <NavLink
                  to="/"
                  style={{
                    color: "blue",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                >
                  {" "}
                  Login
                </NavLink>{" "}
                to access the portal
              </h2>
            )}
            <div
              style={{
                backgroundColor: "#f8f6f6",
                borderRadius: "4px",
                width: "100%",
                marginTop: "30px",
                float: "left",
                boxSizing: "border-box",
                padding: "30px",
              }}
            >
              <div
                style={{
                  color: "#232528",
                  marginTop: "25px",
                  marginBottom: "30px",
                  padding: "0rem 1rem",
                }}
              >
                <div style={{ display: "flex" }}>
                  <b>Transaction ID : </b>
                  <div style={{ marginLeft: "0.4rem" }}>
                    {location.state.client_secret}
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <b>Amount : </b>
                  <div style={{ marginLeft: "0.4rem" }}>
                    $ {location.state.amount}
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <b>Subscription Type : </b>
                  <div
                    style={{
                      marginLeft: "0.4rem",
                      textTransform: "capitalize",
                    }}
                  >
                    {location.state.description}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentFailure;
