/** @format */

import React, { useContext, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import logo from "../assets/vidavault-logo-seal.png";
import logo2 from "../assets/logo_icon_light.png";
import { UserContext } from "../UserProvider";
import Cookies from "js-cookie";
import DrawerComponent from "../pages/responsive/DrawerComp";
const Navbar = () => {
  const navigate = useNavigate();
  const [isDrawer, setIsDrawer] = useState(false);
  const { loggedIn, logUserIn } = useContext(UserContext);
  useEffect(() => {
    document.querySelector("html").style.fontSize = "100%";
  }, []);
  return (
    <>
      <div
        style={{ height: "15%" }}
        className="navbar navbar-expand-lg navbar-dark bg-indigo navbar-static"
      >
        <div className="d-flex flex-1 d-lg-none">
          {/* <button
            type="button"
            className="navbar-toggler sidebar-mobile-main-toggle"
          >
            <i className="icon-transmission"></i>
          </button> */}

          {/* <button
          data-target="#navbar-search"
          type="button"
          className="navbar-toggler"
          data-toggle="collapse"
        >
          <i className="icon-search4"></i>
        </button> */}
          {/* Drawer responsive */}
          <button
            type="button"
            className="navbar-toggler "
            onClick={() => {
              setIsDrawer(!isDrawer);
            }}
          >
            {isDrawer ? (
              <i className="fa fa-times fa-2x"></i>
            ) : (
              <i className="fa fa-bars fa-2x"></i>
            )}
            {/* <span className="_navigation__icon "></span> */}
          </button>

          {/* Drawer end  */}
        </div>

        <div
          style={{ height: "80px" }}
          className="navbar-brand text-center text-lg-left"
        >
          <a
            //  href="#vv"
            className="d-inline-block"
          >
            <img src={logo} className="d-none d-sm-block" alt="" />
            <img src={logo2} className="d-sm-none" alt="" />
            {/* <img src={logo} className="d-sm-none" alt="" /> */}
          </a>
        </div>

        {/* {window.location.pathname !== "/" &&
        window.location.pathname !== "/fha/register" &&
        window.location.pathname !== "/fha/recover" && (
          <div
            className="navbar-collapse collapse flex-lg-1 mx-lg-3"
            id="navbar-search"
          >
            <div className="navbar-search d-flex align-items-center py-2 py-lg-0">
              <div className="form-group-feedback form-group-feedback-left flex-grow-1">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                />
                <div className="form-control-feedback">
                  <i className="icon-search4 text-white opacity-50"></i>
                </div>
              </div>
            </div>
          </div>
        )} */}

        <div class="d-flex justify-content-end align-items-center ml-auto">
          <ul class="navbar-nav flex-row">
            {window.location.pathname === "/fha/support" && loggedIn && (
              <li class="nav-item">
                <NavLink
                  className="navbar-nav-link"
                  style={{ textDecoration: "none", color: "white" }}
                  to="/fha/createslideshow"
                >
                  <i class="fa-solid fa-home"></i>
                  <span class="d-none d-lg-inline-block ml-2">Home</span>
                </NavLink>
              </li>
            )}
            {window.location.pathname !== "/fha/support" && (
              <li class="nav-item">
                <NavLink
                  style={{
                    position: "relative",
                    display: "block",
                    cursor: "pointer",
                    padding: "1.25rem 1.25rem",
                    outline: 0,
                    transition: "all ease-in-out .15s",
                    textDecoration: "none",
                    color: "white",
                  }}
                  to="/fha/support"
                >
                  <i class="fa-solid fa-life-ring"></i>
                  <span class="d-none d-lg-inline-block ml-2">Support</span>
                </NavLink>
              </li>
            )}
            {loggedIn && (
              <li class="nav-item cursor-pointer">
                <div
                  className="navbar-nav-link "
                  style={{ textDecoration: "none", color: "white" }}
                  onClick={() => {
                    Cookies.remove("userData");
                    localStorage.removeItem("fha_logo");
                    logUserIn(false);
                    navigate("/");
                  }}
                >
                  <i class="fa-solid fa-sign-out"></i>
                  <span class="d-none d-lg-inline-block ml-2 ">Logout</span>
                </div>
              </li>
            )}
            {window.location.pathname !== "/fha/register" && !loggedIn && (
              <li class="nav-item">
                <NavLink
                  className="navbar-nav-link"
                  style={{ textDecoration: "none", color: "white" }}
                  to="/fha/register"
                >
                  <i class="fa-solid fa-user-plus"></i>
                  <span class="d-none d-lg-inline-block ml-2">Sign Up</span>
                </NavLink>
              </li>
            )}
            {window.location.pathname !== "/" && !loggedIn && (
              <li class="nav-item">
                <NavLink
                  className="navbar-nav-link"
                  style={{ textDecoration: "none", color: "white" }}
                  to="/"
                >
                  <i class="fa-solid fa-right-to-bracket"></i>
                  <span class="d-none d-lg-inline-block ml-2">Login</span>
                </NavLink>
              </li>
            )}
          </ul>
        </div>
      </div>
      {isDrawer && (
        <>
          <DrawerComponent setIsDrawer={setIsDrawer} />
          {/* <h3> Sample Text</h3> */}
        </>
      )}
    </>
  );
};

export default Navbar;
