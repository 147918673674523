import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import SaNavbar from "../../components/SaNavbar";
import SaSidebar from "../../components/SaSidebar";
import axios from "axios";
import { adminToken, baseUrl } from "../../config";
import { useContext } from "react";
import { UserContext } from "../../UserProvider";
import { NavLink, useParams } from "react-router-dom";
import MessageComponent from "../../components/MessageComponent";
const Ticketdetails = () => {
  const [ticketData, setTicketData] = useState([]);
  const { setIsLoading, setMsg, setToast } = useContext(UserContext);
  const [response, setResponse] = useState("");
  const { ticketId } = useParams();

  const fetchData = () => {
    setIsLoading(true);
    axios
      .get(`${baseUrl}/support_ticket/${ticketId}`, {
        headers: {
          Authorization: `Bearer ${adminToken}`,
        },
      })
      .then((res) => {
        setTicketData(res.data.data[0]);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  const handleSendResponse = () => {
    setToast(false);
    setMsg({ text: "", type: "" });
    setIsLoading(true);
    if (response.trim() == "") {
      setMsg({
        type: "error",
        text: `Please write some message to respond`,
      });
      setToast(true);
      setIsLoading(false);
    } else {
      axios
        .post(
          `${baseUrl}/support_ticket/respond`,
          {
            ticket_id: ticketData.st_id,
            fha_id: ticketData.st_fha_id,
            responded_msg: response.trim(),
          },
          {
            headers: {
              Authorization: `Bearer ${adminToken}`,
            },
          }
        )
        .then((res) => {
          fetchData();
          setMsg({
            type: "success",
            text: `Response sent successfully`,
          });
          setToast(true);
          setIsLoading(false);
          setResponse("");
        })
        .catch((err) => {
          setMsg({
            type: "error",
            text: `Failed to send the response`,
          });
          setToast(true);
          setIsLoading(false);
        });
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div style={{ height: "100vh" }}>
      <Helmet>
        <title>VidaVault - Admin</title>
      </Helmet>
      <SaNavbar />
      <div style={{ height: "85%" }} className="page-content">
        <SaSidebar />
        <div className="content-wrapper">
          <div className="content-inner">
            <div className="navbar navbar-expand-lg navbar-light sticky-top">
              <div className="text-center d-lg-none w-100">
                <button
                  type="button"
                  className="navbar-toggler dropdown-toggle"
                  data-toggle="collapse"
                  data-target="#navbar-second"
                >
                  <i className="icon-unfold mr-2"></i>
                  Open Task Menu
                </button>
              </div>
            </div>
            <div className="content">
              <div className="card" style={{ height: "100%" }}>
                <div
                  style={{ display: "flex", flexWrap: "wrap", padding: "4rem" }}
                >
                  {ticketData.st_from && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexBasis: "50%",
                        padding: "0.7rem 1.5rem",
                      }}
                    >
                      <div style={{ fontSize: "1rem", fontWeight: "bold" }}>
                        FHA :
                      </div>
                      <div style={{ marginLeft: "0.5rem" }}>
                        {ticketData.st_from}
                      </div>
                    </div>
                  )}
                  {ticketData.st_email && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexBasis: "50%",
                        padding: "0.7rem 1.5rem",
                      }}
                    >
                      <div style={{ fontSize: "1rem", fontWeight: "bold" }}>
                        FHA Email :
                      </div>
                      <div style={{ marginLeft: "0.5rem" }}>
                        {ticketData.st_email}
                      </div>
                    </div>
                  )}
                  {ticketData.st_business_name && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexBasis: "50%",
                        padding: "0.7rem 1.5rem",
                      }}
                    >
                      <div style={{ fontSize: "1rem", fontWeight: "bold" }}>
                        Bussiness Name :
                      </div>
                      <div style={{ marginLeft: "0.5rem" }}>
                        {ticketData.st_business_name}
                      </div>
                    </div>
                  )}
                  {ticketData.st_subject && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexBasis: "50%",
                        padding: "0.7rem 1.5rem",
                      }}
                    >
                      <div style={{ fontSize: "1rem", fontWeight: "bold" }}>
                        Subject :
                      </div>
                      <div style={{ marginLeft: "0.5rem" }}>
                        {ticketData.st_subject}
                      </div>
                    </div>
                  )}
                  {ticketData.st_message && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexBasis: "50%",
                        padding: "0.7rem 1.5rem",
                        flexWrap: "nowrap",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "1rem",
                          fontWeight: "bold",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Message :
                      </div>
                      <div style={{ marginLeft: "0.5rem" }}>
                        {ticketData.st_message}
                      </div>
                    </div>
                  )}
                  {ticketData.st_attachments && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexBasis: "50%",
                        padding: "0.7rem 1.5rem",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <div style={{ fontSize: "1rem", fontWeight: "bold" }}>
                        Attachments :
                      </div>
                      <a
                        href={ticketData.st_attachments}
                        target="_blank"
                        rel="noreferrer"
                        style={{ marginLeft: "0.5rem" }}
                      >
                        {ticketData.st_attachments}
                      </a>
                    </div>
                  )}
                  {ticketData.created_dt && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexBasis: "50%",
                        padding: "0.7rem 1.5rem",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <div style={{ fontSize: "1rem", fontWeight: "bold" }}>
                        Created At :
                      </div>
                      <div style={{ marginLeft: "0.5rem" }}>
                        {new Date(ticketData.created_dt).toLocaleString(
                          "en-US",
                          {
                            timeZone: "UTC",
                            weekday: "long",
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            hour12: true,
                          }
                        )}
                        {/* {ticketData.created_dt} */}
                      </div>
                    </div>
                  )}
                  {ticketData.st_email && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexBasis: "100%",
                        padding: "0.7rem 1.5rem",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "1rem",
                          fontWeight: "bold",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Responses :
                      </div>
                      <div
                        style={{
                          marginLeft: "0.5rem",
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        <ul className="list-group-flush">
                          {ticketData?.response?.map((res) => (
                            <MessageComponent message={res} />
                          ))}
                        </ul>
                      </div>
                    </div>
                  )}

                  {ticketData.st_email && (
                    <div
                      style={{
                        display: "flex",
                        flexBasis: "100%",
                        alignItems: "center",
                        padding: "0.7rem 1.5rem",
                      }}
                    >
                      <div
                        className="form-group form-group-floating"
                        style={{ width: "100%" }}
                      >
                        <div
                          className="position-relative"
                          style={{ width: "100%" }}
                        >
                          <textarea
                            className="form-control form-control-outline"
                            placeholder="Placeholder"
                            onChange={(e) => setResponse(e.target.value)}
                            value={response}
                            name="personalDetails_obituryText"
                            rows="5"
                            style={{ width: "100%" }}
                          ></textarea>
                          <label className="label-floating">Response</label>
                        </div>
                      </div>
                    </div>
                  )}
                  {ticketData.st_email && (
                    <div
                      style={{
                        display: "flex",
                        flexBasis: "100%",
                        justifyContent: "end",
                        alignItems: "center",
                        paddingRight: "1.5rem",
                      }}
                    >
                      <div
                        onClick={handleSendResponse}
                        className="btn btn-teal"
                      >
                        Respond
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="navbar navbar-expand-lg navbar-light">
              <div className="text-center d-lg-none w-100">
                <button
                  type="button"
                  className="navbar-toggler dropdown-toggle"
                  data-toggle="collapse"
                  data-target="#navbar-footer"
                >
                  <i className="icon-unfold mr-2"></i>
                  Open Footer
                </button>
              </div>
              <div className="navbar-collapse collapse" id="navbar-footer">
                <span className="navbar-text">
                  <span>&copy; 2023 VidaVault</span>.
                  All rights reserved.
                </span>
                {/* <ul className="navbar-nav ml-lg-auto">
                  <li className="nav-item">
                    <NavLink to="/fha/support" className="navbar-nav-link">
                      <i className="fa-solid fa-life-ring mr-2"></i> Support
                    </NavLink>
                  </li>
                </ul> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Ticketdetails;
