/** @format */

// <<<<<<< HEAD
// export const baseUrl = "http://3.83.45.156:3001";
// =======
export const baseUrl = "https://vidavault.net/backend"; //"http://3.83.45.156:3001";
// >>>>>>> 36c61854ec6fd4b34538787a9a00ced8463db7c8
// export const baseUrl = "http://ktspldemo.co.in:6002";
// export const baseUrl = "http://161.97.107.171:6002";
export const adminToken =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c";
// export const baseUrl = "http://localhost:3001"

// Test Env
export const frontUrl = "http://ktspldemo.co.in:6001";

// Prod Env
// export const frontUrl = "https://vidavault.net"
