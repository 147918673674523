import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { Helmet } from "react-helmet";
import { NavLink, useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import Subnav from "../../components/Subnav";
import { baseUrl } from "../../config";
import { UserContext } from "../../UserProvider";
import Cookies from "js-cookie";
const Subscription = () => {
  const { loggedIn, setMsg, setToast, logUserIn } = useContext(UserContext);
  const [allSubscriptions, setAllSubscriptions] = useState();
  const [subsctiption, setSubscription] = useState();
  useEffect(() => {
    axios
      .get(`${baseUrl}/subscripions`)
      .then((res) => setAllSubscriptions(res.data))
      .catch((err) => {
        console.log(err);
        if (err?.response?.data?.resultMsg === "User is inactive") {
          setMsg({
            type: "error",
            text: "The Super Admin has deactivated your account. Contact super admin for details.",
          });
          setToast(true);

          setTimeout(() => {
            Cookies.remove("userData");
            localStorage.removeItem("fha_logo");
            logUserIn(false);

            navigate("/");
          }, 2000);
        }
      });
    axios
      .get(`${baseUrl}/fha/my_subscription`, {
        headers: { Authorization: `Bearer ${loggedIn.token}` },
      })
      .then((res) => setSubscription(res.data.data))
      .catch((err) => {
        console.log(err);
        if (err?.response?.data?.resultMsg === "User is inactive") {
          setMsg({
            type: "error",
            text: "The Super Admin has deactivated your account. Contact super admin for details.",
          });
          setToast(true);

          setTimeout(() => {
            Cookies.remove("userData");
            localStorage.removeItem("fha_logo");
            logUserIn(false);

            navigate("/");
          }, 2000);
        }
      });
  }, []);
  const navigate = useNavigate();
  const createPaymentOrder = (request) => {
    const data = {
      amount: request.request_amount,
      user_id: loggedIn.user.fha_id,
      subscription_id: request.subscription_id,
      redirect: window.location.pathname,
      description: request.description,
    };
    navigate("/fha/payment", {
      state: {
        data: data,
        type: "custom_request",
      },
    });
  };
  return (
    <div style={{ height: "100vh" }}>
      <Helmet>
        <title>VidaVault</title>
      </Helmet>
      <Navbar />
      <div style={{ height: "85%" }} className="page-content">
        <Sidebar />
        {subsctiption && (
          <div className="content-wrapper">
            <div className="content-inner">
              <Subnav />
              <div className="content">
                <div className="row">
                  {subsctiption.length == 0 && (
                    <div className="col-lg-12">
                      <div className="card">
                        <div>
                          <h3 className="text-center mt-3 font-weight-bold">
                            Subscription Settings
                          </h3>
                        </div>
                        {loggedIn.user.fha_subs_type == 1 && (
                          <i className="fas fa-check-circle position-absolute top-0 end-0 right-0 m-3 text-success fa-2x"></i>
                        )}
                        <div className="card-body text-center position-relative">
                          <div className="d-inline-flex bg-success bg-opacity-10 text-success rounded-pill p-2 mb-3 mt-1">
                            <i className="ph-book ph-2x m-1"></i>
                          </div>
                          <h5 className="card-title">Free Trial</h5>
                          <p className="mb-3">
                          Enjoy a free trial of our services today.
                          </p>
                          <div className="btn btn-success mb-1">
                            Current Subscription
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="col-lg-6">
                    <div className="card">
                      {subsctiption.length > 0 &&
                        subsctiption[0].subs_log_subs_id == 2 && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              padding: "0.4rem 1rem",
                            }}
                          >
                            <div style={{ fontSize: "1rem" }}>
                              Expires on:
                              {new Date(
                                subsctiption[0].subs_log_end_dt
                              ).toLocaleString("en-US", {
                                timeZone: "UTC",
                                weekday: "long",
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                                hour: "numeric",
                                minute: "numeric",
                                hour12: true,
                              })}
                            </div>
                            <i className="fas fa-check-circle  text-success fa-2x"></i>
                          </div>
                        )}
                      <div className="card-body text-center position-relative">
                        <div className="d-inline-flex bg-warning bg-opacity-10 text-warning rounded-pill p-2 mb-3 mt-1">
                          <i className="ph-lifebuoy ph-2x m-1"></i>
                        </div>
                        <h5 className="card-title">Monthly Subscription</h5>
                        <p className="mb-3">
                        Get access for just $100 per month.
                        </p>
                        {subsctiption.length > 0 &&
                        subsctiption[0].subs_log_subs_id == 2 ? (
                          <div className="btn btn-warning mb-1">
                            Current Subscription
                          </div>
                        ) : (
                          subsctiption.length == 0 && (
                            <div
                              onClick={() =>
                                createPaymentOrder({
                                  request_amount: allSubscriptions.find(
                                    (sub) => sub.subs_id == 2
                                  )?.subs_price,
                                  subscription_id: 2,
                                  description: "Monthly Subscription",
                                })
                              }
                              className="btn btn-warning mb-1"
                            >
                              Buy Now
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="card">
                      {subsctiption.length > 0 &&
                        subsctiption[0].subs_log_subs_id == 3 && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              padding: "0.4rem 1rem",
                            }}
                          >
                            <div style={{ fontSize: "1rem" }}>
                              Expires on:
                              {new Date(
                                subsctiption[0].subs_log_end_dt
                              ).toLocaleString("en-US", {
                                timeZone:"UTC",
                                weekday: "long",
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                                hour: "numeric",
                                minute: "numeric",
                                hour12: true,
                              })}
                            </div>
                            <i className="fas fa-check-circle  text-success fa-2x"></i>
                          </div>
                        )}
                      <div className="card-body text-center position-relative">
                        <div className="d-inline-flex bg-primary bg-opacity-10 text-primary rounded-pill p-2 mb-3 mt-1">
                          <i className="ph-newspaper ph-2x m-1"></i>
                        </div>
                        <h5 className="card-title">Yearly Subscription</h5>
                        <p className="mb-3">
                        Opt for an annual subscription and get it for only $200.
                        </p>
                        {subsctiption.length > 0 &&
                        subsctiption[0].subs_log_subs_id == 3 ? (
                          <div className="btn btn-primary mb-1">
                            Current Subscription
                          </div>
                        ) : (
                          subsctiption.length == 0 && (
                            <div
                              onClick={() =>
                                createPaymentOrder({
                                  request_amount: allSubscriptions.find(
                                    (sub) => sub.subs_id == 3
                                  )?.subs_price,
                                  subscription_id: 3,
                                  description: "Yearly Subscription",
                                })
                              }
                              className="btn btn-primary mb-1"
                            >
                              Buy Now
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="navbar navbar-expand-lg navbar-light">
                <div className="text-center d-lg-none w-100">
                  <button
                    type="button"
                    className="navbar-toggler dropdown-toggle"
                    data-toggle="collapse"
                    data-target="#navbar-footer"
                  >
                    <i className="icon-unfold mr-2"></i>
                    Open Footer
                  </button>
                </div>

                <div className="navbar-collapse collapse" id="navbar-footer">
                  <span className="navbar-text">
                    <span>&copy; 2023 VidaVault</span>. All rights
                    reserved.
                  </span>

                  <ul className="navbar-nav ml-lg-auto">
                    <li className="nav-item">
                      <NavLink to="/fha/support" className="navbar-nav-link">
                        <i className="fa-solid fa-life-ring mr-2"></i> Support
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Subscription;
