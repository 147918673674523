import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import loading from "../assets/loading.gif";
import { UserContext } from "../UserProvider";
const Loader = () => {
  const { isLoading, setIsLoading } = useContext(UserContext);
  return (
    <>
      {isLoading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            width: "100vw",
            position: "absolute",
            zIndex: "2000",
            backdropFilter: "brightness(90%) blur(4px)",
          }}
        >
          <img style={{ height: "5rem" }} src={loading} alt="" />
        </div>
      )}
    </>
  );
};
export default Loader;
