/** @format */

import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import fhalogo from "../assets/logo-placeholder.png";
const SaSidebar = () => {
  return (
    <div className="sidebar sidebar-light sidebar-main sidebar-expand-lg">
      <div className="sidebar-content">
        <div className="sidebar-section">
          <div className="sidebar-user-material">
            <div className="sidebar-section-body bg-white">
              <div className="d-flex">
                {/* <a href="#" target="_blank">
                  <img
                    src={fhalogo}
                    alt="Funeral Home Name"
                    className="client-logo mr-2"
                  />
                </a> */}
              </div>
            </div>
          </div>
        </div>

        <div className="sidebar-section">
          <ul className="nav nav-sidebar" data-nav-type="accordion">
            <li className="nav-item-header">
              <div className="text-uppercase font-size-xs line-height-xs mt-1">
                Main
              </div>
              <i className="icon-menu" title="Main"></i>
            </li>
            <li className="nav-item">
              <NavLink to="/sa/approved" className="nav-link">
                {/* <i className="icon-home4"></i> */}
                <span>Approved Requests</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/sa/pending" className="nav-link">
                {/* <i className="icon-home4"></i> */}
                <span>Pending Requests</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/sa/rejected" className="nav-link">
                {/* <i className="icon-home4"></i> */}
                <span>Rejected Requests</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/sa/tickets" className="nav-link">
                <span>Support Tickets</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/sa/subscribers" className="nav-link">
                <span>Subscribers</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/sa/transactions" className="nav-link">
                <span>Transactions</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/sa/plaqueorders" className="nav-link">
                <span>Plaque Orders</span>
              </NavLink>
            </li>

            {/* <li className="nav-item-header">
              <div className="text-uppercase font-size-xs line-height-xs">
                Account
              </div>{" "}
              <i className="icon-menu" title="Forms"></i>
            </li>
            <li className="nav-item">
              <a href="#" className="nav-link">
                <i className="icon-user-plus"></i>
                <span>My Account</span>
              </a>
            </li>

            <li className="nav-item">
              <a href="#" className="nav-link">
                <i className="icon-comment-discussion"></i>
                <span>Messages</span>
                <span className="badge badge-teal badge-pill align-self-center ml-auto">
                  58
                </span>
              </a>
            </li> */}
            {/* <li className="nav-item">
              <a href="#" className="nav-link">
                <i className="icon-cog5"></i>
                <span>Subscription settings</span>
              </a>
            </li> */}
            {/* <li className="nav-item">
              <a href="#" className="nav-link">
                <i className="icon-switch2"></i>
                <span>Logout</span>
              </a>
            </li> */}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SaSidebar;
