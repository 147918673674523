import React, { useEffect, useState, useMemo, useRef } from "react";
import { Helmet } from "react-helmet";
import SaNavbar from "../../components/SaNavbar";
import { useTable, useFilters, useSortBy, usePagination } from "react-table";
import SaSidebar from "../../components/SaSidebar";
import axios from "axios";
import { adminToken, baseUrl } from "../../config";
import Confirmation from "../../components/Confirmation";
import { useContext } from "react";
import { UserContext } from "../../UserProvider";
import ColumnFilter from "../../components/ColumnFilter";
import { NavLink } from "react-router-dom";
const Pendingrequests = () => {
  const [args, setArgs] = useState([]);
  const [popupTrigger, setPopupTrigger] = useState(false);
  const [popupText, setPopupText] = useState();
  const [tableData, setTableData] = useState([]);
  const [popupTitle, setPopupTitle] = useState();
  const { setIsLoading, setMsg, setToast } = useContext(UserContext);

  const fetchData = () => {
    setIsLoading(true);
    axios
      .get(`${baseUrl}/users/pending`, {
        headers: {
          Authorization: `Bearer ${adminToken}`,
        },
      })
      .then((res) => {
        setTableData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    fetchData();
  }, []);
  const confirmed = () => {
    setIsLoading(true);
    setPopupText();
    setPopupTrigger();
    setArgs();
    axios
      .post(
        `${baseUrl}/users/change_status`,
        { status: args.status, user_id: args.id },
        {
          headers: {
            Authorization: `Bearer ${adminToken}`,
          },
        }
      )
      .then((res) => {
        fetchData();
        setIsLoading(false);
        setMsg({
          type: "success",
          text: res.data.resultMsg,
        });
        setToast(true);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };
  const canceled = () => {
    setPopupTrigger();
    setPopupText();
  };
  const columns = useMemo(
    () => [
      {
        Header: "First Name",
        accessor: "fha_firstname",
        Filter: ColumnFilter,
      },
      {
        Header: "Last Name",
        accessor: "fha_lastname",
        Filter: ColumnFilter,
      },
      {
        Header: "Username",
        accessor: "fha_username",
        Filter: ColumnFilter,
      },
      {
        Header: "Email",
        accessor: "fha_email",
        Filter: ColumnFilter,
      },
      {
        Header: "Business Name",
        accessor: "fha_business_name",
        Filter: ColumnFilter,
      },
      {
        Header: "Actions",
        Cell: ({ row }) => {
          const ref = useRef(null);
          const [dropdownOpen, setDropdownOpen] = useState(false);
          const toggleDropdown = (req) => {
            if (dropdownOpen) {
              setDropdownOpen(false);
            } else {
              setDropdownOpen(req.fha_id);
            }
          };
          const req = row.original;
          useEffect(() => {
            function handleClickOutside(event) {
              if (ref.current && !ref.current.contains(event.target)) {
                setDropdownOpen(false);
              }
            }

            document.addEventListener("mousedown", handleClickOutside);

            return () => {
              document.removeEventListener("mousedown", handleClickOutside);
            };
          }, [ref]);
          return (
            <div className="btn-group">
              <button
                type="button"
                className="btn btn-sm link-primary btn-outline-warning"
                style={{ textTransform: "none", whiteSpace: "nowrap" }}
                onClick={() => toggleDropdown(req)}
              >
                Pending
                <i class="fa-solid fa-pen ml-2"></i>
              </button>
              {dropdownOpen && dropdownOpen == req.fha_id && (
                <div
                  className="dropdown-menu"
                  style={{
                    display: "block",
                    zIndex: "10",
                  }}
                  ref={ref}
                >
                  {req.status !== 1 && (
                    <div
                      onClick={() => {
                        setArgs({ id: req.fha_id, status: 1 });
                        setPopupTitle("Approve");
                        setPopupText("Are your sure do you want to approve ?");
                        setPopupTrigger(true);
                      }}
                      className="dropdown-item"
                    >
                      Approve
                    </div>
                  )}
                  {req.status !== 1 && (
                    <div
                      onClick={() => {
                        setArgs({ id: req.fha_id, status: 2 });
                        setPopupTitle("Reject");
                        setPopupText("Are your sure do you want to reject ?");
                        setPopupTrigger(true);
                      }}
                      className="dropdown-item"
                    >
                      Reject
                    </div>
                  )}
                </div>
              )}
            </div>
          );
        },
        disableFilters: true,
      },
    ],
    []
  );

  const data = useMemo(() => tableData, [tableData]);
  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useFilters,
    usePagination
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    nextPage,
    gotoPage,
    previousPage,
    pageOptions,
    pageCount,
    canNextPage,
    canPreviousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = tableInstance;
  return (
    <div style={{ height: "100vh" }}>
      <Helmet>
        <title>VidaVault - Admin</title>
      </Helmet>
      <Confirmation
        popupText={popupText}
        popupTitle={popupTitle}
        popupTrigger={popupTrigger}
        setPopupTrigger={setPopupTrigger}
        confirmed={confirmed}
        canceled={canceled}
      />
      <SaNavbar />
      <div style={{ height: "85%" }} className="page-content">
        <SaSidebar />

        <div className="content-wrapper">
          <div className="content-inner">
            <div className="navbar navbar-expand-lg navbar-light sticky-top">
              <div className="text-center d-lg-none w-100">
                <button
                  type="button"
                  className="navbar-toggler dropdown-toggle"
                  data-toggle="collapse"
                  data-target="#navbar-second"
                >
                  <i className="icon-unfold mr-2"></i>
                  Open Task Menu
                </button>
              </div>
            </div>

            <div className="content">
              <div className="card" style={{ height: "100%" }}>
                <div
                  className="content d-flex justify-content-center align-items-center"
                  style={{ height: "100%" }}
                >
                  <form className="flex-fill" style={{ height: "100%" }}>
                    <div className="row" style={{ height: "100%" }}>
                      <div className="col-lg-12">
                        <div>
                          <h3 className="text-center font-weight-bold">
                            Pending Requests
                          </h3>
                        </div>
                        <div class="table">
                          <table
                            {...getTableProps()}
                            className="table"
                            style={{ height: "100%" }}
                          >
                            <thead>
                              {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                  {headerGroup.headers.map((column) => (
                                    <th {...column.getHeaderProps()}>
                                      {column.render("Header")}

                                      <div>
                                        {column.canFilter
                                          ? column.render("Filter")
                                          : null}
                                      </div>
                                    </th>
                                  ))}
                                </tr>
                              ))}
                            </thead>
                            {page?.length > 0 ? (
                              <tbody {...getTableBodyProps()}>
                                {page.map((row) => {
                                  prepareRow(row);
                                  return (
                                    <tr {...row.getRowProps()}>
                                      {row.cells.map((cell) => {
                                        return (
                                          <td {...cell.getCellProps()}>
                                            {cell.render("Cell")}
                                          </td>
                                        );
                                      })}
                                    </tr>
                                  );
                                })}
                              </tbody>
                            ) : (
                              <tbody>
                                <tr>
                                  <td
                                    colSpan={
                                      headerGroups[0]?.headers.length || 1
                                    }
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "80px",
                                      }}
                                    >
                                      <h1
                                        style={{
                                          color: "#dddddd",
                                          fontSize: "35px",
                                        }}
                                      >
                                        No record yet!
                                      </h1>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            )}
                          </table>
                          <div
                            className="pagination"
                            style={{
                              padding: "0.5rem",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <button
                              type="button"
                              onClick={() => gotoPage(0)}
                              disabled={!canPreviousPage}
                            >
                              {"<<"}
                            </button>
                            <button
                              type="button"
                              style={{ margin: "0.5rem" }}
                              onClick={() => previousPage()}
                              disabled={!canPreviousPage}
                            >
                              {"<"}
                            </button>
                            <button
                              type="button"
                              style={{ margin: "0.5rem" }}
                              onClick={() => nextPage()}
                              disabled={!canNextPage}
                            >
                              {">"}
                            </button>
                            <button
                              type="button"
                              style={{ margin: "0.5rem" }}
                              onClick={() => gotoPage(pageCount - 1)}
                              disabled={!canNextPage}
                            >
                              {">>"}
                            </button>
                            <span style={{ margin: "0.5rem" }}>
                              Page
                              <strong>
                                {pageIndex + 1} of {pageOptions.length}
                              </strong>
                            </span>
                            |
                            <span style={{ margin: "0.5rem" }}>
                              Go to page:
                              <input
                                type="number"
                                defaultValue={pageIndex + 1}
                                onChange={(e) => {
                                  const page = e.target.value
                                    ? Number(e.target.value) - 1
                                    : 0;
                                  gotoPage(page);
                                }}
                                style={{ width: "100px", margin: "0.5rem" }}
                              />
                            </span>
                            <select
                              style={{ margin: "0.5rem" }}
                              value={pageSize}
                              onChange={(e) => {
                                setPageSize(Number(e.target.value));
                              }}
                            >
                              {[10, 20, 30, 40, 50].map((pageSize) => (
                                <option key={pageSize} value={pageSize}>
                                  Show {pageSize}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="navbar navbar-expand-lg navbar-light">
              <div className="text-center d-lg-none w-100">
                <button
                  type="button"
                  className="navbar-toggler dropdown-toggle"
                  data-toggle="collapse"
                  data-target="#navbar-footer"
                >
                  <i className="icon-unfold mr-2"></i>
                  Open Footer
                </button>
              </div>

              <div className="navbar-collapse collapse" id="navbar-footer">
                <span className="navbar-text">
                  <span>&copy; 2023 VidaVault</span>. All rights reserved.
                </span>

                <ul className="navbar-nav ml-lg-auto">
                  {/* <li className="nav-item">
                    <NavLink to="/fha/support" className="navbar-nav-link">
                      <i className="fa-solid fa-life-ring mr-2"></i> Support
                    </NavLink>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pendingrequests;
