/** @format */

import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import { baseUrl } from "../../config";
import Toast from "react-toast-component";
import Popup from "../../components/Popup";
import { useContext } from "react";
import { UserContext } from "../../UserProvider";
import { useLocation, useNavigate } from "react-router-dom";
import { useRef } from "react";
import default_logo from "../../assets/add_business_logo.png";
import PriceModal from "../../components/PriceModal";
import Avatar from "../../components/image-upload/Avatar";
const Register = () => {
  const [openPriceModal, setOpenPriceModal] = useState(false);
  const [firstname, setFirstname] = useState();
  const [lastname, setLastname] = useState();
  const [bussinessname, setBussinessname] = useState();
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [repeatPassword, setRepeatPassword] = useState();
  const [email, setEmail] = useState();
  const [confirmEmail, setConfirmEmail] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [address, setAddress] = useState();
  const [unit, setUnit] = useState("");
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [zip, setZip] = useState();
  const [subscription, setSubscription] = useState(1);
  const [allSubscriptions, setAllSubscriptions] = useState();
  const [comments, setComments] = useState();
  const [registerSuccess, setRegisterSuccess] = useState(false);
  const [validate, setValidate] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState(default_logo);
  const [logoFile, setLogoFile] = useState(null);
  const [error, setError] = useState("");
  const { loggedIn, logUserIn, setMsg, setToast, setIsLoading } =
    useContext(UserContext);
  const location = useLocation();
  const registerFormRef = useRef();

  const nameRegex = /^[a-zA-Z]{1,}['-]?[a-zA-Z]+$/gm;
  const LastnameRegex = /^[a-zA-Z]{1,}['-]?[a-zA-Z]+$/gm;
  // const businessRegex = /^[a-zA-Z]{0,}[-&. ]*[a-zA-Z]*[-&. ]*[a-zA-z]*/gim;
  const businessRegex = /^[a-zA-Z]{0,}[-&. ]*[a-zA-Z]*[-&. ]*[a-zA-z]*/gim;
  useEffect(() => {
    // console.log(location);
    if (location?.state?.data) {
      window.location.replace("/fha/register");
    }
  }, [location]);
  useEffect(() => {
    axios
      .get(`${baseUrl}/subscripions`)
      .then((res) => setAllSubscriptions(res.data))
      .catch((err) => console.log(err));
  }, []);
  const navigate = useNavigate();

  // async function convertBlobToImage(blob) {
  //   return await new Promise((resolve, reject) => {
  //     const img = new Image();
  //     img.onload = () => {
  //       const canvas = document.createElement("canvas");
  //       canvas.width = img.width;
  //       canvas.height = img.height;
  //       const ctx = canvas.getContext("2d");
  //       ctx.drawImage(img, 0, 0);

  //       // Convert canvas to blob in JPG format
  //       canvas.toBlob(
  //         (convertedBlob) => {
  //           resolve(convertedBlob);
  //         },
  //         "image/jpeg", // Change this to "image/png" for PNG format
  //         1 // Quality (0 to 1)
  //       );
  //     };
  //     img.onerror = (error) => {
  //       reject(error);
  //     };
  //     img.src = URL?.createObjectURL(blob);
  //   });
  // }

  // const convertedBlob = convertBlobToImage(logoFile?.png);

  // console.log("logoFile type ", logoFile instanceof Blob);

  const handleRegister = (e) => {
    e.preventDefault();
    if (registerFormRef.current?.checkValidity()) {
      if (confirmEmail !== email) {
        setMsg({ type: "error", text: "Email doesn't match" });
        setToast(true);
      } else if (password !== repeatPassword) {
        setMsg({ type: "error", text: "Password doesn't match" });
        setToast(true);
      } else if (!businessRegex?.test(bussinessname)) {
        console.log("Business name ===> ", bussinessname);
        setMsg({ type: "error", text: "Business name is not valid" });
        setToast(true);
      } else if (!nameRegex?.test(firstname)) {
        console.log("First name ===> ", firstname);
        setMsg({ type: "error", text: "First name is not valid" });
        setToast(true);
      } else if (!LastnameRegex?.test(lastname)) {
        console.log("Last name ===> ", lastname);
        setMsg({ type: "error", text: "Last name is not valid" });
        setToast(true);
      } else if (
        comments?.trim() != "" &&
        (comments?.length < 4 || comments?.length > 200)
      ) {
        setMsg({
          type: "error",
          text: "Comments should be minimum 4 and maximum 200 characters long",
        });
        setToast(true);
      } else {
        const formData = new FormData();
        formData.append("fha_business_name", bussinessname);
        formData.append("fha_username", username);
        formData.append("fha_firstname", firstname);
        formData.append("fha_lastname", lastname);
        formData.append("fha_pwd", password);
        formData.append("fha_email", email);
        formData.append("fha_contact", phoneNumber);
        formData.append("fha_address1", address);
        formData.append("fha_address2", unit);
        formData.append("fha_city", city);
        formData.append("fha_state", state);
        formData.append("fha_zipcode", zip);
        formData.append("fha_subs_type", subscription);
        formData.append("fha_comments", comments);
        logoFile instanceof Blob &&
          formData.append("logo", logoFile, "business-logo.jpg");
        // logoFile instanceof Blob &&
        //   formData.append("logo", logoFile, "cropped-image.jpg");
        setIsLoading(true);

        // console.log("Logo ==> ", logoFile instanceof Blob);
        // console.log("Formdata ==> ", formData);
        try {
          axios
            // .post(`${baseUrl}/register`, formData)
            .post(`${baseUrl}/register`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((res) => {
              if (res?.status == 200) {
                if (res?.data?.resultCode == 1) {
                  if (subscription == 1) {
                    setMsg({
                      type: "success",
                      text: "Registration request sent successfully",
                    });
                    setToast(true);
                    setRegisterSuccess(true);
                  } else {
                    let data = {
                      amount: allSubscriptions?.find(
                        (sub) => sub.subs_id == subscription
                      )?.subs_price,
                      user_id: res?.data?.id,
                      subscription_id: subscription,
                      description: allSubscriptions?.find(
                        (sub) => sub?.subs_id == subscription
                      )?.subs_name,
                    };
                    console.log(data);
                    navigate("/fha/initpayment", {
                      state: {
                        data: data,
                        type: "custom_request",
                      },
                    });
                  }
                } else if (res?.data?.resultCode == 0) {
                  setMsg({ type: "error", text: res?.data?.resultMsg });
                  setToast(true);
                }
              }
              setIsLoading(false);
            })
            .catch((err) => {
              setIsLoading(false);
              setMsg({ type: "error", text: err?.response?.data?.message });
              setToast(true);
            });
        } catch (err) {
          console.log("error==>", err);
        }
      }
    } else {
      registerFormRef.current.reportValidity();
    }
  };

  // const handleImageChange = (event) => {
  //   const file = event.target.files[0];
  //   console.log(file);
  //   if (file) {
  //     setBackgroundImage(URL.createObjectURL(file));
  //   }
  // };

  // add business logo
  // const [backgroundImage, setBackgroundImage] = useState("");

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;

        img.onload = () => {
          console.log(
            "Img width ==> ",
            img.width,
            " Img height ==> ",
            img.height
          );
          if (img.width === 320 && img.height === 124) {
            // Convert the image data to a Blob
            const canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, img.width, img.height);
            canvas.toBlob((blob) => {
              console.log("typeof blob ==> ", typeof blob);
              setBackgroundImage(e.target.result);
              setLogoFile(blob); // Set the Blob as the logo file
              setError("");
            }, file.type);
          } else {
            console.log("Image must be 320x124 pixels.");
            setError("File size should be 320px width and 124px height.");
          }
        };
      };

      reader.readAsDataURL(file);
    }
    // console.log("Image ==> ", typeof logoFile);
  };

  // useEffect(() => {
  //   console.log("setBackgroundImage type ==> ", backgroundImage);
  // }, [backgroundImage]);
  return (
    <div style={{ height: "100vh" }}>
      {openPriceModal && <PriceModal setOpenPriceModal={setOpenPriceModal} />}
      <Helmet>
        <title>VidaVault</title>
      </Helmet>
      <Navbar />
      <div style={{ height: "85%" }} className="page-content">
        <div className="content-wrapper">
          <div className="content-inner">
            <div className="content d-flex justify-content-center align-items-center">
              <form
                ref={registerFormRef}
                onSubmit={handleRegister}
                className="flex-fill"
              >
                {registerSuccess ? (
                  <div class="row">
                    <div class="col-lg-6 offset-lg-3">
                      <div class="card mb-0">
                        <div class="card-body">
                          <div class="text-center mb-3">
                            <i class="fa-sharp fa-solid fa-circle-check text-success large-icon mb-3 mt-1"></i>
                            <h5>Account Request Sent</h5>
                            <p>Thank you for your interest in VidaVault.</p>
                            <p>
                              A team member will be in touch within 1-2 business
                              days to confirm your account details.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="row">
                    <div className="col-lg-6 offset-lg-3">
                      <div className="card mb-0">
                        <div className="card-body">
                          <div className="text-center mb-3">
                            <div className="col-lg-12">
                              <div>
                                <h1 className="mb-1">Create Account</h1>
                                <div className="d-flex justify-content-center">
                                  {/* <div
                                    className="profile-icon"
                                    style={{
                                      backgroundImage: `url(${backgroundImage})`,
                                      width: "320px",
                                      height: "120px",
                                      backgroundSize: "cover",
                                      backgroundPosition: "center",
                                      // borderRadius: "50%",
                                    }}
                                    onClick={() =>
                                      document
                                        .getElementById("fileInput")
                                        .click()
                                    }
                                  ></div>
                                  <input
                                    type="file"
                                    id="fileInput"
                                    style={{ display: "none" }}
                                    onChange={handleImageChange}
                                  /> */}

                                  {/*  */}
                                  <div>
                                    <div
                                      className="profile-icon"
                                      style={{
                                        backgroundImage: `url(${backgroundImage})`,
                                        width: "320px",
                                        height: "120px",
                                        backgroundSize: "cover",
                                        backgroundPosition: "center",
                                        // borderRadius: "50%",
                                      }}
                                      onClick={() =>
                                        document
                                          .getElementById("fileInput")
                                          .click()
                                      }
                                    >
                                      <input
                                        type="file"
                                        id="fileInput"
                                        style={{ display: "none" }}
                                        onChange={handleImageChange}
                                      />
                                    </div>
                                    {error && (
                                      <p style={{ color: "red" }}>{error}</p>
                                    )}
                                  </div>
                                  {/*  */}
                                </div>
                                {/* <br /> */}
                                {/* <i class="fa-solid fa-pen-to-square"></i> */}
                              </div>
                              <div className="d-flex justify-content-center">
                                {/* <Avatar
                                  backgroundImage={backgroundImage}
                                  setBackgroundImage={setBackgroundImage}
                                  setLogoFile={setLogoFile}
                                /> */}
                              </div>
                              <p className="mt-2 text-muted">
                                You can add business logo up to 320px width and
                                124px height.
                              </p>
                              {/* <p>Kindly include the business logo.</p> */}
                            </div>

                            {/* <i className="fa-solid fa-circle-plus text-success large-icon mb-3 mt-1"></i> */}

                            <span className="d-block text-muted mt-3">
                              A VidaVault team member will be in touch within
                              1-2 business days to confirm your account details.
                            </span>
                          </div>

                          <div className="row mt-4">
                            <div className="col-lg-6">
                              <div className="form-group form-group-floating row">
                                <div className="col-lg-12">
                                  <div className="position-relative">
                                    <input
                                      type="text"
                                      className="form-control form-control-outline"
                                      placeholder="Bussiness Name"
                                      value={bussinessname}
                                      // pattern="[a-zA-Z]{1,}[a-zA-Z0-9&-.]"
                                      // pattern="/^[a-zA-Z]{0,}[-&. ]*[a-zA-Z]*[-&. ]*[a-zA-z]*/gmi"
                                      minLength={3}
                                      maxLength={50}
                                      onChange={(e) =>
                                        setBussinessname(e.target.value)
                                      }
                                      required
                                    />

                                    <label className="label-floating">
                                      Business Name
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-6">
                              <div className="form-group form-group-floating row">
                                <div className="col-lg-12">
                                  <div className="position-relative">
                                    <input
                                      type="text"
                                      className="form-control form-control-outline"
                                      placeholder="Placeholder"
                                      value={username}
                                      minLength={4}
                                      maxLength={25}
                                      pattern="^(?!.*[_\.]{2})[a-zA-Z0-9]+([_.]?[a-zA-Z0-9]+)*$"
                                      onChange={(e) =>
                                        setUsername(e.target.value)
                                      }
                                      required
                                    />
                                    <label className="label-floating">
                                      Choose Username
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-lg-6">
                              <div className="form-group form-group-floating row">
                                <div className="col-lg-12">
                                  <div className="position-relative">
                                    <input
                                      type="text"
                                      className="form-control form-control-outline"
                                      placeholder="Placeholder"
                                      value={firstname}
                                      minLength={4}
                                      maxLength={25}
                                      pattern="^(?[a-zA-Z]{2})+([-']?[a-zA-Z]+)*$"
                                      onChange={(e) =>
                                        setFirstname(e.target.value)
                                      }
                                      required
                                    />
                                    <label className="label-floating">
                                      First Name
                                    </label>
                                    {/* <input
                                      type="text"
                                      inputMode="text"
                                      className="form-control form-control-outline"
                                      placeholder="Placeholder"
                                      // pattern="/^[a-zA-Z]{1,}[a-zA-Z'-][a-zA-Z]+/gm"
                                      pattern="/^[a-zA-Z]{1,}['-]?[a-zA-Z]+/gm"
                                      value={firstname}
                                      minLength={2}
                                      maxLength={20}
                                      onChange={(e) =>
                                        setFirstname(e.target.value)
                                      }
                                      required
                                    />
                                    <label className="label-floating">
                                      First Name
                                    </label> */}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-6">
                              <div className="form-group form-group-floating row">
                                <div className="col-lg-12">
                                  <div className="position-relative">
                                    <input
                                      type="text"
                                      className="form-control form-control-outline"
                                      placeholder="Placeholder"
                                      value={lastname}
                                      pattern="^(?[a-zA-Z]{2})+([-']?[a-zA-Z]+)*$"
                                      minLength={2}
                                      maxLength={20}
                                      onChange={(e) =>
                                        setLastname(e.target.value)
                                      }
                                      required
                                    />
                                    <label className="label-floating">
                                      Last Name
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-lg-6">
                              <div className="form-group form-group-floating row">
                                <div className="col-lg-12">
                                  <div className="position-relative">
                                    <input
                                      type="password"
                                      className="form-control form-control-outline"
                                      placeholder="Placeholder"
                                      value={password}
                                      onChange={(e) =>
                                        setPassword(e.target.value)
                                      }
                                      minLength={6}
                                      maxLength={30}
                                      required
                                    />
                                    <label className="label-floating">
                                      Create Password
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-6">
                              <div className="form-group form-group-floating row">
                                <div className="col-lg-12">
                                  <div className="position-relative">
                                    <input
                                      type="password"
                                      className="form-control form-control-outline"
                                      placeholder="Placeholder"
                                      value={repeatPassword}
                                      onChange={(e) =>
                                        setRepeatPassword(e.target.value)
                                      }
                                      minLength={6}
                                      maxLength={30}
                                      required
                                    />
                                    <label className="label-floating">
                                      Repeat Password
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-lg-6">
                              <div className="form-group form-group-floating row">
                                <div className="col-lg-12">
                                  <div className="position-relative">
                                    <input
                                      type="email"
                                      className="form-control form-control-outline"
                                      placeholder="Placeholder"
                                      value={email}
                                      onChange={(e) => setEmail(e.target.value)}
                                      minLength={6}
                                      maxLength={50}
                                      required
                                    />
                                    <label className="label-floating">
                                      Email
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-6">
                              <div className="form-group form-group-floating row">
                                <div className="col-lg-12">
                                  <div className="position-relative">
                                    <input
                                      type="email"
                                      className="form-control form-control-outline"
                                      placeholder="Placeholder"
                                      value={confirmEmail}
                                      minLength={6}
                                      maxLength={50}
                                      onChange={(e) =>
                                        setConfirmEmail(e.target.value)
                                      }
                                      required
                                    />
                                    <label className="label-floating">
                                      Confirm Email
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-lg-6">
                              <div className="form-group form-group-floating row">
                                <div className="col-lg-12">
                                  <div className="position-relative">
                                    <input
                                      name="phoneNumber"
                                      type="text"
                                      className="form-control form-control-outline"
                                      placeholder="Placeholder"
                                      pattern="[0-9]{10}"
                                      value={phoneNumber}
                                      onChange={(e) =>
                                        setPhoneNumber(e.target.value)
                                      }
                                      minLength={10}
                                      maxLength={10}
                                      required
                                    />
                                    <label className="label-floating">
                                      Phone
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group form-group-floating row">
                                <div className="col-lg-12">
                                  <div className="position-relative">
                                    <input
                                      type="text"
                                      className="form-control form-control-outline"
                                      placeholder="Placeholder"
                                      value={address}
                                      onChange={(e) =>
                                        setAddress(e.target.value)
                                      }
                                      minLength={2}
                                      maxLength={50}
                                      required
                                    />
                                    <label className="label-floating">
                                      Address
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-lg-6">
                              <div className="form-group form-group-floating row">
                                <div className="col-lg-12">
                                  <div className="position-relative">
                                    <input
                                      type="text"
                                      className="form-control form-control-outline"
                                      placeholder="Placeholder"
                                      value={unit}
                                      minLength={1}
                                      maxLength={25}
                                      onChange={(e) => setUnit(e.target.value)}
                                      // required
                                    />
                                    <label className="label-floating">
                                      Suite, Unit, Building, or Floor
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group form-group-floating row">
                                <div className="col-lg-12">
                                  <div className="position-relative">
                                    <input
                                      type="text"
                                      className="form-control form-control-outline"
                                      placeholder="Placeholder"
                                      value={city}
                                      minLength={2}
                                      maxLength={25}
                                      onChange={(e) => setCity(e.target.value)}
                                      required
                                    />
                                    <label className="label-floating">
                                      City
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-lg-6">
                              <div className="form-group form-group-floating row">
                                <div className="col-lg-12">
                                  <div className="position-relative">
                                    <select
                                      onChange={(e) => setState(e.target.value)}
                                      className="custom-select form-control-outline-within"
                                      required
                                    >
                                      <option value="" disabled hidden selected>
                                        Please select state
                                      </option>
                                      <option value="AL">Alabama</option>
                                      <option value="AK">Alaska</option>
                                      <option value="AZ">Arizona</option>
                                      <option value="AR">Arkansas</option>
                                      <option value="CA">California</option>
                                      <option value="CO">Colorado</option>
                                      <option value="CT">Connecticut</option>
                                      <option value="DE">Delaware</option>
                                      <option value="DC">
                                        District Of Columbia
                                      </option>
                                      <option value="FL">Florida</option>
                                      <option value="GA">Georgia</option>
                                      <option value="HI">Hawaii</option>
                                      <option value="ID">Idaho</option>
                                      <option value="IL">Illinois</option>
                                      <option value="IN">Indiana</option>
                                      <option value="IA">Iowa</option>
                                      <option value="KS">Kansas</option>
                                      <option value="KY">Kentucky</option>
                                      <option value="LA">Louisiana</option>
                                      <option value="ME">Maine</option>
                                      <option value="MD">Maryland</option>
                                      <option value="MA">Massachusetts</option>
                                      <option value="MI">Michigan</option>
                                      <option value="MN">Minnesota</option>
                                      <option value="MS">Mississippi</option>
                                      <option value="MO">Missouri</option>
                                      <option value="MT">Montana</option>
                                      <option value="NE">Nebraska</option>
                                      <option value="NV">Nevada</option>
                                      <option value="NH">New Hampshire</option>
                                      <option value="NJ">New Jersey</option>
                                      <option value="NM">New Mexico</option>
                                      <option value="NY">New York</option>
                                      <option value="NC">North Carolina</option>
                                      <option value="ND">North Dakota</option>
                                      <option value="OH">Ohio</option>
                                      <option value="OK">Oklahoma</option>
                                      <option value="OR">Oregon</option>
                                      <option value="PA">Pennsylvania</option>
                                      <option value="RI">Rhode Island</option>
                                      <option value="SC">South Carolina</option>
                                      <option value="SD">South Dakota</option>
                                      <option value="TN">Tennessee</option>
                                      <option value="TX">Texas</option>
                                      <option value="UT">Utah</option>
                                      <option value="VT">Vermont</option>
                                      <option value="VA">Virginia</option>
                                      <option value="WA">Washington</option>
                                      <option value="WV">West Virginia</option>
                                      <option value="WI">Wisconsin</option>
                                      <option value="WY">Wyoming</option>
                                    </select>
                                    <label className="label-floating">
                                      State
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group form-group-floating row">
                                <div className="col-lg-12">
                                  <div className="position-relative">
                                    <input
                                      type="text"
                                      inputmode="numeric"
                                      pattern="^\d{5}(-\d{4})?$"
                                      className="form-control form-control-outline"
                                      placeholder="Placeholder"
                                      value={zip}
                                      onChange={(e) => setZip(e.target.value)}
                                      maxLength={5}
                                      required
                                    />
                                    <label className="label-floating">
                                      Zip Code
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="form-group">
                            <label>
                              Request{" "}
                              <span onClick={() => setOpenPriceModal(true)}>
                                <a href="#">Subscription</a>{" "}
                              </span>
                              Type:
                            </label>
                            <select
                              data-placeholder="Select your subscription"
                              className="form-control form-control-select2"
                              aria-hidden="false"
                              onChange={(e) => setSubscription(e.target.value)}
                              required
                            >
                              <option value="" disabled hidden>
                                Please select a subscription
                              </option>

                              {/* Add the Free Trial option */}
                              <option value={1} className="subscribe-options">
                                Free Trial
                              </option>

                              {allSubscriptions?.map(
                                (sub, index) =>
                                  sub.subs_id !== 1 && (
                                    <option
                                      key={sub.subs_id} // Add a unique key for each option
                                      value={sub.subs_id}
                                      className="subscribe-options"
                                      style={{
                                        backgroundColor:
                                          index % 2 === 0
                                            ? "rgba(161, 161, 161, 0.33)"
                                            : "rgba(161, 161, 161, 0.20)",
                                        padding: "5px",
                                      }}
                                    >
                                      {sub.subs_name}
                                    </option>
                                  )
                              )}
                            </select>
                          </div>

                          <div className="form-group">
                            <label>Comments or Questions:</label>
                            <textarea
                              rows="5"
                              cols="5"
                              className="form-control"
                              placeholder="Please let us know if you have any comments or questions."
                              value={comments}
                              onChange={(e) => setComments(e.target.value)}
                              minLength="4"
                              maxLength="200"
                            ></textarea>
                          </div>

                          <div className="form-group mb-0">
                            <label className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                name="remember"
                                className="custom-control-input"
                                title="Please accepts terms and conditions to proceed"
                                required
                              />
                              <span className="custom-control-label">
                                Accepts
                                <a href="/fha/termsofservice" target="_blank">
                                  {/* <a href="#" target="_blank"> */}
                                  &nbsp;terms of service
                                </a>
                              </span>
                            </label>
                          </div>
                        </div>
                        {/* <div
                          style={{
                            color: "#E53030",
                            display: "flex",
                            justifyContent: "end",
                            marginRight: "20px",
                          }}
                        >
                          {err}
                        </div> */}
                        <div className="card-footer bg-transparent text-right">
                          <button
                            type="submit"
                            className="btn btn-teal btn-labeled btn-labeled-right mr-3"
                          >
                            <b>
                              <i className="fa-solid fa-plus"></i>
                            </b>{" "}
                            Create account
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </form>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
