import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { Helmet } from "react-helmet";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import { UserContext } from "../../UserProvider";
const PaymentFailure = ({ err }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { loggedIn } = useContext(UserContext);
  console.log(loggedIn);
  useEffect(() => {
    if (loggedIn.resultCode === 1) {
      if (window.history && window.history.pushState) {
        window.history.pushState("backward", null, "/fha/subscriptions");
      }
    }
  });
  // if (!loggedIn) {
  //   if (window.history && window.history.pushState) {
  //     window.history.pushState("backward", null, "/fha/login");
  //   }
  // }
  useEffect(() => {
    if (!loggedIn) {
      window.addEventListener("popstate", function (event) {
        navigate("/");
        // window.history.pushState("backward", null, "/fha/login");
        // window.location.reload(true);
      });
    }

    return () => {
      window.removeEventListener("popstate", function (event) {
        // window.history.pushState("backward", null, "/fha/login");
        // window.location.reload(true);
      });
    };
  }, []);

  console.log("Pathname ===> ", location.pathname);

  return (
    <div style={{ height: "100vh" }}>
      {loggedIn?.token && (
        <Helmet>
          <title>VidaVault</title>
        </Helmet>
      )}
      <Navbar />
      <div style={{ height: "85%" }} className="page-content">
        {loggedIn?.token && <Sidebar />}
        <div style={{ position: "relative" }}>
          <div style={{ position: "absolute", left: "2rem", top: "1rem" }}>
            {loggedIn.token ? (
              <NavLink to="/fha/subscriptions">
                <i
                  className="fa fa-arrow-left"
                  style={{
                    backgroundColor: "white",
                    fontSize: "2rem",
                    padding: "1rem",
                    borderRadius: "0.4rem",
                  }}
                ></i>
              </NavLink>
            ) : (
              <NavLink to="/">
                <i
                  className="fa fa-arrow-left"
                  style={{
                    backgroundColor: "white",
                    fontSize: "2rem",
                    padding: "1rem",
                    borderRadius: "0.4rem",
                  }}
                ></i>
                {/* Login */}
              </NavLink>
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            alignItems: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              float: "left",
              marginTop: "40px",
              borderRadius: "5px",
              boxSizing: "border-box",
              padding: "80px 30px 25px 30px",
              textAlign: "center",
              position: "relative",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              boxShadow:
                "0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)",
            }}
          >
            <span
              style={{
                position: "absolute",
                top: "-50px",
                display: "flex",
                justifyContent: "center",
                width: "100px",
                height: "100px",
                borderRadius: "100%",
                backgroundColor: "#D44500",
                border: "5px solid white",
              }}
            >
              <i
                style={{
                  color: "white",
                  lineHeight: "100px",
                  fontSize: "45px",
                }}
                className="fa fa-close"
              ></i>
            </span>
            <h1
              style={{
                textTransform: "uppercase",
                color: "#55585b",
                fontSize: "18px",
                fontWeight: "500",
                marginBottom: "5px",
              }}
            >
              Payment Failed
            </h1>
            <h2
              style={{
                color: "#959a9e",
                fontSize: "16px",
                fontWeight: "400",
                marginTop: "0px",
              }}
            >
              {err ? err?.message : location.state?.message}
            </h2>
            {!loggedIn.token && (
              <h2
                style={{
                  color: "#959a9e",
                  fontSize: "16px",
                  fontWeight: "400",
                  marginTop: "0px",
                }}
              >
                Please raise a support ticket for account approval, then Login
                and try payment again in subscriptions section.
                {/* <NavLink
                  to="/fha/login"
                  style={{
                    color: "blue",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                >
                  {" "}
                  Login
                </NavLink>{" "} */}
                {/* and try payment again in subscriptions section */}
              </h2>
            )}
            {loggedIn.token && (
              <h2
                style={{
                  color: "#959a9e",
                  fontSize: "16px",
                  fontWeight: "400",
                  marginTop: "0px",
                }}
              >
                Please
                {/* <NavLink
                  to="/fha/login"
                  style={{
                    color: "blue",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                > */}{" "}
                Login
                {/* </NavLink>{" "} */} and try payment again in subscriptions
                section
              </h2>
            )}
            <div
              style={{
                backgroundColor: "#f8f6f6",
                borderRadius: "4px",
                width: "100%",
                marginTop: "30px",
                float: "left",
                boxSizing: "border-box",
                padding: "30px",
              }}
            >
              <div
                style={{
                  color: "#232528",
                  marginTop: "25px",
                  marginBottom: "30px",
                  padding: "0rem 1rem",
                }}
              >
                <div style={{ display: "flex" }}>
                  <b>Transaction ID : </b>
                  <div style={{ marginLeft: "0.4rem" }}>
                    {err
                      ? err.payment_intent.client_secret
                      : location.state?.payment_intent.client_secret}
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <b>Amount : </b>
                  <div style={{ marginLeft: "0.4rem" }}>
                    ${" "}
                    {err
                      ? err.payment_intent.amount
                      : location.state?.payment_intent.amount}
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <b>Subscription Type : </b>
                  <div
                    style={{
                      marginLeft: "0.4rem",
                      textTransform: "capitalize",
                    }}
                  >
                    {err
                      ? err.payment_intent.description
                      : location.state?.payment_intent.description}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentFailure;
