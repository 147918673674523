import React, { useContext, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import logo from "../assets/vidavault-logo-seal.png";
import logo2 from "../assets/logo_icon_light.png";
import { UserContext } from "../UserProvider";
import Cookies from "js-cookie";
const AdminNavbar = () => {
  const navigate = useNavigate();
  const { loggedIn, logUserIn, logAdminIn, adminLogin } =
    useContext(UserContext);

  useEffect(() => {
    document.querySelector("html").style.fontSize = "100%";
  }, []);
  return (
    <div
      style={{ height: "15%" }}
      className="navbar navbar-expand-lg navbar-dark bg-indigo navbar-static"
    >
      <div className="d-flex flex-1 d-lg-none">
        <button
          type="button"
          className="navbar-toggler sidebar-mobile-main-toggle"
        >
          <i className="icon-transmission"></i>
        </button>

        <button
          data-target="#navbar-search"
          type="button"
          className="navbar-toggler"
          data-toggle="collapse"
        >
          <i className="icon-search4"></i>
        </button>
      </div>

      <div
        style={{ height: "80px" }}
        className="navbar-brand text-center text-lg-left"
      >
        <a
        //  href="#vv"
          className="d-inline-block">
          <img src={logo} className="d-none d-sm-block" alt="" />
          <img src={logo2} className="d-sm-none" alt="" />
        </a>
      </div>

      {/* {window.location.pathname !== "/fha/login" &&
        window.location.pathname !== "/fha/register" &&
        window.location.pathname !== "/fha/recover" && (
          <div
            className="navbar-collapse collapse flex-lg-1 mx-lg-3"
            id="navbar-search"
          >
            <div className="navbar-search d-flex align-items-center py-2 py-lg-0">
              <div className="form-group-feedback form-group-feedback-left flex-grow-1">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                />
                <div className="form-control-feedback">
                  <i className="icon-search4 text-white opacity-50"></i>
                </div>
              </div>
            </div>
          </div>
        )} */}

      <div class="d-flex justify-content-end align-items-center ml-auto">
        <ul class="navbar-nav flex-row">
          {/* <li class="nav-item">
            <a class="navbar-nav-link">
              <NavLink
                style={{ textDecoration: "none", color: "white" }}
                to="/fha/support"
              >
                <i class="fa-solid fa-life-ring"></i>
                <span class="d-none d-lg-inline-block ml-2">Support</span>
              </NavLink>
            </a>
          </li> */}
          {adminLogin && (
            <li class="nav-item">
              <div class="navbar-nav-link">
                <div
                  style={{ textDecoration: "none", color: "white" }}
                  onClick={() => {
                    Cookies.remove("adminData");
                    logAdminIn(false);
                    navigate("/sa/login");
                  }}
                >
                  <i class="fa-solid fa-sign-out"></i>
                  <span class="d-none d-lg-inline-block ml-2">Logout</span>
                </div>
              </div>
            </li>
          )}
          {/* {window.location.pathname !== "/fha/register" && !loggedIn && (
            <li class="nav-item">
              <a class="navbar-nav-link">
                <NavLink
                  style={{ textDecoration: "none", color: "white" }}
                  to="/fha/register"
                >
                  <i class="fa-solid fa-user-plus"></i>
                  <span class="d-none d-lg-inline-block ml-2">Sign Up</span>
                </NavLink>
              </a>
            </li>
          )} */}
          {/* {window.location.pathname !== "/fha/login" && !loggedIn && (
            <li class="nav-item">
              <a class="navbar-nav-link">
                <NavLink
                  style={{ textDecoration: "none", color: "white" }}
                  to="/fha/login"
                >
                  <i class="fa-solid fa-right-to-bracket"></i>
                  <span class="d-none d-lg-inline-block ml-2">Login</span>
                </NavLink>
              </a>
            </li>
          )} */}
        </ul>
      </div>
    </div>
  );
};

export default AdminNavbar;
