/** @format */

import "./App.css";
import Createslideshow from "./pages/fha/Createslideshow";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import LandingPage from "./pages/fha/LandingPage";
import Register from "./pages/fha/Register";
import Recover from "./pages/fha/Recover";
import Support from "./pages/fha/Support";
import Slideshow from "./pages/user/Slideshow";
import { useContext, useEffect } from "react";
import { UserContext } from "./UserProvider";
import FhaRoutes from "./routing/FhaRoutes";
import Cookies from "js-cookie";
import SaRoutes from "./routing/SaRoutes";
import Approved from "./pages/sa/Approved";
import AdminLogin from "./pages/sa/SaLogin";
import Pendingrequests from "./pages/sa/Pending";
import Rejectedrequests from "./pages/sa/Rejected";
import SlideshowLibrary from "./pages/fha/SlideshowLibrary";
import Resetpassword from "./pages/fha/Resetpassword";
import Popup from "./components/Popup";
import Loader from "./components/Loader";
import Tickets from "./pages/sa/Tickets";
import Ticketdetails from "./pages/sa/Ticketdetails";
import Messages from "./pages/fha/Messages";
import MyAccount from "./pages/fha/MyAccount";
import Messagedetails from "./pages/fha/Messagedetails";
import Notifications from "./pages/fha/Notifications";
import Subscription from "./pages/fha/Subscription";
import CheckoutForm from "./pages/fha/CheckoutForm";
import Success from "./pages/fha/Success";
import Payment from "./pages/fha/Payment";
import PaymentSuccess from "./pages/fha/PaymentSuccess";
import PaymentFailure from "./pages/fha/PaymentFailure";
import Subscribers from "./pages/sa/Subscribers";
import Transactions from "./pages/sa/Transactions";
import PlaqueOrders from "./pages/sa/PlaqueOrders";
import TermsAndConditions from "./pages/fha/TermsAndConditions";
import CookiesPolicy from "./pages/fha/CookiesPolicy";
import SignupTerms from "./pages/fha/SignupTerms";
// import TransactionDetails from "./pages/sa/TransactionDetails";

// const userRoutes = (
//   <>
//     <Route path="/slideshow" element={<Slideshow />} />
//   </>
// );
function App() {
  // const navigate = useNavigate()
  const { logUserIn, isOpen, setToast, msg, setMsg, logAdminIn } =
    useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    let user = Cookies.get("userData");
    if (user) {
      logUserIn(JSON.parse(user));
      navigate(location.pathname);
    }
    let admin = Cookies.get("adminData");
    if (admin) {
      logAdminIn(true);
      navigate(location.pathname);
    }
  }, []);
  return (
    <>
      <Popup isOpen={isOpen} setToast={setToast} msg={msg} setMsg={setMsg} />
      <Loader />
      <Routes>
        <Route element={<FhaRoutes />}>
          <Route path="/fha/createslideshow" element={<Createslideshow />} />
        </Route>
        <Route element={<FhaRoutes />}>
          <Route path="/fha/editslideshow/:id" element={<Createslideshow />} />
        </Route>
        <Route element={<FhaRoutes />}>
          <Route path="/fha/slideshowlibrary" element={<SlideshowLibrary />} />
        </Route>
        <Route element={<FhaRoutes />}>
          <Route path="/fha/messages" element={<Messages />} />
        </Route>
        <Route element={<FhaRoutes />}>
          <Route path="/fha/myaccount" element={<MyAccount />} />
        </Route>
        <Route element={<FhaRoutes />}>
          <Route path="/fha/subscriptions" element={<Subscription />} />
        </Route>
        <Route element={<FhaRoutes />}>
          <Route path="/fha/notifications" element={<Notifications />} />
        </Route>
        <Route element={<FhaRoutes />}>
          <Route path="/fha/payment/success" element={<PaymentSuccess />} />
        </Route>
        <Route element={<FhaRoutes />}>
          <Route path="/fha/payment/failed" element={<PaymentFailure />} />
        </Route>
        <Route element={<FhaRoutes />}>
          <Route path="/fha/payment" element={<Payment />} />
        </Route>
        <Route element={<FhaRoutes />}>
          <Route path="/fha/success" element={<Success />} />
        </Route>
        <Route element={<FhaRoutes />}>
          <Route path="/fha/messages/:messageId" element={<Messagedetails />} />
        </Route>
        <Route element={<SaRoutes />}>
          <Route path="/sa/pending" element={<Pendingrequests />} />
        </Route>
        <Route element={<SaRoutes />}>
          <Route path="/sa/rejected" element={<Rejectedrequests />} />
        </Route>
        <Route element={<SaRoutes />}>
          <Route path="/sa/approved" element={<Approved />} />
        </Route>
        <Route element={<SaRoutes />}>
          <Route path="/sa/subscribers" element={<Subscribers />} />
        </Route>
        <Route element={<SaRoutes />}>
          <Route path="/sa/plaqueorders" element={<PlaqueOrders />} />
        </Route>
        {/* <Route element={<SaRoutes />}>
          <Route path="/sa/transactions/:transactionId" element={<TransactionDetails />} />
        </Route> */}
        <Route element={<SaRoutes />}>
          <Route path="/sa/transactions" element={<Transactions />} />
        </Route>
        <Route element={<SaRoutes />}>
          <Route path="/sa/tickets" element={<Tickets />} />
        </Route>
        <Route element={<SaRoutes />}>
          <Route path="/sa/tickets/:ticketId" element={<Ticketdetails />} />
        </Route>
        <Route path="/fha/initpayment" element={<Payment />} />
        <Route path="/fha/payment/initsuccess" element={<PaymentSuccess />} />
        <Route path="/fha/payment/initfailed" element={<PaymentFailure />} />
        <Route path="/fha/resetpassword/:token" element={<Resetpassword />} />
        <Route path="/sa/login" element={<AdminLogin />} />
        <Route path="/fha/register" element={<Register />} />
        <Route
          path="/fha/termsandconditions"
          element={<TermsAndConditions />}
        />
        <Route path="/fha/termsofservice" element={<SignupTerms />} />
        <Route path="/fha/cookiespolicy" element={<CookiesPolicy />} />
        <Route path="/fha/support" element={<Support />} />
        <Route path="/fha/recover" element={<Recover />} />
        <Route path="/" element={<LandingPage />} />
        <Route path="fha/slideshow" element={<Slideshow />} />
        <Route path="fha/slideshow/:webUrl" element={<Slideshow />} />
        <Route path="/slideshow/:publicWebUrl" element={<Slideshow />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </>
  );
}

export default App;
