import React from 'react'

const ColumnFilter = ({ column }) => {
    const { filterValue, setFilter } = column;
  return (
    <input
      className="form-control form-control-sm"
      value={filterValue || ""}
      onChange={(e) => setFilter(e.target.value)}
      placeholder={`Search ${column.Header}`}
    />
  );
}

export default ColumnFilter