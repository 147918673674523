/** @format */

import axios from "axios";
import React from "react";
import { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { baseUrl } from "../config";
import { UserContext } from "../UserProvider";
import Cookies from "js-cookie";

const Subnav = () => {
  const {
    loggedIn,
    setIsLoading,
    setNotificationCount,
    setMsg,
    setToast,
    logUserIn,
  } = useContext(UserContext);

  const navigate = useNavigate();
  const getCount = () => {
    axios
      .get(`${baseUrl}/msg/notification/count`, {
        headers: { Authorization: `Bearer ${loggedIn.token}` },
      })
      .then((res) => {
        setNotificationCount(res.data.count);
      })
      .catch((err) => {
        if (err?.response?.data?.resultMsg === "User is inactive") {
          setMsg({
            type: "error",
            text: "The Super Admin has deactivated your account. Contact super admin for details.",
          });
          setToast(true);

          setTimeout(() => {
            Cookies.remove("userData");
            localStorage.removeItem("fha_logo");
            logUserIn(false);

            navigate("/");
          }, 2000);
        }
        console.log(err);
      });
  };
  useEffect(() => {
    getCount();
  }, []);
  return (
    <div className="navbar navbar-expand-lg navbar-light sticky-top border-left">
      <div className="text-center d-lg-none w-100">
        <button
          type="button"
          className="navbar-toggler dropdown-toggle"
          data-toggle="collapse"
          data-target="#navbar-second"
        >
          <i className="icon-unfold mr-2"></i>
          Open Task Menu
        </button>
      </div>
      <div className="navbar-collapse collapse" id="navbar-second">
        <ul className="navbar-nav">
          <li className="nav-item">
            <NavLink to="/fha/createslideshow" className="navbar-nav-link">
              <i className="fa-solid fa-rectangle-history-circle-plus mr-2"></i>
              Create New Slideshow
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/fha/notifications" className="navbar-nav-link">
              <i className="fa-solid fa-messages mr-2"></i>
              Account Messages
              <span className="badge badge-warning badge-pill position-static align-top ml-auto ml-lg-2">
                {loggedIn.notificationCount > 0
                  ? loggedIn.notificationCount
                  : ""}
              </span>
            </NavLink>
          </li>
        </ul>
        <ul className="navbar-nav ml-lg-auto">
          <li className="nav-item">
            <NavLink to="/fha/slideshowlibrary" className="navbar-nav-link">
              <i className="fa-solid fa-rectangle-history mr-2"></i>
              Slideshow Library
              {/* <span className="badge badge-indigo badge-pill position-static align-top ml-auto ml-lg-2">
                28
              </span> */}
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Subnav;
