/** @format */

import React, { useEffect, useState, useMemo, useRef } from "react";
import { Helmet } from "react-helmet";
import Navbar from "../../components/Navbar";
import { useTable, useFilters, usePagination } from "react-table";
import Sidebar from "../../components/Sidebar";
import axios from "axios";
import { adminToken, baseUrl } from "../../config";
import { useContext } from "react";
import { UserContext } from "../../UserProvider";
import ColumnFilter from "../../components/ColumnFilter";
import { NavLink, useNavigate } from "react-router-dom";
import Subnav from "../../components/Subnav";
import Cookies from "js-cookie";
const Messages = () => {
  const {
    loggedIn,
    setIsLoading,
    setTicketCount,
    setMsg,
    setToast,
    logUserIn,
  } = useContext(UserContext);
  const [tableData, setTableData] = useState([]);
  const navigate = useNavigate();
  const fetchData = () => {
    setIsLoading(true);
    axios
      .get(`${baseUrl}/support_ticket/list_by_user`, {
        headers: { Authorization: `Bearer ${loggedIn.token}` },
      })
      .then((res) => {
        setTableData(res.data.data);
        setIsLoading(false);
      })
      .then(() => {
        axios
          .get(`${baseUrl}/support_ticket/notification/clear`, {
            headers: { Authorization: `Bearer ${loggedIn.token}` },
          })
          .then((res) => {
            setTicketCount(0);
          })
          .catch((err) => {
            console.log(err);
            if (err?.response?.data?.resultMsg === "User is inactive") {
              setMsg({
                type: "error",
                text: "The Super Admin has deactivated your account. Contact super admin for details.",
              });
              setToast(true);

              setTimeout(() => {
                Cookies.remove("userData");
                localStorage.removeItem("fha_logo");
                logUserIn(false);

                navigate("/");
              }, 2000);
            }
          });
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    fetchData();
  }, []);
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "st_from",
        Filter: ColumnFilter,
      },
      {
        Header: "Email",
        accessor: "st_email",
        Filter: ColumnFilter,
      },
      {
        Header: "Subject",
        accessor: "st_subject",
        Filter: ColumnFilter,
      },
      {
        Header: "Business Name",
        accessor: "st_business_name",
        Filter: ColumnFilter,
      },
      {
        Header: "Details",
        Cell: ({ row }) => {
          const req = row.original;
          return (
            <NavLink to={`/fha/messages/${req.st_id}`}>View Details</NavLink>
          );
        },
      },
    ],
    []
  );

  const data = useMemo(() => tableData, [tableData]);
  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useFilters,
    usePagination
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    nextPage,
    gotoPage,
    previousPage,
    pageOptions,
    pageCount,
    canNextPage,
    canPreviousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = tableInstance;
  return (
    <div style={{ height: "100vh" }}>
      <Helmet>
        <title>VidaVault</title>
      </Helmet>
      <Navbar />
      <div style={{ height: "85%" }} className="page-content">
        <Sidebar />
        <div className="content-wrapper">
          <div className="content-inner">
            <Subnav />
            {/* <div className="navbar navbar-expand-lg navbar-light sticky-top">
              <div className="text-center d-lg-none w-100">
                <button
                  type="button"
                  className="navbar-toggler dropdown-toggle"
                  data-toggle="collapse"
                  data-target="#navbar-second"
                >
                  <i className="icon-unfold mr-2"></i>
                  Open Task Menu
                </button>
              </div>
            </div> */}

            <div className="content">
              <div className="card" style={{ height: "100%" }}>
                <div>
                  <h3 className="text-center mt-3 font-weight-bold">
                    Support Tickets
                  </h3>
                </div>
                <div
                  className="content d-flex justify-content-center align-items-center overflow-auto"
                  style={{ height: "100%" }}
                >
                  <form className="flex-fill" style={{ height: "100%" }}>
                    <div className="row" style={{ height: "100%" }}>
                      <div className="col-lg-12">
                        <div class="table ">
                          <table
                            {...getTableProps()}
                            className="table custom-left-support"
                            style={{ height: "100%" }}
                          >
                            <thead>
                              {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                  {headerGroup.headers.map((column) => (
                                    <th {...column.getHeaderProps()}>
                                      {column.render("Header")}
                                      <div>
                                        {column.canFilter
                                          ? column.render("Filter")
                                          : null}
                                      </div>
                                    </th>
                                  ))}
                                </tr>
                              ))}
                            </thead>
                            {page?.length > 0 ? (
                              <tbody {...getTableBodyProps()}>
                                {page.map((row) => {
                                  prepareRow(row);
                                  return (
                                    <tr {...row.getRowProps()}>
                                      {row.cells.map((cell) => {
                                        return (
                                          <td {...cell.getCellProps()}>
                                            {cell.render("Cell")}
                                          </td>
                                        );
                                      })}
                                    </tr>
                                  );
                                })}
                              </tbody>
                            ) : (
                              <tbody>
                                <tr>
                                  <td
                                    colSpan={
                                      headerGroups[0]?.headers.length || 1
                                    }
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "80px",
                                      }}
                                    >
                                      <h1
                                        style={{
                                          color: "#dddddd",
                                          fontSize: "35px",
                                        }}
                                      >
                                        No record yet!
                                      </h1>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            )}
                          </table>
                          <div
                            className="pagination custom-left-support"
                            style={{
                              padding: "0.5rem",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <button
                              type="button"
                              onClick={() => gotoPage(0)}
                              disabled={!canPreviousPage}
                            >
                              {"<<"}
                            </button>
                            <button
                              type="button"
                              style={{ margin: "0.5rem" }}
                              onClick={() => previousPage()}
                              disabled={!canPreviousPage}
                            >
                              {"<"}
                            </button>
                            <button
                              type="button"
                              style={{ margin: "0.5rem" }}
                              onClick={() => nextPage()}
                              disabled={!canNextPage}
                            >
                              {">"}
                            </button>
                            <button
                              type="button"
                              style={{ margin: "0.5rem" }}
                              onClick={() => gotoPage(pageCount - 1)}
                              disabled={!canNextPage}
                            >
                              {">>"}
                            </button>
                            <span style={{ margin: "0.5rem" }}>
                              Page
                              <strong>
                                {pageIndex + 1} of {pageOptions.length}
                              </strong>
                            </span>
                            |
                            <span style={{ margin: "0.5rem" }}>
                              Go to page:
                              <input
                                type="number"
                                defaultValue={pageIndex + 1}
                                onChange={(e) => {
                                  const page = e.target.value
                                    ? Number(e.target.value) - 1
                                    : 0;
                                  gotoPage(page);
                                }}
                                style={{ width: "100px", margin: "0.5rem" }}
                              />
                            </span>
                            <select
                              style={{ margin: "0.5rem" }}
                              value={pageSize}
                              onChange={(e) => {
                                setPageSize(Number(e.target.value));
                              }}
                            >
                              {[10, 20, 30, 40, 50].map((pageSize) => (
                                <option key={pageSize} value={pageSize}>
                                  Show {pageSize}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="navbar navbar-expand-lg navbar-light">
              <div className="text-center d-lg-none w-100">
                <button
                  type="button"
                  className="navbar-toggler dropdown-toggle"
                  data-toggle="collapse"
                  data-target="#navbar-footer"
                >
                  <i className="icon-unfold mr-2"></i>
                  Open Footer
                </button>
              </div>

              <div className="navbar-collapse collapse" id="navbar-footer">
                <span className="navbar-text">
                  <span>&copy; 2023 VidaVault</span>. All rights reserved.
                </span>

                <ul className="navbar-nav ml-lg-auto">
                  <li className="nav-item">
                    <NavLink to="/fha/support" className="navbar-nav-link">
                      <i className="fa-solid fa-life-ring mr-2"></i> Support
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Messages;
