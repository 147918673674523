/** @format */

import axios from "axios";
import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar";
import { baseUrl } from "../../config";
import Cookies from "js-cookie";
import { UserContext } from "../../UserProvider";
const Support = () => {
  // const businessRegex = /^[a-zA-Z][-&.a-zA-Z\s]*[a-zA-Z]$/g;
  const businessRegex = /^[a-zA-Z]{0,}[-&. ]*[a-zA-Z]*[-&. ]*[a-zA-z]*/gim;
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [bussinessName, setBussinessName] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [screenshot, setScreenshot] = useState();
  const [message, setMessage] = useState("");
  const { loggedIn, setMsg, setToast, setIsLoading, logUserIn } =
    useContext(UserContext);
  const navigate = useNavigate();
  const config = {};
  useEffect(() => {
    if (loggedIn?.user?.fha_business_name) {
      setBussinessName(loggedIn.user.fha_business_name);
    }
    if (loggedIn?.user?.fha_business_name) {
      setPhone(loggedIn.user.fha_contact);
    }
  }, [loggedIn]);
  if (loggedIn?.token) {
    config.headers = {
      Authorization: `Bearer ${loggedIn.token}`,
    };
  }
  const handleSubmitSupport = () => {
    // console.log(bussinessName);
    if (!loggedIn && !/^[a-zA-Z]+([-'][a-zA-Z]+)*$/.test(firstName)) {
      setMsg({
        type: "error",
        text: "First name is not valid",
      });
      setToast(true);
    } else if (!loggedIn && !/^[a-zA-Z]+([-'][a-zA-Z]+)*$/.test(lastName)) {
      setMsg({
        type: "error",
        text: "Last name is not valid",
      });
      setToast(true);
    } else if (!businessRegex?.test(bussinessName)) {
      console.log(bussinessName);
      setMsg({
        type: "error",
        text: "Business name is not valid",
      });
      setToast(true);
    } else if (
      (!loggedIn && email?.trim()) === "" ||
      bussinessName.trim() == "" ||
      phone.length !== 10 ||
      subject.trim() == "" ||
      message.trim() == "" ||
      !screenshot
    ) {
      setMsg({
        type: "error",
        text: "Please fill all the fields",
      });
      setToast(true);
    } else {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("subject", subject);
      formData.append("business_name", bussinessName);
      formData.append("message", message);
      formData.append("upload", screenshot);
      formData.append("fha_contact", phone);
      formData.append("firstname", firstName);
      formData.append("lastname", lastName);
      formData.append("email", email);
      axios
        .post(`${baseUrl}/support_ticket/create`, formData, config)
        .then((res) => {
          setIsLoading(false);
          setMsg({
            type: "success",
            text: res.data.resultMsg,
          });
          setToast(true);
          if (!loggedIn?.user?.fha_business_name) {
            setBussinessName("");
          }
          setMessage("");
          setScreenshot();
          setSubject("");
          setFirstName("");
          setLastName("");
          setEmail("");
          !loggedIn && setPhone("");
        })
        .catch((err) => {
          console.log(err);
          if (err?.response?.data?.resultMsg === "User is inactive") {
            setMsg({
              type: "error",
              text: "The Super Admin has deactivated your account. Contact super admin for details.",
            });
            setToast(true);

            setTimeout(() => {
              Cookies.remove("userData");
              localStorage.removeItem("fha_logo");
              logUserIn(false);

              navigate("/");
            }, 2000);
          }
          setIsLoading(false);
        });
    }
  };

  return (
    <div style={{ height: "100vh" }}>
      <Navbar />
      <div style={{ height: "85%" }} className="page-content">
        <div className="content-wrapper">
          <div className="content-inner">
            <div className="content d-flex justify-content-center align-items-center">
              <form action="index.html" className="flex-fill">
                <div className="row">
                  <div className="col-lg-6 offset-lg-3">
                    <div className="card mb-0">
                      <div className="card-body">
                        <div className="text-center mb-3">
                          <i className="fa-solid fa-circle-plus text-success large-icon mb-3 mt-1"></i>
                          <h5 className="mb-0">Create a Support Ticket</h5>
                          <span className="d-block text-muted">
                            All fields are required
                          </span>
                        </div>
                        <form>
                          {!loggedIn && (
                            <>
                              <div className="form-group">
                                <label>First Name:</label>
                                <input
                                  name="firstName"
                                  type="text"
                                  className="form-control form-control-outline"
                                  placeholder="First Name"
                                  // pattern="^(?[a-zA-Z]{2})+([-']?[a-zA-Z]+)*$"
                                  value={firstName}
                                  onChange={(e) => setFirstName(e.target.value)}
                                  minLength={2}
                                  maxLength={20}
                                  required
                                  // disabled={loggedIn?.user?.fha_contact}
                                />
                              </div>
                              <div className="form-group">
                                <label>Last Name:</label>
                                <input
                                  name="lastName"
                                  type="text"
                                  className="form-control form-control-outline"
                                  placeholder="Last Name"
                                  pattern="^[a-zA-Z]+([-'][a-zA-Z]+)*$"
                                  value={lastName}
                                  onChange={(e) => setLastName(e.target.value)}
                                  minLength={2}
                                  maxLength={20}
                                  required
                                  // disabled={loggedIn?.user?.fha_contact}
                                />
                              </div>
                              <div className="form-group">
                                <label>Email:</label>
                                <input
                                  name="email"
                                  type="email"
                                  className="form-control form-control-outline"
                                  placeholder="Email"
                                  // pattern="[0-9]{10}"
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                  minLength={30}
                                  maxLength={30}
                                  required
                                />
                              </div>
                            </>
                          )}
                          <div className="form-group">
                            <label>Subject:</label>
                            <input
                              type="text"
                              value={subject}
                              onChange={(e) => setSubject(e.target.value)}
                              required
                              className="form-control"
                              placeholder="Enter Subject"
                            />
                          </div>
                          <div className="form-group">
                            <label>Business:</label>
                            <input
                              type="text"
                              value={bussinessName}
                              minLength={3}
                              maxLength={50}
                              onChange={(e) => setBussinessName(e.target.value)}
                              className="form-control"
                              disabled={loggedIn?.user?.fha_business_name}
                              placeholder="Enter Business Name"
                            />
                          </div>

                          <div className="form-group">
                            <label>Phone:</label>
                            <input
                              name="phoneNumber"
                              type="text"
                              className="form-control form-control-outline"
                              placeholder="Phone"
                              value={phone}
                              onChange={(e) => {
                                const input = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                                if (input.length <= 10) {
                                  setPhone(input);
                                }
                              }}
                              maxLength={10}
                              required
                              disabled={loggedIn?.user?.fha_contact}
                            />
                          </div>
                          <div className="form-group">
                            <label>Attach Screenshot:</label>
                            <label className="custom-file">
                              <input
                                type="file"
                                accept="image/gif, image/png, image/jpeg"
                                onChange={(e) => {
                                  const file = e.target.files[0];
                                  if (file && file.size > 2 * 1024 * 1024) {
                                    setMsg({
                                      type: "error",
                                      text: "File size should be less than 2 MB",
                                    });
                                    setToast(true);
                                  } else {
                                    setScreenshot(file);
                                  }
                                }}
                                value={undefined}
                                className="custom-file-input"
                              />

                              <span className="custom-file-label">
                                Choose File
                              </span>
                            </label>
                            {screenshot && <p>{screenshot.name}</p>}
                            <span className="form-text text-muted">
                              Accepted Formats: gif, png, jpg. Max file size 2Mb
                            </span>
                          </div>
                          <div className="form-group">
                            <label>Your Message:</label>
                            <textarea
                              rows="5"
                              cols="5"
                              className="form-control"
                              value={message}
                              onChange={(e) => setMessage(e.target.value)}
                              placeholder="Please describe the issue that you are facing"
                            ></textarea>
                          </div>
                          <div className="text-center text-lg-right text-md-center">
                            <button
                              type="button"
                              onClick={handleSubmitSupport}
                              className="btn btn-teal btn-labeled btn-labeled-right"
                            >
                              <b>
                                <i className="fa-sharp fa-solid fa-paper-plane"></i>
                              </b>
                              Submit Ticket
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div className="navbar navbar-expand-lg navbar-light">
              <div className="text-center d-lg-none w-100">
                <button
                  type="button"
                  className="navbar-toggler dropdown-toggle"
                  data-toggle="collapse"
                  data-target="#navbar-footer"
                >
                  <i className="icon-unfold mr-2"></i>
                  Footer
                </button>
              </div>

              <div className="navbar-collapse collapse" id="navbar-footer">
                <span className="navbar-text">
                  <span>&copy; 2023 VidaVault</span>
                </span>

                <ul className="navbar-nav ml-lg-auto">
                  <li className="nav-item">
                    <NavLink to="/fha/support" className="navbar-nav-link">
                      <i className="fa-solid fa-life-ring mr-2"></i> Support
                    </NavLink>
                  </li>
                  {/* <li className="nav-item">
                    <a
                      href="FHA-documentation.html"
                      className="navbar-nav-link"
                      target="_blank"
                    >
                      <i className="fa-solid fa-file mr-2"></i> Documentation
                    </a>
                  </li> */}
                  <li className="nav-item">
                    <NavLink
                      to="/fha/register"
                      className="navbar-nav-link font-weight-semibold"
                    >
                      <span className="text-pink mr-2">
                        <i className="fa-solid fa-user-plus"></i> Sign Up
                      </span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Support;
