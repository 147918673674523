import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { retrievePaymentIntent } from "./Stripe";

const Success = () => {
  const navigate = useNavigate();
  const [paymentStatus, setPaymentStatus] = useState("");

  useEffect(() => {
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent"
    );
    if (!clientSecret) {
      navigate("/");
      return;
    }
    console.log(clientSecret)
    retrievePaymentIntent(clientSecret)
      .then((paymentIntent) => {
        console.log(paymentIntent)
        setPaymentStatus(paymentIntent.status);
      })
      .catch((err) => {
        console.log(err);
        navigate("/");
      });
  }, [navigate]);
  return (
    <div>
      <h1>Payment Successful!</h1>
      {paymentStatus && <p>Payment Status: {paymentStatus}</p>}
    </div>
  );
};

export default Success;
