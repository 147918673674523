/** @format */

// /** @format */

// import React, { useState } from "react";
// import Sidebar from "../../components/Sidebar";
// const DrawerComponent = () => {
//   const [Drawer, setOpenDrawer] = useState(false);
//   const openDrawer = () => {
//     setOpenDrawer(!Drawer);
//   };

//   const closeDrawer = () => {
//     const drawer = document.getElementById("drawer-3");
//     drawer.classList.remove("show");
//   };

//   return (
//     <>
//       <Sidebar />
//       {/* <div
//         className="drawer drawer-left slide"
//         tabIndex="-1"
//         role="dialog"
//         aria-labelledby="drawer-3-title"
//         aria-hidden="true"
//         id="drawer-3"
//         style={{ height: "auto", width: "auto", backgroundColor: "cyan" }}
//       >
//         <div
//           className="drawer-content drawer-content-scrollable"
//           role="document"
//         >
//           <div className="drawer-header">
//             <h4 className="drawer-title" id="drawer-3-title">
//               Drawer Title
//             </h4>
//           </div>
//           <div className="drawer-body">
//             <p>Cras mattis consectetur purus sit amet fermentum...</p>
//           </div>
//           <div className="drawer-footer">Drawer footer</div>
//         </div>
//       </div> */}
//     </>
//   );
// };

// export default DrawerComponent;
/** @format */

import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import fhalogo from "../../assets/logo-placeholder.png";
import Cookies from "js-cookie";
import { UserContext } from "../../UserProvider";
import { useContext } from "react";
import { useEffect } from "react";
import axios from "axios";
import { baseUrl } from "../../config";
import business_logo from "../../assets/business_logo.png";
const DrawerComponent = ({ setIsDrawer }) => {
  const [logo, setLogo] = useState(business_logo);
  const navigate = useNavigate();
  const { loggedIn, logUserIn, setTicketCount, setMsg, setToast } =
    useContext(UserContext);

  useEffect(() => {
    axios
      .get(`${baseUrl}/support_ticket/notification/count`, {
        headers: { Authorization: `Bearer ${loggedIn.token}` },
      })
      .then((res) => setTicketCount(res.data.count))
      .catch((err) => {
        console.log(err);
        if (err?.response?.data?.resultMsg === "User is inactive") {
          setMsg({
            type: "error",
            text: "The Super Admin has deactivated your account. Contact super admin for details.",
          });
          setToast(true);

          setTimeout(() => {
            Cookies.remove("userData");
            localStorage.removeItem("fha_logo");
            logUserIn(false);

            navigate("/");
          }, 2000);
        }
      });
  }, []);

  // console.log(loggedIn.user.fha_id);

  const getUserDetails = async () => {
    try {
      axios
        .get(`${baseUrl}/users/${loggedIn.user.fha_id}`, {
          headers: {
            Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c`,
          },
        })
        .then((response) => {
          // console.log(
          //   "User details response ==> ",
          //   response.data.data.fha_logo
          // );
          if (response?.data?.data?.fha_logo?.startsWith("http")) {
            localStorage.setItem("fha_logo", response?.data?.data?.fha_logo);
          }
          let addLogo = response?.data?.data?.fha_logo ?? business_logo;
          // setLogo(addLogo);
        })
        .catch((error) => {
          console.log("axios errror => ", error);
          if (error?.response?.data?.resultMsg === "User is inactive") {
            setMsg({
              type: "error",
              text: "The Super Admin has deactivated your account. Contact super admin for details.",
            });
            setToast(true);

            setTimeout(() => {
              Cookies.remove("userData");
              localStorage.removeItem("fha_logo");
              logUserIn(false);

              navigate("/");
            }, 2000);
          }
        });
    } catch (error) {
      console.error("Error ==> ", error);
    }
  };

  useEffect(() => {
    if (localStorage?.getItem("fha_logo")?.startsWith("http")) {
      // setLogo(localStorage?.getItem("fha_logo"));
    }
    getUserDetails();
    // console.log("Logo ==> ", loggedIn?.user?.fha_logo);
    // setLogo(loggedIn?.user?.fha_logo);
  }, []);

  useEffect(() => {
    if (localStorage?.getItem("fha_logo")?.startsWith("http")) {
      setLogo(localStorage?.getItem("fha_logo"));
    }
  });

  return (
    <>
      {/* <div style={{ position: "absolute", zIndex: 299, top: 20 }}> */}
      <div
        style={{
          width: "100vw",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          className="drawer drawer-left slide  d-lg-none "
          tabIndex="-1"
          role="dialog"
          aria-labelledby="drawer-3-title"
          aria-hidden="true"
          id="drawer-3"
          style={{ height: "auto", margin: "auto" }}
        >
          <div
            className="d-flex"
            // style={{ border: " 1px solid rgb(128 128 128 / 42%)" }}
          >
            <div
              // className="profile-icon"
              style={{
                width: "320px",
                height: "124px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                // border: " 1px solid rgb(128 128 128 / 42%)",
              }}
            >
              <img
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  width: "100%",
                  height: "100%",
                  objectFit: "fit-content",
                }}
                src={logo}
                alt="logo"
              />
            </div>

            {/* <div>
                <img
                  width={320}
                  height={124}
                  src={logo}
                  alt="Business Logo"
                  className="client-logo mr-2"
                />
                </div> */}

            <div className="flex-1 text-right">
              <button
                type="button"
                className="btn btn-outline-medium border-transparent btn-icon rounded-pill btn-sm sidebar-control sidebar-main-resize d-none d-lg-inline-flex"
              >
                <i className="fa-duotone fa-left-right"></i>
              </button>

              {/* <button
                type="button"
                className="btn btn-outline-medium border-transparent btn-icon rounded-pill btn-sm sidebar-mobile-main-toggle d-lg-none"
              >
                <i className="fa-duotone fa-left-right"></i>
              </button> */}
            </div>
          </div>
          <p className="mt-1" style={{ color: "gray", fontSize: "13px" }}>
            Note: You can edit the business logo from 'My Account'
          </p>
          <div className="sidebar-section text-dark">
            <ul className="nav nav-sidebar" data-nav-type="accordion">
              <li className="nav-item-header">
                <div className="text-uppercase font-size-xs line-height-xs mt-1">
                  Main
                </div>{" "}
                <i className="icon-menu" title="Main"></i>
              </li>
              <li className="nav-item text-dark">
                <a
                  href="#"
                  className="nav-link text-dark"
                  onClick={() => setIsDrawer(false)}
                >
                  <i className="icon-home4"></i>
                  <span>Dashboard</span>
                </a>
              </li>

              <li className="nav-item nav-item-submenu text-dark">
                <NavLink
                  to="/fha/slideshowlibrary"
                  className={({ isActive }) =>
                    isActive
                      ? "active nav-link text-dark"
                      : "nav-link text-dark"
                  }
                  onClick={() => setIsDrawer(false)}
                >
                  <i className="icon-color-sampler "></i>{" "}
                  <span>Slideshow Library</span>
                </NavLink>
              </li>
              <li className="nav-item nav-item-submenu text-dark">
                <NavLink
                  to="/fha/createslideshow"
                  className={({ isActive }) =>
                    isActive
                      ? "active nav-link text-dark"
                      : "nav-link text-dark"
                  }
                  onClick={() => setIsDrawer(false)}
                >
                  <i className="fa-solid fa-rectangle-history-circle-plus mr-2"></i>
                  Create New Slideshow
                </NavLink>
              </li>

              <li className="nav-item-header text-dark">
                <div className="text-uppercase font-size-xs line-height-xs">
                  Account
                </div>{" "}
                <i className="icon-menu" title="Forms"></i>
              </li>
              <li className="nav-item text-dark">
                <NavLink
                  to="/fha/myaccount"
                  className="nav-link text-dark"
                  onClick={() => setIsDrawer(false)}
                >
                  <i className="icon-user-plus"></i>
                  <span>My Account</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/fha/messages"
                  className="nav-link text-dark"
                  onClick={() => setIsDrawer(false)}
                >
                  <i className="icon-comment-discussion"></i>
                  <span>Support Tickets</span>
                  <span className="badge badge-teal badge-pill align-self-center ml-auto">
                    {loggedIn.ticketCount > 0 ? loggedIn.ticketCount : ""}
                  </span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/fha/subscriptions"
                  className="nav-link text-dark"
                  onClick={() => setIsDrawer(false)}
                >
                  <i className="icon-cog5"></i>
                  <span>Subscription settings</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <div
                  onClick={() => {
                    Cookies.remove("userData");
                    localStorage.removeItem("fha_logo");
                    logUserIn(false);

                    navigate("/");
                  }}
                  className="nav-link"
                >
                  <i className="icon-switch2"></i>
                  <span>Logout</span>
                </div>
              </li>
            </ul>
          </div>
          {/* custom divs */}
          {/* <div
          className="drawer-content drawer-content-scrollable"
          role="document"
        >
          <div className="drawer-header">
            <h4 className="drawer-title" id="drawer-3-title">
              Drawer Title
            </h4>
          </div>
          <div className="drawer-body">
            <p>Cras mattis consectetur purus sit amet fermentum...</p>
          </div>
          <div className="drawer-footer">Drawer footer</div>
        </div> */}
        </div>
      </div>
    </>
  );
};

export default DrawerComponent;
