/** @format */

import React, { useCallback, useEffect, useRef, useState } from "react";
import { NavLink, useLocation, useParams } from "react-router-dom";
// import '../../assets/css/reset-min.css'
import "../../assets/css/slick-min.css";
import "../../assets/css/styles.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import axios from "axios";
import { baseUrl } from "../../config";
import { useContext } from "react";
import { UserContext } from "../../UserProvider";
import default_logo from "../../assets/preview-logo.png";
import Cookies from "js-cookie";
const Slideshow = () => {
  const { loggedIn, logUserIn, isLoading, setIsLoading, setMsg, setToast } =
    useContext(UserContext);
  const [data, setData] = useState();
  const [isSlideExisted, setIsSlideExisted] = useState([]);
  const [relationList, setRelationList] = useState([]);
  const location = useLocation();
  const params = useParams();
  const [isdata, setIsData] = useState(false);
  const [logo, setLogo] = useState(default_logo);

  let user = Cookies.get("userData");
  const fha_id = user && JSON.parse(user).user?.fha_id;
  // const fha_id = JSON.parse(user)?.user?.fha_id;
  const [showerr, setshowErr] = useState(false);
  useEffect(() => {
    axios
      .get(`${baseUrl}/relations`)
      .then((res) => {
        setRelationList(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    // setIsLoading(false);
    const slideshowData = JSON.parse(localStorage.getItem("slideshowdata"));
    const userToken = JSON.parse(localStorage.getItem("userToken"));
    if (params.webUrl) {
      let temp = {
        personalDetails: {
          firstName: null,
          lastName: null,
          dob: null,
          dod: null,
          birthCity: null,
          birthState: null,
          deathCity: null,
          deathState: null,
          obituryText: null,
        },
        familyDetails: {
          slideShowId: null,
          motherName: null,
          fatherName: null,
          relatives: [],
        },
        files: [],
      };
      setIsLoading(true);
      axios
        .post(`${baseUrl}/slideshow/${params.webUrl}`, {
          userId: fha_id,
          // headers: { Authorization: `Bearer ${userToken}` },
        })
        .then((res) => {
          // console.log("Response webUrl ===> ", res);
          // if (res.data.data.length === 0) {
          //   setMsg({
          //     type: "error",
          //     text: "The slideshow you requested no longer exists.",
          //   });
          //   setToast(true);
          // }
          let response = res.data.data[0];
          temp.personalDetails.firstName = response?.firstName;
          temp.personalDetails.lastName = response?.lastName;
          temp.personalDetails.dob = response?.dob;
          temp.personalDetails.dod = response?.dod;
          temp.personalDetails.birthCity = response?.birthCity;
          temp.personalDetails.birthState = response?.birthState;
          temp.personalDetails.deathCity = response?.deathCity;
          temp.personalDetails.deathState = response?.deathState;
          temp.personalDetails.obituryText = response?.obituaryText;
          temp.familyDetails.fatherName = response?.fatherName;
          temp.familyDetails.motherName = response?.motherName;
          temp.familyDetails.relatives = response?.familyTree;
          temp.files = response?.files;
          setData(temp);
          if (temp.files == undefined) {
            setshowErr(true);
          }
          console.log("data==>", temp);
          res?.data?.fhaLogo !== null && setLogo(res?.data?.fhaLogo);
          // setSuccessMsg("Family details saved successfully");
          // setSuccessToast(true);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsData(true);
          setIsLoading(false);
          console.log(err);
        });
    } else if (params.publicWebUrl) {
      let temp = {
        personalDetails: {
          firstName: null,
          lastName: null,
          dob: null,
          dod: null,
          birthCity: null,
          birthState: null,
          deathCity: null,
          deathState: null,
          obituryText: null,
        },
        familyDetails: {
          slideShowId: null,
          motherName: null,
          fatherName: null,
          relatives: [],
        },
        files: [],
      };
      setIsLoading(true);
      axios
        .get(`${baseUrl}/gu/slideshow/${params.publicWebUrl}`, {
          headers: { Authorization: `Bearer ${userToken}` },
        })
        .then((res) => {
          console.log("Response publicWebUrl ====> ", res);
          let response = res.data.data[0];
          temp.personalDetails.firstName = response?.firstName;
          temp.personalDetails.lastName = response?.lastName;
          temp.personalDetails.dob = response?.dob;
          temp.personalDetails.dod = response?.dod;
          temp.personalDetails.birthCity = response?.birthCity;
          temp.personalDetails.birthState = response?.birthState;
          temp.personalDetails.deathCity = response?.deathCity;
          temp.personalDetails.deathState = response?.deathState;
          temp.personalDetails.obituryText = response?.obituaryText;
          temp.familyDetails.fatherName = response?.fatherName;
          temp.familyDetails.motherName = response?.motherName;
          temp.familyDetails.relatives = response?.familyTree;
          temp.files = response?.files;
          setData(temp);
          // setSuccessMsg("Family details saved successfully");
          // setSuccessToast(true);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    } else if (slideshowData) {
      console.log("slideshowData ==> ", slideshowData);
      setData(slideshowData);
      localStorage.getItem("fha_logo") &&
        setLogo(localStorage.getItem("fha_logo"));
    }
  }, []);
  useEffect(() => {
    document.querySelector("html").style.fontSize = "62.5%";
  }, []);
  const nav = useRef(null);

  // function topFunction() {
  //   console.log("called topFunction");
  //   window.scrollTo({
  //     top: 0,
  //     behavior: "smooth" // Add smooth scrolling behavior if desired
  //   });
  // }

  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (window.scrollY > 0) {
  //       nav.current.classList.add("_header__sticky");
  //     } else {
  //       nav.current.classList.remove("_header__sticky");
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll, true);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  useEffect(() => {
    const slideshow_base = document.querySelector(".slideshow_base");
    // Ensure that the DOM has loaded before applying the parallax effect
    document.addEventListener(
      "scroll",
      function () {
        // console.log(document.documentElement.scrollTop);
        // console.log(document.body.scrollTop);
        if (document.body.scrollTop > 0) {
          nav?.current?.classList?.add("_header__sticky");
        } else {
          nav?.current?.classList?.remove("_header__sticky");
        }
      },
      true
    );
    // console.log("useeffect call");
    return () => {
      document.removeEventListener("scroll", function () {});
    };
  }, []);

  // calculate age
  function calculateAge(dob, dod) {
    const birthDate = new Date(dob);
    const deathDate = new Date(dod);
    const ageInMilliseconds = deathDate - birthDate;

    const days = ageInMilliseconds / (1000 * 60 * 60 * 24);
    const months = Math.floor(days / 30.44); // Approximate average number of days in a month
    const years = Math.floor(months / 12);
    const remainingMonths = months % 12;
    const remainingDays = Math.floor(days % 30.44);

    if (years === 0 && months === 0 && remainingDays === 1) {
      return ` They were 1 day old.`;
    } else if (years === 0 && months === 1 && remainingDays === 0) {
      return ` They were 1 month old.`;
    } else if (years === 1 && months === 0 && remainingDays === 0) {
      return ` They were 1 year old.`;
    } else if (years === 0 && months === 0) {
      return ` They were ${Math.floor(days)} days old.`;
    } else if (years === 0 && months > 0) {
      return ` They were ${years} years and ${remainingMonths} months old.`;
    } else {
      return ` They were ${years} years and ${remainingMonths} months old.`;
    }
  }

  const handleDebounceImage = useCallback((file) => {
    return (
      <>
        <video
          // width="100%"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
          height="95%"
          controls
          name="Video Name"
        >
          <source
            // width="100%"
            // height="95%"
            src={file?.dataUri}
            type="video/mp4"
          />
        </video>
      </>
    );
  }, []);

  return (
    <div>
      {/* {data?.files !== undefined ? ( */}
      {data?.files !== undefined && (
        <div
          className="slideshow_base"
          // onScroll={(e) => {
          //   nav.current.classList.toggle(
          //     "_header__sticky",
          //     e.target.scrollTop > 0
          //   );
          // }}
          style={{ height: "100%", fontSize: "20px !important" }}
        >
          <a name="memories"></a>
          <header className="_header ">
            <nav ref={nav} className="_header__nav" id="navbar">
              <div className="_fh-logo"></div>
              {/* <div
                className="slideshow-navbar"
                style={{
                  width: "320px",
                  height: "124px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                  // border: " 1px solid rgb(128 128 128 / 42%)",
                  marginRight: "40%",
                }}
              >
              </div> */}
              <ul className="_header__list">
                <li className="_header__item">
                  <a href="#memories" className="_header__link">
                    Memories
                  </a>
                </li>
                <li className="_header__item">
                  <a href="#obituary" className="_header__link">
                    Obituary
                  </a>
                </li>
                <li className="_header__item">
                  <a href="#tree" className="_header__link">
                    Family Tree
                  </a>
                </li>
                <li className="_header__item">
                  <a
                    // href="#vv"
                    href="https://vidavault.net/"
                    target="_blank"
                    className="_header__link"
                  >
                    VidaVault
                  </a>
                </li>
              </ul>

              <div className="_navigation">
                <input
                  type="checkbox"
                  className="_navigation__checkbox"
                  id="navi-toggle"
                />
                <label for="navi-toggle" className="_navigation__button">
                  <span className="_navigation__icon"></span>
                </label>
                <div className="_navigation__background"></div>
                <nav className="_navigation__nav">
                  <ul className="_navigation__list">
                    <li className="_navigation__item">
                      <a href="#memories" className="_navigation__link">
                        Memories
                      </a>
                    </li>
                    <li className="_navigation__item">
                      <a href="#obituary" className="_navigation__link">
                        Obituary
                      </a>
                    </li>
                    <li className="_navigation__item">
                      <a href="#tree" className="_navigation__link">
                        Family Tree
                      </a>
                    </li>
                    <li className="_navigation__item">
                      <a
                        href="#vv"
                        target="_blank"
                        className="_navigation__link"
                      >
                        VidaVault
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </nav>
          </header>

          <section className="_main-slider">
            <div
              style={{ height: "100%", display: "flex", alignItems: "center" }}
            >
              <Swiper
                allowTouchMove={false}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                slidesPerView={1}
                // autoplay={{
                //   delay: 10000,
                //   disableOnInteraction: false,
                // }}
                loop={true}
                pagination={{
                  clickable: true,
                }}
                style={{ height: "100%", width: "100%" }}
                onSlideChange={() => {
                  // console.log("slide change");
                  // topFunction();
                }}
                // onSwiper={(swiper) => console.log(swiper)}
                lazy={true}     
              >
                {data?.files?.length > 0 ? (
                  data?.files.map((file) => {
                    // console.log("File ==> ", file.dataUri);
                    return (
                      <SwiperSlide>
                        {file.type.split("/")[0] == "application" && (
                          <>
                            {/* <iframe
                                  width="100%"
                                  height="95%"
                                  src={file?.dataUri}
                                  title="YouTube video player"
                                  frameborder="0"
                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                  allowfullscreen
                                ></iframe> */}

                            {/* <video
                              // width="100%"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                              allowFullScreen
                              height="95%"
                              controls
                              name="Video Name"
                            >
                              <source
                                // width="100%"
                                // height="95%"
                                src={file?.dataUri}
                                type="video/mp4"
                              />
                            </video> */}

                            {handleDebounceImage(file)}
                          </>
                        )}

                        {/* {console.log(file.type.split("/")[0] == "image")} */}
                        {file.type.split("/")[0] == "video" &&
                          file.type.split("/")[1] != "link" && (
                            // <video
                            //   autoplay
                            //   muted
                            //   loop
                            //   style={{
                            //     height: "100%",
                            //     minWidth: "100%",
                            //     objectFit: "cover",
                            //   }}
                            //   controls
                            // >
                            //   <source src={file.dataUri} />
                            //   Your browser does not support the video tag.
                            // </video>
                            <>
                              {/* <video
                              // width="100%"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                              allowFullScreen
                              height="95%"
                              controls
                              name="Video Name"
                            >
                              <source
                                // width="100%"
                                // height="95%"
                                src={file?.dataUri}
                                type="video/mp4"
                              />
                            </video> */}
                              {/* <iframe
                                width="100%"
                                height="95%"
                                src={file?.dataUri}
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen
                              ></iframe> */}
                              {handleDebounceImage(file)}
                            </>
                          )}
                        {/* <iframe
                      width="560"
                      height="315"
                      src="https://www.youtube.com/embed/35npVaFGHMY"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe> */}
                        {file?.type.split("/")[0] == "video" &&
                          file?.type.split("/")[1] == "link" && (
                            <iframe
                              height="95%"
                              width="100%"
                              src={
                                // file?.dataUri.match("youtube")
                                //   ? "https://www.youtube.com/embed/" +
                                //     file?.dataUri.split("v=")[1]
                                //   :
                                file?.dataUri.match(".be/")
                                  ? "https://www.youtube.com/embed/" +
                                    file?.dataUri.split(".be/")[1]
                                  : "https://player.vimeo.com/video/" +
                                    file?.dataUri.split("m/")[1]
                              }
                              // src="https://www.youtube.com/embed/35npVaFGHMY"
                              frameborder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                              title={file?.dataUri}
                              // allow="autoplay;web-share"
                              // webkitallowfullscreen
                              // mozallowfullscreen
                              allowfullscreen
                            ></iframe>
                          )}
                        {file?.type.split("/")[0] == "image" && (
                          <>
                            <img
                              style={{ height: "100%", width: "100%" }}
                              src={file?.dataUri}
                            />
                          </>
                        )}
                        {}
                      </SwiperSlide>
                    );
                  })
                ) : (
                  <SwiperSlide>
                    <div className="h-100 d-flex justify-content-center">
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ fontSize: "5em" }}
                      >
                        <span style={{ color: "#c2c2c2" }}>
                          Slide not available
                        </span>
                      </div>
                    </div>
                  </SwiperSlide>
                )}
              </Swiper>
            </div>
            {/* <div className="item image">
            <span className="loading">Loading...</span>
            <figure className="_figure">
              <div
                className="slide-image slide-media"
                style="background-image:url('images/01hero.jpg');"
              >
                <img data-lazy="images/01hero.jpg" className="image-entity" />
              </div>
            </figure>
          </div> */}
            {/* <div className="item vimeo" data-video-start="4">
            <iframe
              className="embed-player slide-media"
              src="https://player.vimeo.com/video/217885864?api=1&byline=0&portrait=0&title=0&background=1&mute=1&loop=1&autoplay=0&id=217885864"
              width="980"
              height="520"
              frameborder="0"
              webkitallowfullscreen
              mozallowfullscreen
              allowfullscreen
            ></iframe>
          </div> */}
            {/* <div className="item image">
            <figure>
              <div
                className="slide-image slide-media"
                style="background-image:url('images/02hero.jpg');"
              >
                <img data-lazy="images/02hero.jpg" className="image-entity" />
              </div>
            </figure>
          </div> */}
            {/* <div className="item youtube-sound">
            <iframe
              className="embed-player slide-media"
              width="980"
              height="520"
              src="https://www.youtube.com/embed/QV5EXOFcdrQ?enablejsapi=1&controls=0&fs=0&iv_load_policy=3&rel=0&showinfo=0&loop=1&playlist=QV5EXOFcdrQ&start=1"
              frameborder="0"
              allowfullscreen
            ></iframe>
          </div> */}
            {/* <div className="item youtube">
            <iframe
              className="embed-player slide-media"
              width="980"
              height="520"
              src="https://www.youtube.com/embed/QV5EXOFcdrQ?enablejsapi=1&controls=0&fs=0&iv_load_policy=3&rel=0&showinfo=0&loop=1&playlist=QV5EXOFcdrQ&start=1"
              frameborder="0"
              allowfullscreen
            ></iframe>
          </div> */}
            {/* <div className="item image">
            <figure>
              <div
                className="slide-image slide-media"
                style="background-image:url('images/03hero.jpg');"
              >
                <img data-lazy="images/03hero.jpg" className="image-entity" />
              </div>
            </figure>
          </div> */}
          </section>
          <a name="obituary"></a>
          <section className="_container">
            <div className="_content">
              <h2>
                {data?.personalDetails?.firstName + " "}
                {data?.personalDetails?.lastName}
              </h2>
              <h3>
                {data?.personalDetails?.dob?.split("-")[0]} -
                {data?.personalDetails?.dod?.split("-")[0]}
              </h3>
              <p style={{ color: "#333" }}>
                {data?.personalDetails?.firstName + " "}
                {data.personalDetails?.lastName} of
                {" " + data?.personalDetails?.deathCity},
                {" " + data?.personalDetails?.deathState + " "}
                died on
                {" " +
                  new Date(data.personalDetails?.dod).toLocaleDateString(
                    "en-US",
                    {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    }
                  )}
                .
                {/* {data?.personalDetails?.dod &&
                  Math.floor(
                    (new Date(data?.personalDetails?.dod) -
                      new Date(data?.personalDetails?.dob)) /
                      (1000 * 60 * 60 * 24 * 365)
                  )} */}
                {data?.personalDetails?.dod &&
                  calculateAge(
                    data?.personalDetails?.dob,
                    data?.personalDetails?.dod
                  )}
              </p>
              <p style={{ color: "#333" }}>
                {data?.personalDetails?.obituryText}
              </p>
              {/* <p>
                Jack Hunter Suggs of Chester, MD died on November 17, 2010. He
                was 40.
              </p>

              <p>
                Born on October 13, 1970 in Atlanta, GA, he was the son of Ruth
                Ann Kersey-Suggs and Leo Hunter Suggs. Jack graduated from St.
                John’s Country Day School in Orange Park, FL, className of 1989.
                He then received his Bachelors and Pre-Med from the University
                of Kansas. Jack then attended University of Kansas Medical
                School, and later attended Arizona State University where he
                received his MBA and Masters in Health Care Science. Mr. Suggs
                worked as an investment Banker for several firms in both New
                York and Arizona. In December 2009, he relocated to Chester, MD.
              </p>

              <p>
                Jack is survived by his father, Leo Hunter Suggs of Ponte Vedra,
                FL and Richmond, VA; his mother, Ruth Ann Kersey-Suggs of
                Chester, MD; his sister, Crystal Herrmann, of Easton, MD and two
                sons, Jackson Theodore Suggs, of Kansas City, MO. and Cash
                Linnihan of Madison, WI.
              </p>

              <p>
                Visitation will be held on Friday, November 19, 2010 from 7-9PM
                at Fellows, Helfenbein &amp; Newnam Funeral Home, P.A., 106
                Shamrock Road, Chester, MD 21619 where Funeral Services will be
                held on Saturday, November 20, 2010 at 11AM. Interment will take
                place at Stevensville Cemetery.
              </p>

              <p>
                In lieu of flowers contributions can be made to Father Martin’s
                Ashley, 800 Tydings Lane, Havre de Grace, MD 21078.
              </p> */}
            </div>
          </section>

          <a name="tree"></a>
          <section className="_container _dark">
            <div className="_content">
              <h2>Family Tree</h2>

              <ul className="_tree">
                <li>
                  <p>
                    <span>Mother :</span>
                    {data?.familyDetails && data?.familyDetails?.motherName ? (
                      // <a href="#">{data?.familyDetails?.motherName} </a>
                      <span id="spancustom">
                        {data?.familyDetails?.motherName}{" "}
                      </span>
                    ) : (
                      <span id="spancustom"> Not Available</span>
                    )}
                    {/* <a href="#">
                      {data?.familyDetails && data?.familyDetails?.motherName
                        ? data?.familyDetails?.motherName
                        : "Not Available"}
                    </a> */}
                  </p>
                </li>
                <li>
                  <p>
                    <span>Father :</span>
                    {data?.familyDetails && data?.familyDetails?.fatherName ? (
                      <span id="spancustom">
                        {data?.familyDetails?.fatherName}
                      </span>
                    ) : (
                      <span id="spancustom">{"Not Available"}</span>
                    )}
                  </p>
                </li>

                {data?.familyDetails?.relatives?.map((relative) => {
                  console.log("Relative ==> ", relative);
                  return (
                    <li>
                      <p>
                        <span>
                          {relationList.find(
                            (obj) => obj.rm_id === +relative?.relationId
                          )?.rm_desc + " :"}
                        </span>
                        {relative.slideshowURL == null ||
                        relative.slideshowURL === "" ? (
                          <span id="spancustom"> {relative?.name} </span>
                        ) : (
                          <a href={relative?.slideshowURL} target="_blank">
                            {relative?.existingName
                              ? relative?.existingName
                              : relative?.name}
                          </a>
                        )}
                      </p>
                    </li>
                  );
                })}
              </ul>
            </div>
          </section>
          {/* <NavLink
            to={"/fha/createslideshow"}
            state={{ data: data }}
            className="urgent"
          >
            <i className="fa fa-arrow-left mr-4"></i>
            Go back
          </NavLink> */}
          <div className="_container1">
            <div className="_content1">
              <img
                src={logo}
                title="Business logo"
                style={{ height: "120px", width: "auto" }}
                alt="footer logo"
              />
            </div>
          </div>
        </div>
        // ) : (
        //   <div className="page-not-found">
        //     <h1>The slideshow you're requesting no longer exists.</h1>
        //     {/* <img src={page_not_found} alt="" srcset="" /> */}
        //   </div>
      )}

      {/* {!isLoading && data?.files == undefined && (
        <div className="page-not-found">
          <h1>The slideshow you're requesting no longer exists.</h1>
        </div>
      )} */}

      {data?.length === 0 ||
        (data?.files == undefined && showerr && (
          <div className="page-not-found">
            <h1>The slideshow you're requesting no longer exists.</h1>
          </div>
        ))}
      {/* {data?.data?.length == [] && ( */}
    </div>
  );
};

export default Slideshow;
