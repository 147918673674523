import React, { useEffect } from "react";
import SimpleParallax from "simple-parallax-js";
import img from "../../assets/demo/skill.jpg";
function LeftParallax() {
  useEffect(() => {
    const background = document.querySelector(".left-parallax-background");
    // Ensure that the DOM has loaded before applying the parallax effect
    document.addEventListener(
      "scroll",
      function () {
        new SimpleParallax(background, {
          delay: 2,
          transition: "cubic-bezier(0.25, 0.1, 0.25, 1)",
          overflow: true,
        });
        // console.log("background", background);
      },
      true
    );
    console.log("useeffect call");
    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("scroll", function () {});
    };
  }, []);

  return (
    <>
      {/* // <div
    //   className="SimpleParallax"
    //   style={{ position: "absolute", height: "100%" }}
    // > */}
      {/* <div
        className="left-parallax-container"
        style={{ position: "absolute", height: "100%" }}
      > */}
      <div
        className="left-parallax-background skills-image"
        style={{
          backgroundImage: `url(${img})`,
          height: "143%",
          width: "65%",
          position: "absolute",
          top: "-15vh",
          left: "-10vw",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "50% 0%",
        }}
        // onClick={() => {
        //   console.log("click event triggers");
        // }}
      >
        {/* Content */}
      </div>
      {/* </div> */}
      {/* <div style={{ height: "100vh", width: "100vw" }}></div> */}
      {/* </div> */}
    </>
  );
}

export default LeftParallax;
