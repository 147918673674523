/** @format */

import React, { useState } from "react";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { createPaymentIntent } from "./Stripe";
import { useContext } from "react";
import { UserContext } from "../../UserProvider";
import axios from "axios";
import { baseUrl } from "../../config";
import { useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar";
import { Helmet } from "react-helmet";
import Sidebar from "../../components/Sidebar";
import Cookies from "js-cookie";
const Checkout = ({ data, setErr }) => {
  const { loggedIn, logUserIn, setMsg, setToast, setIsLoading } =
    useContext(UserContext);
  const stripe = useStripe();
  const navigate = useNavigate();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [succeeded, setSucceeded] = useState(false);
  const [clientSecret, setClientSecret] = useState();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet, so show a loading indicator
      setProcessing(true);
      return;
    }
    setProcessing(true);
    try {
      // Call your server-side API to create a payment intent
      let clientSecret;
      if (loggedIn?.token) {
        clientSecret = await createPaymentIntent({
          data: data,
          token: loggedIn.token,
        });
      } else {
        clientSecret = await createPaymentIntent({
          data: data,
        });
      }
      setClientSecret(clientSecret);
      // Confirm the payment with Stripe
      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardNumberElement),
        },
      });
      if (result.error) {
        setError(result.error.message);
        if (loggedIn?.token) {
          await axios.post(
            `${baseUrl}/payment/update_status`,
            {
              status:
                result.error.code === "card_declined"
                  ? "Failed"
                  : result.error.code,
              client_secret: result.error.payment_intent.client_secret,
            },
            {
              headers: { Authorization: `Bearer ${loggedIn.token}` },
            }
          );
          navigate("/fha/payment/failed", {
            state: result.error,
          });
          setMsg({
            type: "error",
            text: result.error.message,
          });
          setProcessing(false);
        } else {
          await axios.post(`${baseUrl}/payment/update_payment_status`, {
            status:
              result.error.code === "card_declined"
                ? "Failed"
                : result.error.code,
            user_id: data.user_id,
            client_secret: result.error.payment_intent.client_secret,
          });
          setErr(result.error);
          // navigate("/fha/payment/initfailed", { state: result.error });
          setMsg({
            type: "error",
            text: result.error.message,
          });
          setProcessing(false);
        }
      } else {
        if (loggedIn?.token) {
          await axios.post(
            `${baseUrl}/payment/update_status`,
            {
              status: result.paymentIntent.status,
              client_secret: result.paymentIntent.client_secret,
            },
            {
              headers: { Authorization: `Bearer ${loggedIn.token}` },
            }
          );
          navigate("/fha/payment/success", { state: result.paymentIntent });
          setMsg({
            type: "success",
            text: "Payment succeeded!",
          });
          setToast(true);
          setProcessing(false);
        } else {
          await axios.post(`${baseUrl}/payment/update_payment_status`, {
            status: result.paymentIntent.status,
            user_id: data.user_id,
            client_secret: result.paymentIntent.client_secret,
          });
          navigate("/fha/payment/initsuccess", { state: result.paymentIntent });
          setMsg({
            type: "success",
            text: "Payment succeeded!",
          });
          setToast(true);
          setProcessing(false);
        }
      }
    } catch (error) {
      // setError(error.message);
      if (error?.response?.data?.resultMsg === "User is inactive") {
        setMsg({
          type: "error",
          text: "The Super Admin has deactivated your account. Contact super admin for details.",
        });
        setToast(true);

        setTimeout(() => {
          Cookies.remove("userData");
          localStorage.removeItem("fha_logo");
          logUserIn(false);

          navigate("/");
        }, 2000);
      }
      setProcessing(false);
    }
  };
  return (
    <div style={{ height: "100vh" }}>
      {loggedIn?.token && (
        <Helmet>
          <title>VidaVault</title>
        </Helmet>
      )}
      <Navbar />
      <div style={{ height: "85%" }} className="page-content">
        {loggedIn?.token && <Sidebar />}
        <form
          onSubmit={handleSubmit}
          className="d-flex flex-column align-items-center w-100 justify-content-center"
          style={{ height: "100%" }}
        >
          <div
            className=" bg-white p-4 payment-parent-div"
            style={{ borderRadius: "5px", width: "40%" }}
          >
            <div
              style={{
                textAlign: "center",
                fontSize: "2rem",
                fontWeight: "700",
                padding: "1.5rem 0rem",
              }}
            >
              Pay Now
            </div>
            <div className="form-group">
              <label
                htmlFor="card-number-element"
                style={{ fontSize: "1.1rem" }}
              >
                Card number:
              </label>

              <div className="input-group">
                <CardNumberElement
                  id="card-number-element"
                  className="form-control"
                />
              </div>
            </div>

            <div className="form-group">
              <label
                htmlFor="card-expiry-element"
                style={{ fontSize: "1.1rem" }}
              >
                Expiration date:
              </label>

              <div className="input-group">
                <CardExpiryElement
                  id="card-expiry-element"
                  className="form-control"
                />
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="card-cvc-element" style={{ fontSize: "1.1rem" }}>
                CVC:
              </label>

              <div className="input-group">
                <CardCvcElement
                  id="card-cvc-element"
                  className="form-control"
                />
              </div>
            </div>

            {error && (
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
            )}

            {succeeded && (
              <div className="alert alert-success" role="alert">
                Payment succeeded!
              </div>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "1rem 0rem",
              }}
            >
              <div style={{ fontSize: "1rem" }}>
                Total:
                {"$" +
                  data.amount.toLocaleString("en-US", {
                    style: "decimal",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
              </div>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={processing || succeeded}
              >
                {processing ? "Processing..." : "Pay now"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Checkout;
