import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { baseUrl } from "../../config";
export const stripePromise = loadStripe(
  "pk_test_51MgWGVHuntcc8ukVuxRnB1JY5t1ZHiRJivuPPDZrY17G2vR1NZQzlbJLGNigmLjXoHTGqPsvcY9K26xtxFBpTnCU00GF00ebIP"
);

export const createPaymentIntent = async ({ data, token }) => {
  try {
    let response;
    if (token) {
      response = await fetch(`${baseUrl}/payment/create_session`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
    } else {
      console.log("reached");
      response = await fetch(`${baseUrl}/payment/create_payment_session`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: { "Content-Type": "application/json" },
      });
    }
    if (response.ok) {
      const responseData = await response.json();
      return responseData.client_secret;
    } else {
      throw new Error("Failed to create payment intent");
    }
  } catch (err) {
    console.log(err);
    throw new Error("Failed to create payment intent");
  }
};

export const retrievePaymentIntent = async (clientSecret) => {
  try {
    const stripe = await stripePromise;
    const paymentIntent = await stripe.retrievePaymentIntent(clientSecret);
    return paymentIntent;
  } catch (err) {
    console.log(err);
    throw new Error("Failed to retrieve payment intent");
  }
};
