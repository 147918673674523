import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import SaNavbar from "./SaNavbar";
import SaSidebar from "./SaSidebar";
import axios from "axios";
import { adminToken, baseUrl } from "../config";
import { useContext } from "react";
import { UserContext } from "../UserProvider";
import { useParams } from "react-router-dom";
const TransactionDetailsPopup = ({
  popupTrigger,
  setPopupTrigger,
  transactionId,
  setTransactionId,
}) => {
  const { setIsLoading, isLoading } = useContext(UserContext);
  const [transactionDetails, setTransactionDetails] = useState();
  const [copied, setCopied] = useState(false);
  const fetchData = () => {
    setIsLoading(true);
    axios
      .get(`${baseUrl}/fha/transaction/${transactionId}`, {
        headers: {
          Authorization: `Bearer ${adminToken}`,
        },
      })
      .then((res) => {
        setTransactionDetails(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    if (transactionId) {
      fetchData();
    }
  }, []);
  const handleCopyClick = async (textToCopy) => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      setCopied(true);
    } catch (err) {}
  };

  return (
    <>
      {popupTrigger && !isLoading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            width: "100vw",

            zIndex: "4000",
            backdropFilter: "brightness(90%) blur(4px)",
          }}
          className="modal"
        >
          <div
            style={{
              display: "flex",
              flex: "0 0 auto",
              width: "50%",
              minWidth: "content",
            }}
          >
            <div
              className="card"
              style={{ height: "100%", padding: "3rem 1rem" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginRight: "2rem",
                  marginTop: "-1rem",
                }}
              >
                <div
                  style={{
                    marginLeft: "1.5rem",
                    marginBottom: "1.5rem",
                    fontSize: "1.8rem",
                    fontWeight: "500",
                  }}
                >
                  Transaction Details
                </div>
                <i
                  className="fa fa-close"
                  onClick={() => {
                    setPopupTrigger(false);
                    setTransactionId();
                  }}
                  style={{
                    fontSize: "1.5rem",
                    padding: "4px",
                    cursor: "pointer",
                  }}
                ></i>
              </div>

              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                {/* {transactionDetails?.st_id && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexBasis: "100%",
                      padding: "0.7rem 1.5rem",
                      flexWrap: "wrap",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "1rem",
                        flexWrap: "wrap",
                        fontWeight: "bold",
                      }}
                    >
                      Id :
                    </div>
                    <div style={{ marginLeft: "0.5rem" }}>
                      {transactionDetails.st_id}
                    </div>
                  </div>
                )} */}
                {transactionDetails?.st_transaction_id && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexBasis: "100%",
                      padding: "0.7rem 1.5rem",
                      flexWrap: "wrap",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "1rem",
                        flexWrap: "wrap",
                        fontWeight: "bold",
                      }}
                    >
                      Transaction ID:
                    </div>
                    <div style={{ marginLeft: "0.5rem" }}>
                      {transactionDetails.st_transaction_id}
                      {copied ? (
                        <i
                          style={{ marginLeft: "0.4rem", fontSize: "1rem" }}
                          className="fa fa-circle-check text-success"
                        ></i>
                      ) : (
                        <i
                          style={{
                            marginLeft: "0.4rem",
                            fontSize: "1rem",
                            cursor: "pointer",
                          }}
                          className="fa fa-copy text-secondary"
                          onClick={() =>
                            handleCopyClick(
                              transactionDetails.st_transaction_id
                            )
                          }
                        ></i>
                      )}
                    </div>
                  </div>
                )}
                {transactionDetails?.fha_firstname && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexBasis: "50%",
                      padding: "0.7rem 1.5rem",
                    }}
                  >
                    <div style={{ fontSize: "1rem", fontWeight: "bold" }}>
                      First Name:
                    </div>
                    <div style={{ marginLeft: "0.5rem" }}>
                      {transactionDetails.fha_firstname}
                    </div>
                  </div>
                )}
                {transactionDetails?.fha_lastname && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexBasis: "50%",
                      padding: "0.7rem 1.5rem",
                    }}
                  >
                    <div style={{ fontSize: "1rem", fontWeight: "bold" }}>
                      Last Name:
                    </div>
                    <div style={{ marginLeft: "0.5rem" }}>
                      {transactionDetails.fha_lastname}
                    </div>
                  </div>
                )}
                {transactionDetails?.fha_lastname && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexBasis: "50%",
                      padding: "0.7rem 1.5rem",
                    }}
                  >
                    <div style={{ fontSize: "1rem", fontWeight: "bold" }}>
                      Subscription Type:
                    </div>
                    <div style={{ marginLeft: "0.5rem" }}>
                      {transactionDetails.subs_name}
                    </div>
                  </div>
                )}
                {transactionDetails?.st_transaction_status && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexBasis: "50%",
                      padding: "0.7rem 1.5rem",
                    }}
                  >
                    <div style={{ fontSize: "1rem", fontWeight: "bold" }}>
                      Status:
                    </div>
                    <div style={{ marginLeft: "0.5rem" }}>
                      {transactionDetails.st_transaction_status}
                    </div>
                  </div>
                )}
                {transactionDetails?.st_fha_user_id && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexBasis: "50%",
                      padding: "0.7rem 1.5rem",
                    }}
                  >
                    <div style={{ fontSize: "1rem", fontWeight: "bold" }}>
                      User ID:
                    </div>
                    <div style={{ marginLeft: "0.5rem" }}>
                      {transactionDetails.st_fha_user_id}
                    </div>
                  </div>
                )}
                {transactionDetails?.st_transaction_metadata?.amount && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexBasis: "50%",
                      padding: "0.7rem 1.5rem",
                    }}
                  >
                    <div style={{ fontSize: "1rem", fontWeight: "bold" }}>
                      Amount:
                    </div>
                    <div style={{ marginLeft: "0.5rem" }}>
                      ${transactionDetails.st_transaction_metadata.amount}
                    </div>
                  </div>
                )}
                {transactionDetails?.created_date && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexBasis: "50%",
                      padding: "0.7rem 1.5rem",
                    }}
                  >
                    <div style={{ fontSize: "1rem", fontWeight: "bold" }}>
                      Date:
                    </div>
                    <div style={{ marginLeft: "0.5rem" }}>
                      {new Date(transactionDetails.created_date).toLocaleString(
                        "en-US",
                        
                        {
                          timeZone:"UTC",
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                          hour: "numeric",
                          minute: "numeric",
                          hour12: true,
                        }
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default TransactionDetailsPopup;
